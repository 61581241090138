import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  addOrUpdateNeoVentes,
  getNeoVentes,
  getClientSearch,
  getB2BClientSearch,
  getClientAddress,
  getCodePromo,
  getNeoVentesPromos,
  getProductInfo,
  getPartialInvoicePayments,
  getAllProducts,
  getChargeDeskStatus,
  getClientTransactionsFullInfo,
  getClientAndAddress,
  getClientAndAddressByLead,
} from "../../actions/NeoVentesAction";
import { Validations } from "../../utils/validations";
import Select from "react-select";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import StripePayment from "../../StripePayment";
import moment from "moment";
import frLocale from "moment/locale/fr";
import InputMask from "react-input-mask";
import Switch from "@material-ui/core/Switch";
import Toastr from "../../messages";
import Loader from "../../loader";
import productList from "../../appProductList";
import getProvinceTaxInfo from "../../appTaxes";
import { FLEXITI_MIN } from "../../appConfig";
import GoogleAddressSearch from "../GoogleAddressSearch";
import MuiAlert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import FolderIcon from "@material-ui/icons/Folder";
import DraftsIcon from "@material-ui/icons/Drafts";
import Tooltip from "@material-ui/core/Tooltip";
import { encryptText, decryptText } from "../../appEncryption";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import AutorenewIcon from "@material-ui/icons/Autorenew";
//import AutocompleteNeo from '../libautocomplete/AutocompleteNeo';
import ContactMailIcon from "@material-ui/icons/ContactMail";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import HelpIcon from "@material-ui/icons/Help";
import { AddAPhoto, DesktopWindows } from "@material-ui/icons";
import NeoB2bleadProspectsForm from "../neob2bleadprospects/NeoB2bleadProspectsForm";
import NeoB2bleadProsViactionForm from "../neob2bleadprospects/NeoB2bleadProsViactionForm";
import NeoB2bleadProsInnovaForm from "../neob2bleadprospects/NeoB2bleadProsInnovaForm";
import NeoB2bleadProsGFIntegralForm from "../neob2bleadprospects/NeoB2bleadProsGFIntegralForm";
import NdiInterestConflict from "./NdiInterestConflict";
import NdiFieldsMiniBoardzendesk from "../../srcndi/components/ndifields/NdiFieldsMiniBoardzendesk";
import ZendMacrosSendForm from "../zendmacros/ZendMacrosSendForm";
import ZendFusionNoteForm from "../zendleads/ZendFusionNoteForm";
import PublicLinkForm from "../ndifields/PublicLinkForm";
import AssignTicketToGroupOrUser from "../ndifields/AssignTicketToGroupOrUser";
import MyZendFusionNoteForm from "../ndifields/ZendFusionNoteForm";
import ZendAddNoteForm from "../ndifields/ZendAddNoteForm";
import ZendAddTitleTAForm from "../ndifields/ZendAddTitleTAForm";
import {
  addOrUpdateNeoInvAftersalesActions,
  getNeoInvAftersalesActions,
  getNeoInvAftersalesActionsx,
  getNeoInvAftersalesActionsAll,
  getNeoInvAftersalesActionsGoto,
  deleteNeoInvAftersalesActions,
} from "../../actions/NeoInvAftersalesActionsAction";
import { Button, Input, Spinner } from "reactstrap";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { genLang } from "../../composables/genLang";
import {
  faAt,
  faCheck,
  faCopy,
  faPaperPlane,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { Alert } from "@mui/material";
import { ProductAvailabilitiesList } from "./ProductAvailabilitiesList";
import { getGroupsAndUsers, sendAssignedGroupUser } from '../../actions/NdiFieldsAction';
const BACKEND1 = process.env.REACT_APP_API_URLX;
let FIELD_VALIDATION = {};
FIELD_VALIDATION["id"] = {
  check: false,
  chkType: "String",
  message: "incorrect value",
};
FIELD_VALIDATION["name"] = {
  check: false,
  chkType: "String",
  message: "incorrect value",
};
FIELD_VALIDATION["description"] = {
  check: false,
  chkType: "String",
  message: "incorrect value",
};
FIELD_VALIDATION["amount"] = {
  check: false,
  chkType: "BigDecimal",
  message: "incorrect value",
};

FIELD_VALIDATION["idClient"] = {
  check: false,
  chkType: "String",
  message: "incorrect value",
};
FIELD_VALIDATION["firstname"] = {
  check: true,
  chkType: "String",
  message: "Ce champ est obligatoire",
};
FIELD_VALIDATION["lastname"] = {
  check: true,
  chkType: "String",
  message: "Ce champ est obligatoire",
};
FIELD_VALIDATION["cell"] = {
  check: true,
  chkType: "String",
  message: "Ce champ est obligatoire",
};
FIELD_VALIDATION["email"] = {
  check: true,
  chkType: "email",
  message: "Vous n'avez pas saisi une adresse e-mail valide",
};
FIELD_VALIDATION["id_users"] = {
  check: false,
  chkType: "int",
  message: "incorrect value",
};
FIELD_VALIDATION["language"] = {
  check: true,
  chkType: "String",
  message: "incorrect value",
};
FIELD_VALIDATION["sms"] = {
  check: false,
  chkType: "int",
  message: "incorrect value",
};
FIELD_VALIDATION["occupation"] = {
  check: true,
  chkType: "String",
  message: "Ce champ est obligatoire",
};

FIELD_VALIDATION["idAddress"] = {
  check: false,
  chkType: "String",
  message: "incorrect value",
};
FIELD_VALIDATION["address"] = {
  check: true,
  chkType: "String",
  message: "incorrect value",
};
FIELD_VALIDATION["city"] = {
  check: true,
  chkType: "String",
  message: "incorrect value",
};
FIELD_VALIDATION["province"] = {
  check: true,
  chkType: "String",
  message: "incorrect value",
};
FIELD_VALIDATION["cp"] = {
  check: true,
  chkType: "String",
  message: "incorrect value",
};
FIELD_VALIDATION["country"] = {
  check: false,
  chkType: "String",
  message: "incorrect value",
};
FIELD_VALIDATION["company"] = {
  check: false,
  chkType: "String",
  message: "incorrect value",
};
FIELD_VALIDATION["unitsuite"] = {
  check: false,
  chkType: "String",
  message: "incorrect value",
};

const URGENT_SALE_PERCENTAGE = 0.35

const TAXk_TVQ = 0.09975; //0.9975
const TAXk_TPS = 0.05;
const TAXk_TOTAL = 0.14975; //0.15;

const QUEBEC = "664df59d3e5eb38754f440d7"
const ONTARIO = "664df59d3e5eb38754f440d8"
const provinceMap = {
  qc:  QUEBEC,
  on: ONTARIO
}
const ENGLISH ="664df563387e588fa15b5ce8"
const FRENCH ="664df563387e588fa15b5ce7"
const langMap = {
  en: ENGLISH,
  fr: FRENCH
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

//let t=null;
//const NDIWebsite = "http://localhost:3001";
const NDIWebsite = "https://crm2.neolegal.ca";

class NeoVentesForm extends React.Component {
  validation = new Validations();
  actionMode = "new";
  requestTimer = null;
  backInvoiceId = 0;
  stripeAvoidManyTokens = 0;
  stripeTryTimes = 0;
  stripeNeoTokenTrack = 0;
  paymentBtnTryTimes2 = 0;
  paymentBtnTryTimes3 = 0;
  backLeadClId = 0;
  backLeadReId = 0;
  backLeadTiId = 0;
  ndiAlreadyLoggedin = false;
  ndiLoggedinQty = 0;

  constructor(props) {
    super(props);

    const { t } = this.props;
    const agentLang = t("curlang");

    this.stateInit = {
      id: 0,
      name: "",
      e_name: false,
      description: "",
      e_description: false,
      amount: "",
      e_amount: false,

      productCart: [],
      productId: 0,
      productTitre: "",
      product: {},
      productPrice: "",
      productPriceTax: "",
      productPriceTaxOnly: 0,
      productExtraOptions: [],
      productExtraOptionCtrl: null,
      productExtraOptionCtrl2: [],
      showProductOption: false,
      prodTagPrice: [],
      prodTagPriceTax: [],
      prodTagPriceTaxOnly: [],
      prodTagTotOptions: [],
      grandTotal: 0,

      idClient: "0",
      firstname: "",
      e_firstname: false,
      lastname: "",
      e_lastname: false,
      cell: "",
      e_cell: false,
      terms: "",
      e_terms: false,
      active: "",
      e_active: false,
      created: "",
      e_created: false,
      email: "",
      e_email: false,
      id_users: "0",
      e_id_users: false,
      //language:'fr', e_language: false,
      //language:agentLang, e_language: false,
      language: "",
      e_language: false,
      push: "",
      e_push: false,
      id_zendesk: "",
      e_id_zendesk: false,
      sms: "1",
      e_sms: false,
      stripe_id: "",
      e_stripe_id: false,
      occupation: "",
      e_occupation: false,

      idAddress: 0,
      address: "",
      e_address: false,
      unitsuite: "",
      e_unitsuite: false,
      city: "",
      e_city: false,
      province: "QC",
      e_province: false,
      province2: "",
      cp: "",
      e_cp: false,
      country: "Canada",
      e_country: false,
      company: "",
      e_company: false,
      existedClientRO: false,
      openPossibleZipCode: false,
      listAddressOnCode: null,
      listZipCodeAddresses: [],
      countryTuner: "",
      listPromotionCodes: [],
      codepromoOptions: [],
      openInfoStepsPopup: false,
      infoStepsPopupno: 0,
      infoProduct: { data: [] },
      infoProduct_title: "",
      infoProduct_excerpt: "",
      partialCanClose: 0,
      openTransactionPopup: false,
      isTransactionClosed: false,
      twoStepSale: "",

      listOfSuggestion: [],
      valueClientSearch: "",
      inputValueClientSearch: "",
      selectedIdSuggestion: "",
      clientInfo: {},
      clientAddress: {},
      globalStepWorkflow: 1, // 1 init 2:start with product 3:start with customerInfo 4:payment 5: case close
      workflowType: 1, //1 begin, 2 with clientInfo, 3 with Prod

      stripePaymentOpen: false,
      isLoaderOpen: false,
      toastrMessage: "",
      toastrType: "",
      toastrDuration: 6000,
      cuponcode: "",
      cuponcodeId: 0,
      cuponcodeName: "",
      showPromoLine: false,
      promoAmount: 0,
      promoPercentage: 0,
      promoTax1: 0,
      promoTax2: 0,
      orderBruteSubTotal: 0,
      orderNetSubTotal: 0,
      orderTVQ: 0,
      orderTPS: 0,
      orderTotal: 0,
      discountFreeTotal: 0,

      stripeToken: "",
      dossier: "", //421
      isRecurrentPayment: 0,
      nowPayment: 0,
      qtyPayment: 1,
      laterpayment: 4,
      fromDatePayment: "",
      paymentRound: 0,
      paymentWay: "full",
      nowPaymentRO: true,
      recurPaymentAmount: 0,
      paymentsReceived: [0],
      totalPaymentReceived: 0,
      paymentWayRO: false,
      beforePaymentTotal: 0,
      id_invoice: 0,
      pMethod: 1,
      status_invoice: "",
      showProdMulti: 0,
      productsList: { products: [] },
      openPListAsOnTheSite: false,
      errorTransacMessage: "",
      emailDoesNotExist: 0,
      openPossibleListOfClient: false,
      listClientOnCode: [],
      possibleListOfClient: [],
      statusChargeDesk: 0,
      chargeDeskIdStr: "",
      openPossibleCDStatusInfo: false,
      possibleCDStatusInfo: {},
      possibleListOfCDStatusInfo: [],
      taxInfo: {},
      isupsale: false,
      upsaleTicket: "",
      isAmerindian: false,
      no_amerindien: "",
      listOfTickets: [],

      source: "",
      showB2bLead: false,
      showSearchConflict: false,
      ticket_id: "",
      openMessageInfo: false,
      messageInfo: "",
      showRendevousBooking: false,
      calendarBookingInfo: "",
      sendMacro: false,
      sendMacroInfo: "",
      mergeTicketNote: false,
      mergeTicketNoteInfo: "",
      showmergeTicketNote: false,
      reasons: [],
      reason: "",
      salesreasontxt: "",
      oldtax1: 0,
      oldtax2: 0,
      openWinSTransaction: false,
      whichDate: "1",
      interacCDeskDate: "",

      listOfSuggestion2: [],
      valueClientSearch2: "",
      inputValueClientSearch2: "",
      selectedIdSuggestion2: "",
      b2b: {
        id: 0,
        id_b2b_list: "",
        e_id_b2b_list: false,
        id_external: "",
        e_id_external: false,
        name_id_external: "",
        e_name_id_external: false,
        firstname: "",
        e_firstname: false,
        lastname: "",
        e_lastname: false,
        email: "",
        e_email: false,
        tel: "",
        e_tel: false,
        start_date: "",
        e_start_date: false,
        expiry_date: "",
        e_expiry_date: false,
        address: "",
        e_address: false,
        city: "",
        e_city: false,
        province: "QC",
        e_province: false,
        postal_code: "",
        e_postal_code: false,
        raw_data: "",
        e_raw_data: false,
        date_created: "",
        e_date_created: false,
        date_updated: "",
        e_date_updated: false,
        status: "",
        e_status: false,
        dob: "",
        e_dob: false,
        id_clients: "",
        e_id_clients: false,
        company_name: "",
      },
      b2bIDCustomer: "",
      b2bIDCustomerContract: "",

      openPublicLinkForm: false,
      isSAASFormOpen: false,
      SAASForms: [],
      SAASPostLoading: false,
      SAASFormLink: "",
      SAASLinkCopied: false,
      SAASMessage: null,
      selectedSAASForm: null,
      publicLinkFormOrFile: 1,
      publicFormOrFile: {},
      openAssignTicketForm: false,
      openZendFusionNoteForm: false,
      sendPublicFormtxt: "",
      assignTicketFormtxt: "",
      mergeTicketNotetxt: "",
      zendFusionNotetxt: "",
      openZendAddNoteForm: false,
      zendAddNotetxt: "",
      lead2actionFormtxt: "",
      searchConflicttxt: "",
      bookingFormtxt: "",
      lead2actionFormtxt2: "",
      statenameid: "",
      lead2actionFormtxt3: "",
      biginvoice: {},
      openZendAddTitleTAForm: false,
      zendAddTitleTAtxt: "",
      ticketList: [],
      actionsaftersales: [],
      tentative: "0",
      productAvailableLoading: false,
      groups: [],
      groupDomains: [],
      urgentSale: false,
      urgentSaleDollarAmount: 0,
      salePrecision: "",
    };
    this.state = JSON.parse(JSON.stringify(this.stateInit)); // Object.assign(this.stateInit);
    let workflowIni = true;
    let workflowClientInfo = false;
    let workflowProductList = false;
    const locurl = window.location.href;
    const patt = /([0-9]+)(\/edit)$/;
    const patt2 = /([0-9]+)(\/invoice)$/;
    const patt3 = /([0-9]+)\/([0-9]+)\/([0-9]+)(\/fromlead)/;
    const patt4 = /([0-9]+)(\/leadId)$/;
    const patt5 = /([0-9]+)\/([0-9]+)(\/onSaleClose)/;

    if (patt.test(locurl)) {
      this.actionMode = "edit";
    } else if (patt2.test(locurl)) {
      this.actionMode = "partialInvoice";
      const regex = /([0-9]+)(\/invoice)$/g; ///[A-Z]/g;
      const foundText = locurl.match(regex);
      const cleanId = ("" + foundText[0]).replace("/invoice", "");
      this.backInvoiceId = cleanId;
    } else if (patt3.test(locurl)) {
      this.actionMode = "fromLead";
      const regex = /([0-9]+)(\/invoice)$/g; ///[A-Z]/g;
      const foundText = locurl.match(patt3);
      const cleanId = foundText[1];
      this.backLeadClId = parseInt(foundText[1]); //client id
      this.backLeadReId = parseInt(foundText[2]); //lead id
      this.backLeadTiId = parseInt(foundText[3]); //ticket id
    } else if (patt4.test(locurl)) {
      this.actionMode = "fromLeadportal";
      const foundText = locurl.match(patt4);
      const cleanId = foundText[0];
      this.backLeadReId = parseInt(foundText[1]); //lead id
    } else if (patt5.test(locurl)) {
      this.actionMode = "onSaleClose"; //'fromLead';
      const regex = /([0-9]+)(\/invoice)$/g; ///[A-Z]/g;
      const foundText = locurl.match(patt5);
      const cleanId = foundText[1];
      this.backLeadReId = parseInt(foundText[1]); //sale id
      this.backLeadTiId = parseInt(foundText[2]); //ticket id
    } else {
      this.actionMode = "new";
      if (this.props.frmGoXonSaleClosePopup !== undefined) {
        this.actionMode = "onSaleClose"; //'fromLead';
        this.backLeadReId = parseInt(this.props.invoiceIdPopup); //sale id
        this.backLeadTiId = parseInt(this.props.ticket_idPopup); //ticket id
        let ticketList = [];
        if (
          this.props.ticketList !== null &&
          this.props.ticketList !== undefined
        ) {
          ticketList = this.props.ticketList;
          this.setState({ ["ticketList"]: ticketList });
        }
        this.setState({
          ["ticket_id"]: this.backLeadTiId,
          ["ticketList"]: ticketList,
        });
      }
    }
    this.setExtraServiceForProduct = this.setExtraServiceForProduct.bind(this);
    this.prodPrice = [];
    this.prodPriceTVQ = [];
    this.prodPriceTPS = [];
    this.prodTaxOnly = [];
    this.prodPriceAndTax = [];
    this.prodPriceAndTax2 = [];
    this.prodPriceServices = [];
    this.prodPriceSubtotals = [];
    this.paymentWayRef = React.createRef();
    this.searchOptions = {};

    this.navigationTopRefOnTop = React.createRef();
    this.iframeRef = React.createRef();
    this.priceRef = React.createRef();

    //t=this.props.t;
    //const { t } = this.props;
    this.promoDefaultCode = {
      id: "1",
      percentage: "0",
      description: "Aucun promo/Référence",
      valid_start: null,
      valid_end: null,
      active: "1",
      created: "2020-12-14 20:03:51",
      name: "nopromo",
      usage: "0",
    };
  }
  productsList = { products: [] }; // this.state.productsList; // productList();

  handleChangeTA = (event) => {
    const { t } = this.props;
    this.setState({ [event.target.name]: event.target.value });
    this.setState({
      sendPublicFormtxt: "",
      assignTicketFormtxt: "",
      mergeTicketNotetxt: "",
      zendFusionNotetxt: "",
      zendAddNotetxt: "",
      bookingFormtxt: "",
    });
    this.getAllDoneActionsAftersalesList(event);
  };

  handleChange = (event) => {
    const { t } = this.props;
    this.setState({ [event.target.name]: event.target.value });
    const globalStepWorkflow = this.state.globalStepWorkflow;
    if (this.state.existedClientRO) {
      const fulname = this.state.firstname + " " + this.state.lastname;
      this.showToast(t("zqwNVFrmEl1_x") + "  " + fulname, "warning");
    }
  };
  handleCheckBoxCheck = (event) => {
    let isChecked = false;
    let onOffval = "0";
    if (event.target.checked) {
      isChecked = true;
      onOffval = "1";
    }
    if (event.target.name === "sms") {
      this.setState({ [event.target.name]: onOffval });
    } else {
      this.setState({ [event.target.name]: isChecked });
    }

    if (event.target.name === "isupsale" && isChecked) {
      this.getClientTransactionList(-7);
    }

    if (event.target.name === "isAmerindian") {
      const taxInfo = this.state.taxInfo;
      const taxISO = taxInfo["iso"];
      setTimeout(() => {
        this.sethandleChangeProvince(taxISO);
      }, 500);
    }

    if (event.target.name === "urgentSale") {
      const isChecked = event.target.checked;
      this.setState({urgentSale: isChecked}, () => this.calculateGrandTotal(null, isChecked));
    }
  };

  handleCheckBoxCheckOnOff = (event) => {
    let isChecked = false;
    let onOffval = "0";
    if (event.target.checked) {
      isChecked = true;
      onOffval = "1";
    }
    this.setState({ [event.target.name]: onOffval });
  };

  handleCheckBoxCheckBtn = (event) => {
    let isChecked = false;
    let onOffval = "0";
    const stateName = event.target.dataset.statename;
    const statenameid = event.target.dataset.statenameid;
    let statenameidx = "";
    if (event.target.checked) {
      isChecked = true;
      onOffval = "1";
    }
    const { t } = this.props;
    let currentTic = this.state.ticket_id;
    const upsaleTicket = this.state.upsaleTicket;
    const searchConflicttxt = this.state.searchConflicttxt;
    if (statenameid !== undefined) {
      statenameidx = "" + statenameid;
    }
    if (stateName !== "showSearchConflict") {
      if (
        (currentTic === "" &&
          (upsaleTicket === null || upsaleTicket === "") &&
          searchConflicttxt === "") ||
        (currentTic !== "" && upsaleTicket === "" && searchConflicttxt === "")
      ) {
        this.showToast(t("zqwMesas016_x") + "  ", "error");
        return false;
      }
    }
    this.setState({ [stateName]: true, ["statenameid"]: statenameidx });
  };
  handleChangeProvince = (event) => {
    const { t } = this.props;
    this.setState({ [event.target.name]: event.target.value });
    const globalStepWorkflow = this.state.globalStepWorkflow;
    if (this.state.existedClientRO) {
      const fulname = this.state.firstname + " " + this.state.lastname;
      this.showToast(t("zqwNVFrmEl1_x") + "  " + fulname, "warning");
    }
    this.sethandleChangeProvince(event.target.value);
  };
  handleChangeEmailFocus = (event) => {
    const { t } = this.props;
    this.setState({ [event.target.name]: event.target.value });
    //this.setState({ ["emailDoesNotExist"]: 0 });
    const emailneo = event.target.value;
    const emailBan = [
      "no-reply@neolegal.ca",
      "noreply@neolegal.ca",
      "serviceclient@neolegal.ca",
      "support@neolegal.ca",
      "it@neolegal.ca",
    ];
    const idClient = this.state.idClient;
    if (parseFloat("" + idClient) > 0) {
      this.showToast(
        "Emmail popup est bloqué pour éviter des dégats. / Email popup is blocked to avoid disasters. ",
        "warning"
      );
      return false;
    }

    if (
      FIELD_VALIDATION[event.target.name] !== undefined &&
      FIELD_VALIDATION[event.target.name].check === true
    ) {
      this.setState({ ["e_" + event.target.name]: false });
      if (
        !this.validation.validate(
          FIELD_VALIDATION[event.target.name].chkType,
          event.target.value
        )
      ) {
        this.setState({ ["e_" + event.target.name]: true });
      }
    }
    const globalStepWorkflow = this.state.globalStepWorkflow;
    if (this.state.existedClientRO) {
      const fulname = this.state.firstname + " " + this.state.lastname;
      return false;
    }
    if (emailBan.includes(emailneo)) {
      this.setState({
        ["openMessageInfo"]: true,
        ["messageInfo"]: t("zqwNDICItxl8_x") + " " + emailneo,
      });
      return false;
    }
    this.setState({ ["emailDoesNotExist"]: 0 });
    //const emailneo=event.target.value;
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(emailneo)) {
      //this.getClientSearchSuggestion(emailneo);
      const serachObj = { search: "", searchEmail: emailneo };
      const this2 = this;
      Promise.resolve(this.props.getClientSearch(serachObj))
        .then((response) => {
          const myList = response.NeoClientsOne;
          let clientListx = [];

          if (myList.length > 0) {
            let listAddressOnCode =
              myList.length > 0 &&
              myList.map((item, i) => {
                const firstname = item.firstname;
                const lastname = item.lastname;
                const email = item.email;
                const zx = (
                  <tr key={i}>
                    <td>{firstname}</td>
                    <td>{lastname}</td>
                    <td>{email}</td>
                    <td>
                      {" "}
                      <a
                        href="#"
                        data-client={i}
                        onClick={this2.clientCustomerSetter}
                      >
                        <FolderIcon />
                      </a>{" "}
                    </td>
                  </tr>
                );
                clientListx.push(zx);

                return (
                  <tr key={i}>
                    <td>{firstname}</td>
                    <td>{lastname}</td>
                    <td>{email}</td>
                    <td>
                      {" "}
                      <a
                        href="#"
                        data-client={i}
                        onClick={this2.clientCustomerSetter}
                      >
                        <FolderIcon />
                      </a>{" "}
                    </td>
                  </tr>
                );
              }, this);
            this.setState({
              ["openPossibleListOfClient"]: true,
              ["possibleListOfClient"]: clientListx,
              listClientOnCode: myList,
            });
          } else {
            this.setState({ ["emailDoesNotExist"]: 1 });
            this.showToast(
              t("zqwNVFrmEl2_x", { emailneo: emailneo }) + " ",
              "success"
            );
            //this.showToast(t("zqwNVFrmEl1_x", {emailneo:emailneo})+"L'email "+emailneo+" de ce client n'existe pas tu peux continuer.", "success");
          }

          return response;
        })
        .catch((e) => {
          console.error(e);
        });
    } else {
      this.setState({ ["emailDoesNotExist"]: 0 });
    }

    //}, 3000);
  };
  handleChangeCountrypays = (event) => {
    const cval = event.target.value;
    this.setState({ ["country"]: event.target.value });
    if (cval === "Canada") {
      this.setState({ ["province"]: "QC", ["province2"]: "", ["cp"]: "" });
    } else {
      this.setState({ ["province"]: "", ["province2"]: "", ["cp"]: "" });
    }
  };
  handleCountryTuner = (event) => {
    const cval = event.target.value;
    this.setState({ [event.target.name]: event.target.value });
    if (cval === "") {
      this.searchOptions = {};
    } else {
      // and limit the results to addresses only
      let searchOptions = {
        //location: new google.maps.LatLng(-34, 151),
        radius: 2000,
        types: ["address"],
      };

      var optionsqqqq = {
        types: ["(cities)"],
        componentRestrictions: { country: "fr" },
      };

      searchOptions = {
        types: ["street_address"],
      };

      this.searchOptions = {
        types: ["address"],
        componentRestrictions: { country: cval },
      };
    }
  };
  handleChangeChkBox = (event) => {
    let valx = 0;
    if (event.target.checked) {
      valx = 1;
    }
    this.setState({ [event.target.name]: valx });
  };
  handleChangePaymentWay = (event) => {
    const valuex = event.target.value;
    this.setState({ [event.target.name]: event.target.value });
    const productPriceTotal = this.state.grandTotal;
    if (valuex === "full") {
      this.setState({
        ["isRecurrentPayment"]: 0,
        ["nowPaymentRO"]: true,
        ["nowPayment"]: productPriceTotal,
      });
    } else if (valuex === "partial") {
      this.setState({ ["isRecurrentPayment"]: 0, ["nowPaymentRO"]: false });
    } else if (valuex === "recurrent") {
      this.setState({ ["isRecurrentPayment"]: 1, ["nowPaymentRO"]: false });

      const downPayment = this.state.nowPayment;
      const paymentRound = this.state.paymentRound;
      const id_invoice = this.state.id_invoice;

      const productPriceTotalx = this.state.orderTotal;
      const totalPaymentReceived = this.state.totalPaymentReceived;
      const productPriceTotal = productPriceTotalx - totalPaymentReceived; //.toFixed(2) ;

      const nowPayment = (productPriceTotal / 2).toFixed(2);
      const recurPaymentAmount = (productPriceTotal / 2).toFixed(2);
      const recPaymentDate = moment().add(1, "M").format("YYYY-MM-DD"); //""; //set one month from today

      this.setState({
        ["nowPayment"]: nowPayment,
        ["recurPaymentAmount"]: recurPaymentAmount,
        ["fromDatePayment"]: recPaymentDate,
      });
    } else {
    }
  };

  handleChangeRecurrent = (event) => {
    const { t } = this.props;
    event.preventDefault();
    const productPriceTotal = this.state.grandTotal;
    const neoVal = event.target.value * 1;
    if (neoVal > productPriceTotal) {
      this.showToast(t("zqwNVFrmEl3_x") + "  " + productPriceTotal, "error");
      return false;
    }
    this.setState({ [event.target.name]: event.target.value });
    let this2 = this;
    setTimeout(function () {
      const paymentWay = this2.state.paymentWay;
      const qtyPayment = this2.state.qtyPayment;
      const downPayment = this2.state.nowPayment;
      const paymentRound = this2.state.paymentRound;
      const id_invoice = this2.state.id_invoice;

      const productPriceTotalx = this2.state.orderTotal;
      const totalPaymentReceived = this2.state.totalPaymentReceived;
      const productPriceTotal = productPriceTotalx - totalPaymentReceived; //.toFixed(2) ;

      if (paymentWay === "full") {
      } else if (paymentWay === "partial") {
      } else if (paymentWay === "recurrent") {
        const recurPaymentAmount = Math.ceil(
          (productPriceTotal - downPayment) / qtyPayment
        ).toFixed(2);
        this2.setState({ ["recurPaymentAmount"]: recurPaymentAmount });
      }
    }, 300);
  };
  handleFocusout = (event) => {
    if (
      FIELD_VALIDATION[event.target.name] !== undefined &&
      FIELD_VALIDATION[event.target.name].check === true
    ) {
      this.setState({ ["e_" + event.target.name]: false });
      if (
        !this.validation.validate(
          FIELD_VALIDATION[event.target.name].chkType,
          event.target.value
        )
      ) {
        this.setState({ ["e_" + event.target.name]: true });
      }
    }
  };

  handleCloseMessageInfo = (anyEvent) => {
    this.setState({ ["openMessageInfo"]: false });
  };

  handleOpenMessageInfo = (event) => {
    this.setState({ ["openMessageInfo"]: true });
  };

  MessageInfoPopup = (event) => {
    const open = this.state.openMessageInfo; //lg sm md  fullWidth={"sm"} maxWidth={"lg"}
    const { t } = this.props;
    const messageInfo = this.state.messageInfo;
    if (open === false) {
      return null;
    }

    return (
      <div>
        <Dialog
          maxWidth={"md"}
          open={open}
          onClose={this.handleCloseMessageInfo}
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="form-dialog-confirm"
        >
          <DialogContent id="form-dialog-confirm-msgInfo">
            <div className="form-row neoxtbl">
              <p>&nbsp;</p>
              <p>{messageInfo}</p>

              <div className="form-group col-md-9">
                <br />
                <input
                  type="button"
                  onClick={this.handleCloseMessageInfo}
                  className="btn btn-default"
                  value={t("Close_x")}
                />{" "}
                &nbsp; &nbsp;
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  };

  openStripePayment = (event) => {
    this.setState({ ["stripePaymentOpen"]: true });
    //window.alert('heloo stripe .................');
  };

  inputEventListener = (event) => {
    if (event.origin !== "https://crm2.neolegal.ca") {
      // console.log("REJECTED INPUT LISTENER", event.origin);
      return;
    }
    var iframeSource = NDIWebsite + "/Login";

    var iframe = null;
    var iframeEl = document.getElementById("the_iframe0007");
    if (iframeEl === undefined || iframeEl === null) {
      iframe = document.createElement("iframe");
      iframe.setAttribute("src", iframeSource);
      iframe.setAttribute("id", "the_iframe0007");
      iframe.style.width = 450 + "px";
      iframe.style.height = 200 + "px";
      iframe.style.display = "none";
      document.body.appendChild(iframe);
      iframeEl = document.getElementById("the_iframe0007");
    }
    const msgIframe = JSON.parse(JSON.stringify(localStorage));
    msgIframe.message = "HEEEEELO 00007 FROM SALES.... ";
    iframeEl.contentWindow.postMessage(msgIframe, "*");
    if (this.ndiAlreadyLoggedin === false) {
      if (this.ndiLoggedinQty < 3 && iframeEl) {
        setTimeout(function () {
          this.ndiLoggedinQty++;
          iframeEl.contentWindow.postMessage(msgIframe, "*");
        }, 2000);
      }
    }
    if (event.data === "infoLoggedinReceivedAndApplied") {
      this.ndiAlreadyLoggedin = true;
      if (iframe !== undefined && iframe !== null) {
        iframe.remove();
      } else if (iframeEl !== undefined && iframeEl !== null) {
        iframeEl.remove();
      }
      return;
    }

    const msgFromIframe = event.data; //JSON.parse(JSON.stringify(localStorage));
    if (
      msgFromIframe !== undefined &&
      msgFromIframe.messageFunc !== undefined &&
      msgFromIframe.messageFunc !== null &&
      msgFromIframe.messageFunc === "setMessageDoneAction"
    ) {
      this.setMessageDoneAction(msgFromIframe);
    }
  };

  bookingEventListener = (event) => {
    if (
        ![
          "https://admin.neolegal.ca",
          "https://www.neodoc.app",
          "https://stg.neodoc.app",
          "http://localhost:3000"
        ].includes(event.origin)
    ) {
      // console.log("REJECTED BOOKING EVENT LISTENER", event.origin);
      return;
    }
    
    const dataReceived = JSON.parse(event.data);
    // console.log("DATA FROM BOOKING", dataReceived);
    moment.locale("en");
    if (this.props.t("curlang") === "fr") {
      moment.locale("fr", [frLocale]);
    }
    if (
      dataReceived !== undefined &&
      dataReceived !== null &&
      dataReceived.name === "booking"
    ) {
      // console.log("ENTERED BOOKING LOOP");
      const user = dataReceived.user ?? dataReceived.users?.[0];
      const config = dataReceived.taken?.config?.data;
      const date = dataReceived.date ?? dataReceived.start;
      const name = user.firstname ? `${user.firstname} ${user.lastname}` : (user.name ?? '');
      const fullTxt = `${name} ${moment(date).format("LLLL")}`;
      this.setState({ ["calendarBookingInfo"]: " (" + fullTxt + ")" });
      const taken = {
        user,
        config: config ? JSON.parse(config) : undefined,
      };
      const idx2 = this.backLeadReId;
      let ticket_id = this.state.ticket_id;
      const invoiceId = this.state.id_invoice;
      let invoiceIdx = 0;
      
      if (this.props.frmGoXonSaleClosePopup !== undefined) {
        if (
          this.backLeadTiId > 0 &&
          (ticket_id < 1 || ticket_id === "" || ticket_id === "0")
        ) {
          ticket_id = this.backLeadTiId;
        }
      }

      if (
        idx2 > 0 &&
        (invoiceId < 1 || invoiceId === "" || invoiceId === "0")
      ) {
        invoiceIdx = idx2;
      } else if (invoiceId > 0) {
        invoiceIdx = invoiceId;
      }

      const rawobj = {
        message: fullTxt,
        taken: taken,
        ticket_id: "" + ticket_id,
      };

      const aftobj2 = {
        action_no: "2",
        action_text: "" + fullTxt,
        ticket_id: "" + ticket_id,
        invoice_id: "" + invoiceIdx,
        raw: rawobj,
      };

      Promise.resolve(this.props.addOrUpdateNeoInvAftersalesActions(aftobj2, 1))
        .catch((e) => {
          console.error(e);
        });
    } else {
      // console.log("DIDN'T BOOKING LOOP");
    }
  };

  componentDidMount() {
    this.getSAASForms();
    this.getGroups();
    window.addEventListener("message", this.bookingEventListener);
    window.addEventListener("message", this.inputEventListener);
    const { t } = this.props;
    const taxInfo = getProvinceTaxInfo("QC");
    this.setState({ ["taxInfo"]: taxInfo, language: "" });
    if (!window.google) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBNT3gyx565iSZCCAjtIiwuWOjQmZVcjt4&libraries=places`;
      script.async = true;
      document.body.appendChild(script);
    }

    if (this.actionMode === "edit") {
      if (this.props.NeoVentes.id === undefined) {
        const idx = this.props.match.params.id;
        Promise.resolve(this.props.getNeoVentes(idx))
          .then((response) => {
            this.setState(response.NeoVentesOne);
            return response;
          })
          .catch((e) => {
            console.error(e);
          });
      }
    } else if (this.actionMode === "partialInvoice") {
      if (this.props.NeoVentes.id === undefined) {
        const idx =
          this.props.match === undefined
            ? this.backInvoiceId
            : this.props.match.params.id;
        Promise.resolve(this.props.getPartialInvoicePayments(idx))
          .then((response) => {
            const payInfoCli = response.NeoCodePromoOne.data;
            const id_clients = payInfoCli.invoice.id_clients;
            const salePrecision = payInfoCli.invoice.salePrecision || "";
            this2.setState({
              ["id_invoice"]: payInfoCli.invoice.id,
              ["status_invoice"]: payInfoCli.invoice.status,
              id_clients: id_clients,
              nowPayment: payInfoCli.invoice.amountToPay,
              nowPaymentRo: true,
              email: payInfoCli.client.email,
              salePrecision
            });
            this.workflowProductList = false;
            this.workflowClientInfo = false;
            this.setState({ ["globalStepWorkflow"]: 4 });
            this.setState({ ["biginvoice"]: payInfoCli.invoice });

            let totalPaymentReceived = payInfoCli.invoice.paymentReceived; // 0;
            let paymentsReceived = this.state.paymentsReceived;
            const txtv = totalPaymentReceived * 1; //+(downPayment*1));
            paymentsReceived.push(totalPaymentReceived);
            this.setState({
              ["idClient"]: id_clients,
              ["id_users"]: payInfoCli.invoice.id_users,
              ["totalPaymentReceived"]: txtv,
              ["paymentsReceived"]: paymentsReceived,
              ["paymentWayRO"]: true,
            });

            //pMethod
            const paymentList = payInfoCli.payments;
            if (
              payInfoCli.invoice.code !== null &&
              payInfoCli.invoice.code.indexOf("chargedesk") > -1 &&
              payInfoCli.invoice.result.indexOf("ch_") < 0 &&
              payInfoCli.invoice.status === "sent"
            ) {
              let chargeDeskIdStr = "";
              if (payInfoCli.invoice.result !== "") {
                chargeDeskIdStr = payInfoCli.invoice.result;
              }
              //this.setState({ ["statusChargeDesk"]: 4, ["chargeDeskIdStr"]: chargeDeskIdStr});
            } else if (paymentList.length > 1) {
              const lastPayment = paymentList[paymentList.length - 1];
              if (
                lastPayment.code !== null &&
                lastPayment.code.indexOf("chargedesk_step1") > -1
              ) {
                let chargeDeskIdStr = "";
                const cdstr = ("" + lastPayment.token)
                  .replace("CHD_", "")
                  .replace("chd_", "");
                if (cdstr !== "") {
                  chargeDeskIdStr = cdstr; //}
                  //this.setState({ ["statusChargeDesk"]: 4, ["chargeDeskIdStr"]: chargeDeskIdStr});
                }
              }
            }

            if (payInfoCli.invoice.amountToPay == 0) {
              //they have just to close the sale & create ticket
              this.setState({ ["partialCanClose"]: 4 });
              this.showToast(t("zqwNVFrmEl4_x") + "  ", "success");
            }
            this.setState(payInfoCli.client);
            this.getClientFullAddress(id_clients);
            let productCart = [];
            const basePrice = payInfoCli.invoice.sub_total;
            const myCart = {
              index: 0,
              id: 99999,
              productTitre: "Partial",
              productPrice: basePrice,
              productPrice2: basePrice,
              productPriceTax: 0,
              productPriceTaxOnly: 0,
              totOptions: 0,
              options: [],
            };
            productCart[0] = myCart; //productCart.splice(index, 1); //productCart.push(myCart);
            this.setState({
              ["productCart"]: productCart,
              ["twoStepSale"]: payInfoCli.invoice.code,
              ["oldtax1"]: payInfoCli.invoice.tax1,
              ["oldtax2"]: payInfoCli.invoice.tax2,
            });

            const taxInfo = getProvinceTaxInfo(payInfoCli.invoice.province);
            let isAmerindian = false;
            const taxISO = payInfoCli.invoice.province;
            let no_amerindien = "";
            if (
              payInfoCli.invoice.no_amerindien !== null &&
              payInfoCli.invoice.no_amerindien !== ""
            ) {
              isAmerindian = true;
              no_amerindien = payInfoCli.invoice.no_amerindien;
            }

            let isupsale = false;
            let upsaleTicket = "";
            if (
              payInfoCli.invoice.upsale_ticket !== null &&
              payInfoCli.invoice.upsale_ticket !== ""
            ) {
              isupsale = true;
              upsaleTicket = payInfoCli.invoice.upsale_ticket;
            }
            this.setState({
              ["taxInfo"]: taxInfo,
              ["province"]: taxISO,
              ["isupsale"]: isupsale,
              ["upsaleTicket"]: upsaleTicket,
              ["isAmerindian"]: isAmerindian,
              ["no_amerindien"]: no_amerindien,
            });
            setTimeout(() => {
              this.sethandleChangeProvince(taxISO);
            }, 400);
            //this.calculateGrandTotal(null) ;
            setTimeout(() => {
              this.calculateGrandTotal(null, this.state.urgentSale);
            }, 1000);
            return response;
          })
          .catch((e) => {
            console.error(e);
          });
      }
    } else if (this.actionMode === "fromLead") {
      console.log("actionMode= fromlead ", this.backLeadClId);
      if (this.backLeadClId > 0) {
        Promise.resolve(this.props.getClientAndAddress(this.backLeadClId))
          .then((response) => {
            const obj = response.NeoAddressOne;
            const newValue = obj.client;
            const address = obj.address;
            const clientInfo = {
              id_clients: newValue.id,
              idClient: newValue.id,
              firstname: newValue.firstname,
              lastname: newValue.lastname,
              cell: newValue.cell,
              terms: newValue.terms,
              active: newValue.active,
              email: newValue.email,
              id_users: parseInt(newValue.id_users),
              language: newValue.language,
              push: newValue.push,
              id_zendesk: newValue.id_zendesk,
              sms: newValue.sms,
              stripe_id: newValue.stripe_id,
              occupation: newValue.occupation,
            };
            const clientAddress = {
              idAddress: address.id,
              address: address.address,
              city: address.city,
              province: address.province,
              cp: address.cp,
              country: address.country,
              company: address.company,
            };
            this.setState(clientInfo); //xaza  this.setState({ clientInfo });
            this.setState(clientAddress); //this.setState({ clientAddress });
            this.setState({ ["globalStepWorkflow"]: 2, ["workflowType"]: 2 });

            this.setState({
              ["existedClientRO"]: true,
              ["emailDoesNotExist"]: 1,
              ["openPossibleListOfClient"]: false,
            });
            // this.clientFullInfoValidate();
            setTimeout(() => {
              this.clientFullInfoValidate();
            }, 100);

            return response;
          })
          .catch((e) => {
            console.error(e);
          });
      }
    } else if (this.actionMode === "fromLeadportal") {
      console.log("actionMode= fromLeadportal ", this.backLeadReId);
      if (this.backLeadReId > 0) {
        Promise.resolve(
          this.props.getClientAndAddressByLead(0, this.backLeadReId)
        )
          .then((response) => {
            //productsList=response.NeoVentes;
            const obj = response.NeoAddressOne;
            let newValue = {};
            let client_id = 0;
            let address = {};
            let contact = {};
            if (
              obj !== null &&
              obj.client !== undefined &&
              obj.client !== null
            ) {
              newValue = obj.client;
            }
            if (
              obj !== null &&
              obj.address !== undefined &&
              obj.address !== null
            ) {
              address = obj.address;
            }
            if (
              obj !== null &&
              obj.contact !== undefined &&
              obj.contact !== null
            ) {
              contact = obj.contact;
            }
            if (
              obj !== null &&
              obj.client_id !== undefined &&
              obj.client_id !== null &&
              parseInt(obj.client_id) > 0
            ) {
              client_id = parseInt(obj.client_id);
            }

            if (client_id > 0) {
              const clientInfo = {
                id_clients: newValue.id,
                idClient: newValue.id,
                firstname: newValue.firstname,
                lastname: newValue.lastname,
                cell: newValue.cell,
                terms: newValue.terms,
                active: newValue.active,
                email: newValue.email,
                id_users: parseInt(newValue.id_users),
                language: newValue.language,
                push: newValue.push,
                id_zendesk: newValue.id_zendesk,
                sms: newValue.sms,
                stripe_id: newValue.stripe_id,
                occupation: newValue.occupation,
              };
              const clientAddress = {
                idAddress: address.id,
                address: address.address,
                city: address.city,
                province: address.province,
                cp: address.cp,
                country: address.country,
                company: address.company,
              };
              this.setState(clientInfo); //xaza  this.setState({ clientInfo });
              this.setState(clientAddress); //this.setState({ clientAddress });
              this.setState({ ["globalStepWorkflow"]: 2, ["workflowType"]: 2 });

              this.setState({
                ["existedClientRO"]: true,
                ["emailDoesNotExist"]: 1,
                ["openPossibleListOfClient"]: false,
              });
              // this.clientFullInfoValidate();
              setTimeout(() => {
                this.clientFullInfoValidate();
              }, 100);
            } else {
              if (contact !== null && contact.id !== undefined) {
                const clientInfo = {
                  firstname: contact.firstname,
                  lastname: contact.lastname,
                  cell: contact.cell,
                  email: contact.email,
                  language: contact.language,
                  province: contact.province,
                };
                this.setState(clientInfo); //xaza  this.setState({ clientInfo });
                this.setState({
                  ["globalStepWorkflow"]: 3,
                  ["workflowType"]: 3,
                });
              }
            }
            return response;
          })
          .catch((e) => {
            console.error(e);
          });
      }
    } else if (this.actionMode === "onSaleClose") {
      console.log("actionMode= onSaleClose ", this.backLeadClId);
      const idx = this.backLeadReId;
      Promise.resolve(this.props.getPartialInvoicePayments(idx))
        .then((response) => {
          const payInfoCli = response.NeoCodePromoOne.data;
          const id_clients = payInfoCli.invoice.id_clients;
          const salePrecision = payInfoCli.invoice.salePrecision || ""
          this2.setState({
            ["id_invoice"]: payInfoCli.invoice.id,
            ["status_invoice"]: payInfoCli.invoice.status,
            id_clients: id_clients,
            nowPayment: payInfoCli.invoice.amountToPay,
            nowPaymentRo: true,
            email: payInfoCli.client.email,
            salePrecision
          });
          this.workflowProductList = false;
          this.workflowClientInfo = false;
          this.setState({
            ["globalStepWorkflow"]: 4,
            ["upsaleTicket"]: "" + this.backLeadTiId,
            ["isTransactionClosed"]: true,
          });
          this.setState({ ["biginvoice"]: payInfoCli.invoice });

          let totalPaymentReceived = payInfoCli.invoice.paymentReceived; // 0;
          let paymentsReceived = this.state.paymentsReceived;
          const txtv = totalPaymentReceived * 1; //+(downPayment*1));
          paymentsReceived.push(totalPaymentReceived);
          this.setState({
            ["idClient"]: id_clients,
            ["id_users"]: payInfoCli.invoice.id_users,
            ["totalPaymentReceived"]: txtv,
            ["paymentsReceived"]: paymentsReceived,
            ["paymentWayRO"]: true,
          });

          //pMethod
          const paymentList = payInfoCli.payments;
          if (
            payInfoCli.invoice.code !== null &&
            payInfoCli.invoice.code.indexOf("chargedesk") > -1 &&
            payInfoCli.invoice.result.indexOf("ch_") < 0 &&
            payInfoCli.invoice.status === "sent"
          ) {
            let chargeDeskIdStr = "";
            if (payInfoCli.invoice.result !== "") {
              chargeDeskIdStr = payInfoCli.invoice.result;
            }
            //this.setState({ ["statusChargeDesk"]: 4, ["chargeDeskIdStr"]: chargeDeskIdStr});
          } else if (paymentList.length > 1) {
            const lastPayment = paymentList[paymentList.length - 1];
            if (
              lastPayment.code !== null &&
              lastPayment.code.indexOf("chargedesk_step1") > -1
            ) {
              let chargeDeskIdStr = "";
              const cdstr = ("" + lastPayment.token)
                .replace("CHD_", "")
                .replace("chd_", "");
              if (cdstr !== "") {
                chargeDeskIdStr = cdstr; //}
              }
            }
          }

          if (payInfoCli.invoice.amountToPay == 0) {
            //they have just to close the sale & create ticket
            this.setState({ ["partialCanClose"]: 4 });
            this.showToast(t("zqwNVFrmEl4_x") + "  ", "success");
          }

          this.setState(payInfoCli.client);
          this.getClientFullAddress(id_clients);
          let productCart = [];
          const basePrice = payInfoCli.invoice.sub_total;
          const myCart = {
            index: 0,
            id: 99999,
            productTitre: "Partial",
            productPrice: basePrice,
            productPrice2: basePrice,
            productPriceTax: 0,
            productPriceTaxOnly: 0,
            totOptions: 0,
            options: [],
          };
          productCart[0] = myCart; //productCart.splice(index, 1); //productCart.push(myCart);
          this.setState({
            ["productCart"]: productCart,
            ["twoStepSale"]: payInfoCli.invoice.code,
            ["oldtax1"]: payInfoCli.invoice.tax1,
            ["oldtax2"]: payInfoCli.invoice.tax2,
          });

          const taxInfo = getProvinceTaxInfo(payInfoCli.invoice.province);
          let isAmerindian = false;
          const taxISO = payInfoCli.invoice.province;
          let no_amerindien = "";
          if (
            payInfoCli.invoice.no_amerindien !== null &&
            payInfoCli.invoice.no_amerindien !== ""
          ) {
            isAmerindian = true;
            no_amerindien = payInfoCli.invoice.no_amerindien;
          }

          let isupsale = false;
          let upsaleTicket = "" + this.backLeadTiId;
          this.setState({
            ["taxInfo"]: taxInfo,
            ["province"]: taxISO,
            ["isupsale"]: isupsale,
            ["upsaleTicket"]: upsaleTicket,
            ["isAmerindian"]: isAmerindian,
            ["no_amerindien"]: no_amerindien,
          });
          return response;
        })
        .catch((e) => {
          console.error(e);
        });
      const aftobj = { ticket_id: "0", invoice_id: "" + idx };

      Promise.resolve(this.props.getNeoInvAftersalesActionsx(aftobj))
        .then((response) => {
          const respo = response.NeoInvAftersalesActionsOne;
          console.log("actionsaftersales= ", respo);
          this.setState({ ["actionsaftersales"]: respo });
          this.handleSalespopupToClose(respo);

          return response;
        })
        .catch((e) => {
          console.error(e);
        });
    } else {
    }
    //getAllProducts
    let myLang = localStorage.getItem("i18nextLng")?.split('-')[0] ?? "fr";
    const objProd = { lang: myLang, upsell: "yes", reasons: "s" };
    Promise.resolve(this.props.getAllProducts(objProd))
      .then((response) => {
        let productsList = [];
        const productListx = response.NeoVentes.products;
        const reasons = response.NeoVentes.reasons;
        let productList =
          productListx.length > 0 &&
          productListx.map((item, i) => {
            if (item !== null) {
              productsList.push(item);
            }
            return null;
          }, this);
        this.setState({
          ["productsList"]: { products: productsList },
          ["reasons"]: reasons,
        });
        this.productsList.products = productsList;

        return response;
      })
      .catch((e) => {
        console.error(e);
      });

    const this2 = this;
    setTimeout(function () {
      Promise.resolve(this2.props.getNeoVentesPromos())
        .then((response) => {
          this2.setState({ ["listPromotionCodes"]: response.NeoCodePromoOne });
          let codepromoOptions = [];
          const itemx = this2.promoDefaultCode;
          codepromoOptions.push({
            value: itemx.id + "-" + itemx.percentage + "-" + itemx.name,
            label:
              itemx.description +
              " " +
              itemx.percentage +
              "% (" +
              itemx.name +
              ")",
          });

          let promosList =
            response.NeoCodePromoOne.length > 0 &&
            response.NeoCodePromoOne.map((item, i) => {
              if (item.active == 1) {
                codepromoOptions.push({
                  value: item.id + "-" + item.percentage + "-" + item.name,
                  label:
                    item.description +
                    " " +
                    item.percentage +
                    "% (" +
                    item.name +
                    ")",
                });
              }
              return null;
            }, this2);

          this2.setState({ ["codepromoOptions"]: codepromoOptions });
          return response;
        })
        .catch((e) => {
          console.error(e);
        });
    }, 100);

    const ticket_id = "908888"; //this.state.ticket_id;
    if (this.props.ticketList) {
      this.setState({ticketList: this.props.ticketList})
    }
  }


  componentDidUpdate(prevProps, prevState) {
    if (this.actionMode != "partialInvoice") {
      const { productCart, province, language, groups, productsList } =
        this.state;

      const { ticketList } = this.props;

      if (prevState.province !== province) {
        const newProductCart = productCart.map((i) => {
          const newProvince = provinceMap[province.toLowerCase()] ?? "";
          const oldProvince =
            provinceMap[prevState?.province?.toLowerCase()] ?? "";
          const newGroups = [...(i?.groups || []), newProvince].filter(
            (g) => g !== oldProvince
          );
          return { ...i, groups: newGroups };
        });
        this.setState({ productCart: newProductCart }, () =>
          this.handleGetAllProductAvailabilities()
        );
      }

      if (prevState.language !== language) {
        const newProductCart = productCart.map((i) => {
          const newLanguage = langMap[language.toLowerCase()] ?? "";
          const oldLanguage = langMap[prevState?.language?.toLowerCase()] ?? "";
          const newGroups = [...(i?.groups || []), newLanguage].filter(
            (g) => g !== oldLanguage
          );
          return { ...i, groups: newGroups };
        });
        this.setState({ productCart: newProductCart }, () =>
          this.handleGetAllProductAvailabilities()
        );
      }
      if (prevProps.ticketList !== ticketList) {
        this.setState({ ticketList });
      }
    }
    
}


  componentWillUnmount() {
    window.removeEventListener("message", this.bookingEventListener);
    window.removeEventListener("message", this.inputEventListener);
  }

  getSAASForms = async () => {
    const { data } = await axios.get(BACKEND1 + "/saas-form-list", {
      params: {
        auth_id: localStorage.getItem("user_id"),
      },
    });
    try {
      const forms = data?.data?.filter((i) => i?.form_params?.has_public_form);
      this.setState({ SAASForms: forms });
    } catch (e) {
      console.error(e);
    }
  };

  getGroups = async () => {
    const { data } = await axios.get(BACKEND1 + "/group-list", {
      params: {
        auth_id: localStorage.getItem("user_id"),
      },
    });
    try {
      const groups = data.data.map(i => {
        const children = !i?.parent ? data.data.filter(x => x?.parent === i?.id).map(i => ({...i, label: genLang(i?.name), value: i?.id})) : [];
        return {...i, label: genLang(i.name), value: i.id, subgroups: children}});
      const groupDomains = groups.filter(i => i.domain);
      this.setState({groups, groupDomains})
    } catch (e) {
      console.error(e);
    }
  }

  getProductAvailabilties  = async (products) => {
    try {
      const { data } = await axios.post(BACKEND1 + "/is-product-available",
      {
        auth_id: parseInt(localStorage.getItem("user_id")),
        infos_product: products
      }
    )
      return data.data
    } catch (e) {
      console.error(e);
    }
  }

  handleGetAllProductAvailabilities = async () => {
    this.setState({ productAvailableLoading: "ALL" });
    try {
        const { productCart } = this.state;
        const arr = productCart.map(i => ({
            product_id: i.id,
            groups: [...i.groups, "6553853f848f8c4c390d64a1"]
        }));
        const availabilitiesRes = await this.getProductAvailabilties(arr);
        const newCart = productCart.map(i => {
            const availabilities = availabilitiesRes[i?.id]
            return { ...i, availabilities }});
        this.setState({ productCart: newCart });
    } catch (e) {
        console.log(e)
    } finally {
        this.setState({ productAvailableLoading: false })
    }
}

handleGetSingleProductAvailabilities = async (index) => {
  this.setState({ productAvailableLoading: index });
  try {
      const { productCart } = this.state;
      const foundProduct = productCart.find(i => i.index === index);
      if (foundProduct) {
        const arr = [{product_id: foundProduct?.id, groups: foundProduct?.groups?.length ? [...foundProduct?.groups, "6553853f848f8c4c390d64a1"] : ["6553853f848f8c4c390d64a1"]}]
        const availabilitiesRes = await this.getProductAvailabilties(arr);
        const availabilities = availabilitiesRes[foundProduct?.id]
        const newProduct = {...foundProduct, availabilities }
        const newProductCart = productCart.map(i => i.index === index ? newProduct : i);
        this.setState({productCart: newProductCart})
      }
  } catch (e) {
      console.log(e)
  } finally {
      this.setState({ productAvailableLoading: false })
  }
}

  handleProductGroupChange = async (e, idx) => {
    const { productCart } = this.state;
    const newGroups = !e ? [] : e.map(i => i.value)
    const foundProduct = productCart.find(i => i.index === idx);
    const newProduct = {...foundProduct, groups: newGroups}
    const newCart = productCart.map(i => (i.index === idx ? newProduct : i));
    this.setState({productCart: newCart}, () => this.handleGetSingleProductAvailabilities(idx));
  }

  handleChangeAmerindian = (event) => {
    const cval = event.target.value;
    this.setState({ [event.target.name]: cval });
    const taxInfo = this.state.taxInfo;
    const taxISO = taxInfo["iso"]; //this or
    setTimeout(() => {
      this.sethandleChangeProvince(taxISO);
    }, 500);
  };
  sethandleChangeProvince = (taxISO) => {
    const { t } = this.props;
    const taxInfoX = getProvinceTaxInfo(taxISO);
    const taxInfo = JSON.parse(JSON.stringify(taxInfoX));
    const isAmerindian = this.state.isAmerindian;
    const no_amerindien = this.state.no_amerindien;
    if (isAmerindian && no_amerindien !== null && no_amerindien !== "") {
      taxInfo["tax1"] = 0;
      taxInfo["tax2"] = 0;
      taxInfo["tax1x"] = 0;
      taxInfo["tax2x"] = 0;
    }

    this.setState({ ["taxInfo"]: taxInfo });

    let productCart = this.state.productCart;

    let TAX1 = parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    let TAX2 = parseFloat(taxInfo["tax2x"]); //0.05; //tax2

    const TAX1_NAME = taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME = taxInfo["tax2_name"]; //"TPS";
    const TAX1l = taxInfo["tax1"]; //tax1 0.9975
    const TAX2l = taxInfo["tax2"];
    let TAX1lx = taxInfo["tax1"]; //tax1 0.9975
    let TAX2lx = taxInfo["tax2"];
    let TAX12 = 0;
    let TAX21 = 0;
    if (TAX2_NAME === "0") {
      TAX1lx = parseFloat(TAX1l) + parseFloat(TAX2l);
      TAX1 = TAX1 + TAX2;
      TAX12 = TAX1 + TAX2;
      TAX2 = 0;
    }
    if (TAX1_NAME === "0") {
      TAX2lx = parseFloat(TAX1l) + parseFloat(TAX2l);
      TAX2 = TAX2 + TAX1;
      TAX21 = TAX1 + TAX2;
      TAX1 = 0;
    }

    const TAX_TOTAL = TAX1 + TAX2;

    let subTotal = 0;
    let totTagTotOptions = 0;
    let totProd = 0;
    productCart.length > 0 &&
      productCart.map((item, i) => {
        const isDelete = item.isdelete;
        const myItemtxt = "" + item.id;
        const index = i;
        if (myItemtxt.indexOf("98989") < 0 && isDelete === undefined) {
          totProd++;
          subTotal = 0;
          totTagTotOptions = 0;
          const productPrice =
            item.productPrice2 === 0 ? item.productPrice : item.productPrice2;
          const tarifBase = parseFloat(productPrice) * 1; //(parseFloat(item.productPrice)*1);
          const tarifAmount = tarifBase + parseFloat(item.totOptions);
          subTotal += parseFloat(productPrice); //parseFloat(item.productPrice);
          totTagTotOptions += parseFloat(item.totOptions);
          const tax1 = (tarifAmount * 1 * TAX1).toFixed(2);
          const tax2 = (tarifAmount * 1 * TAX2).toFixed(2);
          const tax_total = (tarifAmount * TAX_TOTAL).toFixed(2);
          const totalForItem = (tarifAmount * 1 + tax_total * 1).toFixed(2);
          const options = item.options;
          const cticket = item.cticket;
          const pages = item.pages;
          const perPage = item.perPage;
          let optionsProd = []; //productCart[index].options;
          let tarif = productPrice * 1 + totTagTotOptions; // (''+item.tarif).replace(/\W\$/g, '');
          const productPriceTaxOnly = (tarif * 1 * TAX_TOTAL).toFixed(2);
          const productPriceTotal = (tarif * 1 * TAX_TOTAL + tarif * 1).toFixed(
            2
          );
          //productCart[index].options=optionsProd;
          const orderTVQ = (tarif * 1 * TAX1).toFixed(2);
          const orderTPS = (tarif * 1 * TAX2).toFixed(2);
          const orderServices = totTagTotOptions;
          const orderSubtotals = (tarif * 1).toFixed(2);
          productCart[index].productPriceTax = productPriceTotal;
          productCart[index].productPriceTaxOnly = productPriceTaxOnly;
        }
      }, this);
    this.setState({ ["productCart"]: productCart });
    setTimeout(() => {
      this.calculateGrandTotal(null, this.state.urgentSale);
    }, 1000);
    this.showToast(t("zqwNVFrmEl5_x") + " ", "warning");
  };

  callbackFunction = (childProp) => {
    const country =
      childProp.country === "États-Unis" ? "Etats-Unis" : childProp.country;
    this.setState({ ["country"]: country }); //États-Unis
    let this2 = this;
    setTimeout(function () {
      //(function(){   = (childProp) => {
      this2.setState({ ["address"]: childProp.street }); //childProp.formatedAddress childProp.street
      this2.setState({ ["unitsuite"]: childProp.suite });
      this2.setState({ ["city"]: childProp.city });
      this2.setState({ ["province"]: childProp.state2 });
      this2.setState({ ["province2"]: childProp.state });
      //this.setState({ ["country"]: childProp.country });
      this2.setState({ ["cp"]: childProp.zipCode });
      this2.setState({ ["listZipCodeAddresses"]: childProp.whenzipCode });
      if (childProp.whenzipCode.length > 0) {
        let listAddressOnCode =
          childProp.whenzipCode.length > 0 &&
          childProp.whenzipCode.map((item, i) => {
            const addcomp = item.obj;
            const addform = item.address;
            const addObj = item.addFormatted;

            return (
              <tr key={i}>
                <td>{addform}</td>
                <td>
                  {" "}
                  <a href="#" data-address={i} onClick={this2.addressSetter}>
                    <FolderIcon />
                  </a>{" "}
                </td>
              </tr>
            );
          }, this);
        this2.setState({
          ["openPossibleZipCode"]: true,
          listAddressOnCode: listAddressOnCode,
        });
      }
      this2.clientFullInfoValidate();
    }, 10);
  };

  addressSetter = (event) => {
    const { t } = this.props;
    const index = event.currentTarget.dataset.address;
    const addressRaw = event.target.dataset.address;
    const streetNbTest = /^\d+-\d+\s?/;
    let childProp = {};
    const listZipCodeAddresses = this.state.listZipCodeAddresses;
    childProp = listZipCodeAddresses[index].addFormatted;
    const country =
      childProp.country === "États-Unis"
        ? t("zqwNVFrmEl6_x") + ""
        : childProp.country;
    this.setState({ ["country"]: country }); //États-Unis

    let this2 = this;
    setTimeout(function () {
      //address city province cp
      this2.setState({ ["address"]: childProp.address }); //childProp.street  childProp.formatedAddress }
      this2.setState({ ["unitsuite"]: childProp.suite });
      this2.setState({ ["city"]: childProp.city });
      this2.setState({ ["province"]: childProp.state2 });
      this2.setState({ ["province2"]: childProp.state });
      this2.setState({ ["cp"]: childProp.zipCode });
      this2.setState({ ["openPossibleZipCode"]: false });
      this2.clientFullInfoValidate();
    }, 10);
  };

  openZipCodeAddressSetter = (event) => {
    this.setState({ ["openPossibleZipCode"]: true });
  };

  clientCustomerSetter = (event) => {
    const { t } = this.props;
    const index = event.currentTarget.dataset.client;
    let newValue = {};
    const listZipCodeClient = this.state.listClientOnCode;
    newValue = listZipCodeClient[index];
    this.setState({ ["clientInfo"]: newValue });
    const clientInfo = {
      id_clients: newValue.id,
      idClient: newValue.id,
      firstname: newValue.firstname,
      lastname: newValue.lastname,
      cell: newValue.cell,
      terms: newValue.terms,
      active: newValue.active,
      email: newValue.email,
      id_users: parseInt(newValue.id_users),
      language: newValue.language,
      push: newValue.push,
      id_zendesk: newValue.id_zendesk,
      sms: newValue.sms,
      occupation: newValue.occupation,
    };
    this.setState(clientInfo); //xaza  this.setState({ clientInfo });
    this.setState({
      ["existedClientRO"]: true,
      ["emailDoesNotExist"]: 1,
      ["openPossibleListOfClient"]: false,
    });
    this.clientFullInfoValidate();
    const clientId = newValue.id;
    setTimeout(() => {
      this.getClientFullAddress(clientId);
    }, 0);
  };

  showToast = (arg, toastType) => {
    this.setState({
      // update a property
      toastrMessage: arg,
      toastrType: toastType,
    });
    setTimeout(() => {
      this.setState({
        toastrMessage: "",
        toastrType: "success",
      });
    }, 5500);
  };
  getClientSearchSuggestion(search) {
    const valueClientSearch = this.state.valueClientSearch;
    const inputValueClientSearch = this.state.inputValueClientSearch;
    if (valueClientSearch === inputValueClientSearch) {
      return false;
    }
    const serachObj = { search: search, searchEmail: "" };
    Promise.resolve(this.props.getClientSearch(serachObj))
      .then((response) => {
        this.setState({ ["listOfSuggestion"]: response.NeoClientsOne });
        return response;
      })
      .catch((e) => {
        console.error(e);
      });
  }
  getClientFullAddress(clientId) {
    const valueClientSearch = this.state.valueClientSearch;
    const inputValueClientSearch = this.state.inputValueClientSearch;
    const this00 = this;
    this.setState({ ["isLoaderOpen"]: true });
    const clientAddress0 = {
      idAddress: 0,
      address: "",
      city: "",
      province: "QC",
      cp: "",
      country: "Canada",
      company: "",
    };

    this.setState(clientAddress0); //resetting address before change

    Promise.resolve(this.props.getClientAddress(clientId))
      .then((response) => {
        const address = response.NeoAddressOne[0];
        if (
          response.NeoAddressOne.length > 0 &&
          address !== null &&
          address !== undefined
        ) {
          this.setState({ ["clientAddress"]: address });

          const clientAddress = {
            idAddress: address.id,
            address: address.address,
            city: address.city,
            province: address.province,
            cp: address.cp,
            country: address.country,
            company: address.company,
          };

          this.setState(clientAddress); //this.setState({ clientAddress });

          this.clientFullInfoValidate();
          this.sethandleChangeProvince(address.province);
        } else {
          this.clientFullInfoValidate();
        }
        this.setState({ ["isLoaderOpen"]: false });
        return response;
      })
      .catch(function (error) {
        this00.setState({ ["isLoaderOpen"]: false });
        alert(error);
      });
  }
  handleSaveSalesReasons = (event) => {
    event.preventDefault();
    const { t } = this.props;
    const reasonId = this.state.reason;
    const invoiceId = this.state.id_invoice;

    let errorFound = 0;

    if (parseInt(reasonId) > 0 && parseInt(invoiceId) > 0) {
    } else {
      window.alert(`${t("pleaseSelsr_x")}`);
      return false;
    }
    const this00 = this;
    let rawError = {};
    const neoVentesBlocks = { id_invoice: invoiceId, saleReason: reasonId };

    this.setState({ ["isLoaderOpen"]: true, ["errorTransacMessage"]: "" });
    Promise.resolve(this.props.addOrUpdateNeoVentes(neoVentesBlocks, 1))
      .then((response) => {
        const resp = response.NeoVentes;
        rawError = response;
        this.showToast(t("zqwsalreason3_x"), "success");
        this.setState({
          ["isLoaderOpen"]: false,
          ["salesreasontxt"]: t("zqwsalreason3_x"),
        });
        return response;
      })
      .catch(function (error) {
        const errObj = error?.response?.data ?? {};
        let errMessage =
          errObj?.data !== ""
            ? "Erreur " + " \n" + errObj?.data
            : "Erreur " + " \n" + errObj?.statusText;
        if (errObj?.status === undefined) {
          errMessage += "\n " + t("zqwNVFrmEl14_x");
        }
        this00.setState({
          ["isLoaderOpen"]: false,
          ["errorTransacMessage"]: errMessage,
        });
        console.log(error);
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { t } = this.props;
    const id = this.state.id;
    const name = this.state.name;
    const description = this.state.description;
    const amount = this.state.amount;
    const NeoVentes = {
      id: id,
      name: name,
      description: description,
      amount: amount,
    };

    let errorFound = 0;
    for (let [fieldName, obj] of Object.entries(FIELD_VALIDATION)) {
      if (FIELD_VALIDATION[fieldName] !== undefined && obj.check === true) {
        this.setState({ ["e_" + fieldName]: false });
        let fieldValue = this.state[fieldName]; // ? this.state[fieldName] : this.props.NeoVentes[fieldName];
        if (!this.validation.validate(obj.chkType, fieldValue)) {
          errorFound++;
          this.setState({ ["e_" + fieldName]: true });
        }
      }
    }
    if (errorFound > 0) {
      alert(`${t("zqwerrorFoundonform_x")}`);
      return false;
    }
    if (this.actionMode === "new") {
      this.props.addOrUpdateNeoVentes(NeoVentes, 1);
      //this.props.addNeoVentes(NeoVentes);
    } else {
      this.props.addOrUpdateNeoVentes(NeoVentes, 2);
      //this.props.updateNeoVentes(NeoVentes);
    }
  };

  handleSubmitClientInfo = (event) => {
    event.preventDefault();
    const { t } = this.props;
    const id = this.state.id;
    const firstname = this.state.firstname;
    const lastname = this.state.lastname;
    const cell = this.state.cell;
    const terms = this.state.terms;
    const active = this.state.active;
    const created = this.state.created;
    const email = this.state.email;
    const id_users = this.state.id_users;
    const language = this.state.language;
    const push = this.state.push;
    const id_zendesk = this.state.id_zendesk;
    const sms = this.state.sms;
    const stripe_id = this.state.stripe_id;
    const NeoClients = {
      id: id,
      firstname: firstname,
      lastname: lastname,
      cell: cell,
      terms: terms,
      active: active,
      created: created,
      email: email,
      id_users: id_users,
      language: language,
      push: push,
      id_zendesk: id_zendesk,
      sms: sms,
      stripe_id: stripe_id,
    };

    let errorFound = 0;
    for (let [fieldName, obj] of Object.entries(FIELD_VALIDATION)) {
      if (FIELD_VALIDATION[fieldName] !== undefined && obj.check === true) {
        this.setState({ ["e_" + fieldName]: false });
        let fieldValue = this.state[fieldName]; // ? this.state[fieldName] : this.props.NeoClients[fieldName];
        if (!this.validation.validate(obj.chkType, fieldValue)) {
          errorFound++;
          this.setState({ ["e_" + fieldName]: true });
        }
      }
    }
    if (errorFound > 0) {
      alert(`${t("zqwerrorFoundonform_x")}`);
      return false;
    }
    if (this.actionMode === "new") {
      this.props.addOrUpdateNeoClients(NeoClients, 1);
    } else {
      this.props.addOrUpdateNeoClients(NeoClients, 2);
    }
  };

  handleGetCustomerInfoPayment2 = (event) => {
    //event.preventDefault();
    const { t } = this.props;
    const idClient = this.state.idClient;
    const firstname = this.state.firstname;
    const lastname = this.state.lastname;
    const cell = this.state.cell;
    const terms = 0; //this.state.terms;
    const active = 1; //this.state.active;
    //const created = this.state.created;
    const email = this.state.email;
    const id_users = this.state.id_users;
    const language = this.state.language;
    const push = 0; //this.state.push;
    const id_zendesk = this.state.id_zendesk;
    const sms = this.state.sms;
    const stripe_id = this.state.stripe_id;
    const occupation = this.state.occupation;
    const neoClients = {
      idClient: idClient,
      firstname: firstname,
      lastname: lastname,
      cell: cell,
      terms: terms,
      active: active,
      email: email,
      id_users: id_users,
      language: language,
      push: push,
      id_zendesk: id_zendesk,
      sms: sms,
      stripe_id: stripe_id,
      occupation: occupation,
    };

    const idAddress = this.state.idAddress;
    const id_clients = this.state.id_clients;
    const address0 = this.state.address;
    const unitsuite = this.state.unitsuite;
    const city = this.state.city;
    const province0 = this.state.province;
    const province2 = this.state.province2;
    const cp = this.state.cp;
    const country = this.state.country;

    //const created = this.state.created;
    const active2 = 1; //this.state.active;
    const tel = ""; //this.state.tel; //this.state.cell;
    const ext = ""; //this.state.ext;
    const company = this.state.company;
    const nameNx2 = ""; //this.state.name;
    //const type = this.state.type;
    let address = address0;
    if (unitsuite !== "") {
      address = unitsuite + "-" + address;
    }
    let province = "";
    if (province0.length > 2) {
      province = province0.substring(0, 2);
      address += " (" + province0 + ")";
    } else {
      province = province0;
    }
    const neoAddress = {
      idAddress: idAddress,
      id_clients: id_clients,
      address: address,
      city: city,
      province: province,
      cp: cp,
      country: country,
      active: active2,
      tel: tel,
      ext: ext,
      company: company,
      name: nameNx2,
    };

    const productId = this.state.productCart.length; //const productId=this.state.productId;
    if (productId < 1) {
      //errorFound++;
      alert(`${t("zqwNVFrmEl7_x")} `);
      return false;
    }

    const productPriceTotal = this.state.grandTotal;
    if (productPriceTotal == 0) {
      this.showToast(t("zqwNVFrmEl8_x"), "error");
      return false;
    }
    const {groups} = this.state;
    const id = this.state.id;
    const name = this.state.name;
    const description = this.state.description;
    const amount = this.state.amount;
    const neoVentes = [];
    const taxInfo = this.state.taxInfo;
    const {urgentSale, salePrecision} = this.state;
    const TAX1 = parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    const TAX2 = parseFloat(taxInfo["tax2x"]); //0.05; //tax2
    const TAX_TOTAL = TAX1 + TAX2; //0.14975;
    const TAX1_NAME = taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME = taxInfo["tax2_name"]; //"TPS";
    const TAX1l = taxInfo["tax1"]; //tax1 0.9975
    const TAX2l = taxInfo["tax2"];
    const productCart = this.state.productCart;
    let subTotal = 0;
    let totTagTotOptions = 0;
    let totProd = 0;
    productCart.length > 0 &&
      productCart.map((item, i) => {
        const isDelete = item.isdelete;
        const myItemtxt = "" + item.id;
        if (myItemtxt.indexOf("98989") < 0 && isDelete === undefined) {
          totProd++;
          const productPrice =
            item.productPrice2 === 0 ? item.productPrice : item.productPrice2;
          const tarifBase = parseFloat(productPrice) * 1; //(parseFloat(item.productPrice)*1);
          const tarifAmount = tarifBase + parseFloat(item.totOptions);
          subTotal += parseFloat(productPrice); //parseFloat(item.productPrice);
          totTagTotOptions += parseFloat(item.totOptions);
          const tax1 = (tarifAmount * 1 * TAX1).toFixed(2);
          const tax2 = (tarifAmount * 1 * TAX2).toFixed(2);
          const tax_total = (tarifAmount * TAX_TOTAL).toFixed(2);
          const totalForItem = (tarifAmount * 1 + tax_total * 1).toFixed(2);
          const options = item.options;
          const cticket = item.cticket;
          const pages = item.pages;
          const perPage = item.perPage;
          const groupsForObj = groups?.filter(g => (item?.groups?.includes(g.id))).map(({name, id}) => ({name, id}));
          const subNeoVentes = {
            groups: groupsForObj,
            isSub: true,
            id: item.id,
            tax1: tax1,
            tax2: tax2,
            sub_total: tarifAmount,
            tax_total: tax_total,
            total: totalForItem,
            amount: tarifAmount,
            options: options,
            tarifBase: tarifBase,
            createTicket: cticket,
            pages: pages,
            perPage: perPage,
            productPrice: item.productPrice,
            productPrice2: item.productPrice2,
            discountFree: item.discountFree,
            discountFree2: item.discountFree2,
          };
          neoVentes.push(subNeoVentes);
        }
      }, this);

    if (totProd === 0) {
      this.showToast(t("zqwNVFrmEl9_x"), "error");
      return false;
    }

    const tarifAmount = subTotal + totTagTotOptions;
    const tax1 = (tarifAmount * 1 * TAX1).toFixed(2);
    const tax2 = (tarifAmount * 1 * TAX2).toFixed(2);
    const tax_total = (tarifAmount * TAX_TOTAL).toFixed(2);
    const totalForItem = (tarifAmount * 1 + tax_total * 1).toFixed(2); //const totalForItem= ((tarifAmount+tax_total)).toFixed(2);
    const mainNeoVentes = {
      isSub: false,
      tax1: tax1,
      tax2: tax2,
      sub_total: tarifAmount,
      tax_total: tax_total,
      total: totalForItem,
      urgentSale,
      ...(salePrecision && {salePrecision})
    };
    neoVentes.push(mainNeoVentes);

    let neoPromocode = {
      id: 0,
      percentage: 0,
      name: name,
      amount: 0,
      tax1: 0,
      tax2: 0,
    };
    const cuponcodeId = this.state.cuponcodeId;
    const cuponcodeName = this.state.cuponcodeName;
    const promoAmount = this.state.promoAmount;
    const promoPercentage = this.state.promoPercentage;
    const promoTax1 = this.state.promoTax1;
    const promoTax2 = this.state.promoTax2;
    if (cuponcodeId > 0) {
      neoPromocode = {
        id: cuponcodeId,
        percentage: promoPercentage,
        name: cuponcodeName,
        amount: promoAmount,
        tax1: promoTax1,
        tax2: promoTax2,
      };
    } else {
      this.showToast(t("pleaseSelsr2_x") + " " + t("zqwNVFrmEl52b_x"), "error");
      return false;
    }
    const stripeToken = this.state.stripeToken;
    let pMethod = 1;
    const dossier = this.state.dossier;
    pMethod = this.state.pMethod;

    const downPayment = this.state.nowPayment;
    const paymentRound = this.state.paymentRound;
    const isRecurrent = this.state.isRecurrentPayment;
    const qtyPayment = this.state.qtyPayment;
    const laterpayment = this.state.laterpayment;
    const fromDatePayment = this.state.fromDatePayment;
    const paymentWay = this.state.paymentWay; //full, partial, recurrent
    const neoPayments = {
      id: id,
      downPayment: downPayment,
      paymentRound: paymentRound,
      paymentWay: paymentWay,
      isRecurrent: isRecurrent,
      qtyPayment: qtyPayment,
      recPaymentDay: laterpayment,
      recPaymentDate: fromDatePayment,
    };

    const id_invoice = this.state.id_invoice;
    let errorFound = 0;
    for (let [fieldName, obj] of Object.entries(FIELD_VALIDATION)) {
      if (FIELD_VALIDATION[fieldName] !== undefined && obj.check === true) {
        this.setState({ ["e_" + fieldName]: false });
        let fieldValue = this.state[fieldName]; // ? this.state[fieldName] : this.props.NeoClients[fieldName];
        if (!this.validation.validate(obj.chkType, fieldValue)) {
          errorFound++;
          this.setState({ ["e_" + fieldName]: true });
        }
      }
    }
    if (errorFound > 0) {
      alert(`${t("zqwNVFrmEl10_x")}`);
      return false;
    }
    const isupsale = this.state.isupsale;
    const upsaleTicket = this.state.upsaleTicket;
    const isAmerindian = this.state.isAmerindian;
    const no_amerindien = this.state.no_amerindien;
    if (isupsale === true && (upsaleTicket === null || upsaleTicket === "")) {
      this.showToast(t("zqwNVFrmEl11_x"), "error");
      return false;
    }
    if (
      isAmerindian === true &&
      (no_amerindien === null || no_amerindien === "")
    ) {
      this.showToast(t("zqwNVFrmEl12_x"), "error");
      return false;
    }
    const neoVentesBlocks = {
      client: neoClients,
      address: neoAddress,
      sales: neoVentes,
      promocode: neoPromocode,
      payment: neoPayments,
      dossier: dossier,
      pMethod: pMethod,
      stripeToken: stripeToken,
      id_invoice: id_invoice,
      isupsale: isupsale,
      upsaleTicket: upsaleTicket,
      isAmerindian: isAmerindian,
      no_amerindien: no_amerindien,
    };
    const this00 = this;
    let rawError = {};
    const b2bIDCustomer = this.state.b2bIDCustomer;
    const b2bIDCustomerContract = this.state.b2bIDCustomerContract;
    if (
      b2bIDCustomer !== undefined &&
      b2bIDCustomer !== null &&
      b2bIDCustomer !== ""
    ) {
      neoVentesBlocks.b2bIDCustomer = b2bIDCustomer;
      if (
        b2bIDCustomerContract !== undefined &&
        b2bIDCustomerContract !== null &&
        b2bIDCustomerContract !== ""
      ) {
        neoVentesBlocks.b2bIDCustomerContract = b2bIDCustomerContract;
      }
    }
    if (this.backLeadReId > 0) {
      neoVentesBlocks.leadId = this.backLeadReId;
    }

    neoVentesBlocks.tentative = this.state.tentative;

    if (this.state.creditClientID !== undefined) {
      neoVentesBlocks.creditClientID = this.state.creditClientID;
    }

    this.setState({ ["isLoaderOpen"]: true, ["errorTransacMessage"]: "" });
    const totalPaymentReceived = this.state.totalPaymentReceived;
    let paymentsReceived = this.state.paymentsReceived;
    Promise.resolve(this.props.addOrUpdateNeoVentes(neoVentesBlocks, 1))
      .then((response) => {
        const resp = response.NeoVentes.transaction;
        rawError = response;
        if (resp.paymentAmount > 0) {
          const txtv = totalPaymentReceived * 1 + downPayment * 1;
          paymentsReceived.push(downPayment);
          const pround = paymentRound + 1;
          this.setState({
            ["idClient"]: resp.id_clients,
            ["id_clients"]: resp.id_clients,
            ["id_users"]: resp.id_users,
            ["idAddress"]: resp.id_address,
            ["id_invoice"]: resp.id_invoice,
            ["totalPaymentReceived"]: txtv,
            ["paymentsReceived"]: paymentsReceived,
            ["paymentRound"]: pround,
            ["paymentWayRO"]: true,
          });
          this.calculateGrandTotal(null, this.state.urgentSale);
          if (
            pMethod === 1 ||
            pMethod === "1" ||
            pMethod === 7 ||
            pMethod === "7"
          ) {
            this.showToast(t("zqwNVFrmEl13_x"), "success");
          } else {
            this.showToast(t("zqwNVFrmEl13b_x"), "success");
          }
        }
        this.setState({ ["isLoaderOpen"]: false });
        return response;
      })
      .catch(function (error) {
        const errObj = error?.response?.data ?? {};
        let errMessage =
          errObj?.data !== ""
            ? "Erreur " + " \n" + errObj?.data
            : "Erreur " + " \n" + errObj?.statusText;
        if (errObj?.status === undefined) {
          errMessage += "\n " + t("zqwNVFrmEl14_x");
        }
        if (
          errObj?.status === 500 &&
          errObj?.data?.error?.description !== undefined
        ) {
          errMessage = "\n " + errObj?.data?.error?.description;
        } else if (errObj?.status < 500 && errObj?.data !== undefined) {
        }
        this00.setState({
          ["isLoaderOpen"]: false,
          ["errorTransacMessage"]: errMessage,
        });
        console.log(error);
      });
  };

  handleMakePartialPayment = (event) => {
    const { t } = this.props;
    const idClient = this.state.idClient;
    const firstname = this.state.firstname;
    const lastname = this.state.lastname;
    const cell = this.state.cell;
    const terms = 0; //this.state.terms;
    const active = 1; //this.state.active;
    const email = this.state.email;
    const id_users = this.state.id_users;
    const language = this.state.language;
    const push = 0; //this.state.push;
    const id_zendesk = this.state.id_zendesk;
    const sms = this.state.sms;
    const stripe_id = this.state.stripe_id;
    const occupation = this.state.occupation;
    const neoClients = {
      idClient: idClient,
      firstname: firstname,
      lastname: lastname,
      cell: cell,
      terms: terms,
      active: active,
      email: email,
      id_users: id_users,
      language: language,
      push: push,
      id_zendesk: id_zendesk,
      sms: sms,
      stripe_id: stripe_id,
      occupation: occupation,
    };

    const idAddress = this.state.idAddress;
    const id_clients = this.state.id_clients;
    const address0 = this.state.address;
    const unitsuite = this.state.unitsuite;
    const city = this.state.city;
    const province = this.state.province;
    const cp = this.state.cp;
    const country = this.state.country;

    //const created = this.state.created;
    const active2 = 1; //this.state.active;
    const tel = ""; //this.state.tel; //this.state.cell;
    const ext = ""; //this.state.ext;
    const company = this.state.company;
    const nameNx2 = ""; //this.state.name;
    //const type = this.state.type;
    let address = address0;
    if (unitsuite !== "") {
      address = unitsuite + "-" + address;
    }
    const neoAddress = {
      idAddress: idAddress,
      id_clients: id_clients,
      address: address,
      city: city,
      province: province,
      cp: cp,
      country: country,
      active: active2,
      tel: tel,
      ext: ext,
      company: company,
      name: nameNx2,
    };

    const productId = this.state.productCart.length; //const productId=this.state.productId;
    const productPriceTotal = this.state.grandTotal;

    const id = this.state.id;
    const name = this.state.name;
    const description = this.state.description;
    const amount = this.state.amount;
    const neoVentes = [];
    const downPayment = this.state.nowPayment;
    const productCart = this.state.productCart;
    let subTotal = 0;
    let totTagTotOptions = 0;
    const subNeoVentes = {
      isSub: true,
      id: 0,
      tax1: 0,
      tax2: 0,
      sub_total: 0,
      tax_total: 0,
      total: downPayment,
      amount: downPayment,
      options: [],
      tarifBase: downPayment,
      createTicket: true,
      pages: 0,
      perPage: 0,
      productPrice: 0,
      productPrice2: downPayment,
    };
    neoVentes.push(subNeoVentes);

    const tarifAmount = downPayment; //subTotal+totTagTotOptions;
    const tax1 = 0;
    const tax2 = 0;
    const tax_total = 0;
    const totalForItem = (tarifAmount * 1 + tax_total * 1).toFixed(2); //const totalForItem= ((tarifAmount+tax_total)).toFixed(2);
    const mainNeoVentes = {
      isSub: false,
      tax1: tax1,
      tax2: tax2,
      sub_total: tarifAmount,
      tax_total: tax_total,
      total: totalForItem,
    };
    neoVentes.push(mainNeoVentes);

    let neoPromocode = {
      id: 0,
      percentage: 0,
      name: name,
      amount: 0,
      tax1: 0,
      tax2: 0,
    };
    const cuponcodeId = this.state.cuponcodeId;
    const cuponcodeName = this.state.cuponcodeName;
    const promoAmount = this.state.promoAmount;
    const promoPercentage = this.state.promoPercentage;
    const promoTax1 = this.state.promoTax1;
    const promoTax2 = this.state.promoTax2;
    const stripeToken = this.state.stripeToken;
    let pMethod = 1;
    const dossier = this.state.dossier;
    pMethod = this.state.pMethod;
    const paymentRound = this.state.paymentRound;
    const isRecurrent = this.state.isRecurrentPayment;
    const qtyPayment = this.state.qtyPayment;
    const laterpayment = this.state.laterpayment;
    const fromDatePayment = this.state.fromDatePayment;
    const paymentWay = this.state.paymentWay; //full, partial, recurrent
    const neoPayments = {
      id: id,
      downPayment: downPayment,
      paymentRound: paymentRound,
      paymentWay: paymentWay,
      isRecurrent: isRecurrent,
      qtyPayment: qtyPayment,
      recPaymentDay: laterpayment,
      recPaymentDate: fromDatePayment,
    };

    const id_invoice = this.state.id_invoice;

    let errorFound = 0;
    const neoVentesBlocks = {
      client: neoClients,
      address: neoAddress,
      sales: neoVentes,
      promocode: neoPromocode,
      payment: neoPayments,
      dossier: dossier,
      pMethod: pMethod,
      stripeToken: stripeToken,
      id_invoice: id_invoice,
    };

    if (this.actionMode === "partialInvoice") {
      neoVentesBlocks["partialInvoice"] = 100;
    }
    let rawError = {};
    const this00 = this;

    this.setState({ ["isLoaderOpen"]: true });
    const totalPaymentReceived = this.state.totalPaymentReceived;
    let paymentsReceived = this.state.paymentsReceived;
    Promise.resolve(this.props.addOrUpdateNeoVentes(neoVentesBlocks, 1))
      .then((response) => {
        const resp = response.NeoVentes.transaction;
        rawError = response;
        if (resp.paymentAmount > 0) {
          const txtv = totalPaymentReceived * 1 + downPayment * 1;
          paymentsReceived.push(downPayment);
          const pround = paymentRound + 1;
          this.setState({
            ["idClient"]: resp.id_clients,
            ["id_clients"]: resp.id_clients,
            ["id_users"]: resp.id_users,
            ["idAddress"]: resp.id_address,
            ["id_invoice"]: resp.id_invoice,
            ["totalPaymentReceived"]: txtv,
            ["paymentsReceived"]: paymentsReceived,
            ["paymentRound"]: pround,
            ["paymentWayRO"]: true,
          });
          if (
            pMethod === 1 ||
            pMethod === "1" ||
            pMethod === 7 ||
            pMethod === "7"
          ) {
            this.showToast(t("zqwNVFrmEl13_x"), "success");
          } else {
            this.showToast(t("zqwNVFrmEl13b_x"), "success");
          }
        }
        this.setState({ ["isLoaderOpen"]: false });
        return response;
      })
      .catch(function (error) {
        this00.setState({ ["isLoaderOpen"]: false });
        const errObj = error?.response;
        let errMessage =
          errObj?.data !== ""
            ? "Erreur " + " \n" + errObj?.data
            : "Erreur " + " \n" + errObj?.statusText;
        if (errObj?.status === undefined) {
          errMessage += "\n " + t("zqwNVFrmEl14_x");
        }
        if (
          errObj?.status === 500 &&
          errObj?.data?.error?.description !== undefined
        ) {
          errMessage = "\n " + errObj?.data?.error?.description;
        } else if (errObj?.status < 500 && errObj?.data !== undefined) {
        }
        this00.setState({
          ["isLoaderOpen"]: false,
          ["errorTransacMessage"]: errMessage,
        });
        console.log(error);
      });
  };
  payWithtChargeDesk = (event) => {
    const { t } = this.props;
    event.preventDefault();
    this.setState({ ["pMethod"]: 4 });
    const productPriceTotal = this.state.grandTotal; //orderTotal
    const nowPayment = this.state.nowPayment;
    const paymentWay = this.state.paymentWay;
    if (paymentWay !== "full") {
      this.paymentWayRef.current.focus();
      this.showToast(t("zqwNVFrmEl15_x"), "error");
      return false;
    }

    const paytryNow = Date.now();
    const timesbetween = paytryNow * 1 - this.paymentBtnTryTimes3 * 1;
    if (timesbetween < 60000) {
      //this.stripeTryTimes=paytryNow;
      const timerest = 60 - timesbetween / 1000;
      this.showToast(t("zqwNVFrmEl16_x", { timerest: timerest }), "error");
      return false;
    } else {
      this.paymentBtnTryTimes3 = paytryNow;
    }
    setTimeout(() => {
      if (this.actionMode === "partialInvoice") {
        this.handleMakePartialPayment(event);
      } else {
        this.handleGetCustomerInfoPayment2(event);
      }
    }, 100);
  };

  payWithtInterac = (event) => {
    const { t } = this.props;
    event.preventDefault();
    this.setState({ ["pMethod"]: 3 });
    const productPriceTotal = this.state.grandTotal; //orderTotal
    const nowPayment = this.state.nowPayment;
    const paymentWay = this.state.paymentWay;
    if (paymentWay !== "full") {
      this.paymentWayRef.current.focus();
      this.showToast(t("zqwNVFrmEl17_x"), "error");
      return false;
    }
    const paytryNow = Date.now();
    const timesbetween = paytryNow * 1 - this.paymentBtnTryTimes2 * 1;
    if (timesbetween < 60000) {
      const timerest = 60 - timesbetween / 1000;
      this.showToast(t("zqwNVFrmEl16_x", { timerest: timerest }), "error");
      return false;
    } else {
      this.paymentBtnTryTimes2 = paytryNow;
    }
    setTimeout(() => {
      if (this.actionMode === "partialInvoice") {
        this.handleMakePartialPayment(event);
      } else {
        this.handleGetCustomerInfoPayment2(event);
      }
    }, 100);
  };

  payWithFlexiti = (event) => {
    const { t } = this.props;
    event.preventDefault();
    this.setState({ ["pMethod"]: 6 });
    const productPriceTotal = this.state.grandTotal; //orderTotal
    const nowPayment = this.state.nowPayment;
    const paymentWay = this.state.paymentWay;
    if (paymentWay !== "full") {
      this.paymentWayRef.current.focus();
      this.showToast(t("zqwNVFrmEl17_x"), "error");
      return false;
    }

    const paytryNow = Date.now();
    const timesbetween = paytryNow * 1 - this.paymentBtnTryTimes2 * 1;
    if (timesbetween < 60000) {
      const timerest = 60 - timesbetween / 1000;
      this.showToast(t("zqwNVFrmEl16_x", { timerest: timerest }), "error");
      return false;
    } else {
      this.paymentBtnTryTimes2 = paytryNow;
    }
    setTimeout(() => {
      this.handleGetCustomerInfoPayment2(event);
    }, 100);
  };

  //payWithNeoCredit
  payWithNeoCredit = (event) => {
    const { t } = this.props;
    event.preventDefault();
    const productPriceTotal = this.state.grandTotal; //orderTotal
    const nowPayment = parseFloat("" + this.state.nowPayment);
    const paymentWay = this.state.paymentWay;
    let credit = this.state.clientAddress.creditClient;
    let amount = 0;
    if (
      credit !== undefined &&
      credit !== null &&
      credit.amount !== undefined
    ) {
      //credit=credit2;
      amount = parseFloat("" + credit.amount);
    } else {
      this.showToast("NoCredit " + t("zqwNVFrmEl18_x"), "error");
      return false;
    }
    const paytryNow = Date.now();
    const timesbetween = paytryNow * 1 - this.paymentBtnTryTimes2 * 1;
    if (timesbetween < 60000) {
      const timerest = 60 - timesbetween / 1000;
      this.showToast(t("zqwNVFrmEl16_x", { timerest: timerest }), "error");
      return false;
    } else {
      this.paymentBtnTryTimes2 = paytryNow;
    }

    if (nowPayment > amount) {
      this.showToast("Montant trop élevé/ amount too high ", "error");
      return false;
    }
    this.setState({ ["pMethod"]: 7, ["creditClientID"]: credit.id });
    setTimeout(() => {
      this.handleGetCustomerInfoPayment2(event);
    }, 100);
  };

  payWithCardOnFile = (event) => {
    const { t } = this.props;
    event.preventDefault();
    if (this.state.stripe_id === null || this.state.stripe_id === "") {
      this.showToast(t("zqwNVFrmEl18_x"), "error");
      return false;
    }
    const productPriceTotal = this.state.grandTotal; //orderTotal
    const nowPayment = this.state.nowPayment;
    const paymentWay = this.state.paymentWay;
    const paytryNow = Date.now();
    const timesbetween = paytryNow * 1 - this.paymentBtnTryTimes2 * 1;
    if (timesbetween < 60000) {
      //this.stripeTryTimes=paytryNow;
      const timerest = 60 - timesbetween / 1000;
      this.showToast(t("zqwNVFrmEl16_x", { timerest: timerest }), "error");
      return false;
    } else {
      this.paymentBtnTryTimes2 = paytryNow;
    }
    this.setState({ ["pMethod"]: 1, ["stripeToken"]: "CARD_ON_FILE" });
    setTimeout(() => {
      if (this.actionMode === "partialInvoice") {
        this.handleMakePartialPayment(event);
      } else {
        this.handleGetCustomerInfoPayment2(event);
      }
    }, 100);
  };

  handleProductNextStep = (event) => {
    event.preventDefault();
    const { t } = this.props;
    const globalStepWorkflow = this.state.globalStepWorkflow;

    const productId = this.state.productCart.length; //const productId=this.state.productId;
    if (productId < 1) {
      alert(`${t("zqwNVFrmEl7_x")}`);
      return false;
    }

    const productCart = this.state.productCart;
    let subTotal = 0;
    let totTagTotOptions = 0;
    let foundProduct = 0;
    productCart.length > 0 &&
      productCart.map((item, i) => {
        if (item.productTitre !== "") {
          foundProduct++;
        }
      }, this);

    if (foundProduct < 1) {
      alert(`${t("zqwNVFrmEl20_x")}`);
      return false;
    }

    this.workflowProductList = true;
    const workflowType = this.state.workflowType;
    if (!this.workflowClientInfo || this.state.firstname === "") {
      this.workflowProductList = true;
      this.setState({ ["globalStepWorkflow"]: 3 });
    } else {
      this.setState({ ["globalStepWorkflow"]: 4 });
    }
    this.navigationTopRefOnTop.current.focus();
  };

  handleClientInfoNextStep = (event) => {
    event.preventDefault();
    const { t } = this.props;
    const globalStepWorkflow = this.state.globalStepWorkflow;
    const idClient = this.state.idClient;
    const firstname = this.state.firstname;
    const lastname = this.state.lastname;
    const cell = this.state.cell;
    const terms = 0; //this.state.terms;
    const active = 1; //this.state.active;
    //const created = this.state.created;
    const email = this.state.email;
    const id_users = this.state.id_users;
    const language = this.state.language;
    const push = 0; //this.state.push;
    const id_zendesk = this.state.id_zendesk;
    const sms = this.state.sms;
    const stripe_id = this.state.stripe_id;
    const occupation = this.state.occupation;
    const NeoClients = {
      idClient: idClient,
      firstname: firstname,
      lastname: lastname,
      cell: cell,
      terms: terms,
      active: active,
      email: email,
      id_users: id_users,
      language: language,
      push: push,
      id_zendesk: id_zendesk,
      sms: sms,
      stripe_id: stripe_id,
      occupation: occupation,
    };

    const idAddress = this.state.idAddress;
    const id_clients = this.state.id_clients;
    const address = this.state.address;
    const city = this.state.city;
    const province = this.state.province;
    const cp = this.state.cp;
    const country = this.state.country;
    const active2 = 1; //this.state.active;
    const tel = ""; //this.state.tel; //this.state.cell;
    const ext = ""; //this.state.ext;
    const company = this.state.company;
    const nameNx2 = ""; //this.state.name;
    const NeoAddress = {
      idAddress: idAddress,
      id_clients: id_clients,
      address: address,
      city: city,
      province: province,
      cp: cp,
      country: country,
      active: active2,
      tel: tel,
      ext: ext,
      company: company,
      name: nameNx2,
    };

    let errorFound = 0;
    for (let [fieldName, obj] of Object.entries(FIELD_VALIDATION)) {
      if (FIELD_VALIDATION[fieldName] !== undefined && obj.check === true) {
        this.setState({ ["e_" + fieldName]: false });
        let fieldValue = this.state[fieldName]; // ? this.state[fieldName] : this.props.NeoClients[fieldName];
        if (!this.validation.validate(obj.chkType, fieldValue)) {
          errorFound++;
          this.setState({ ["e_" + fieldName]: true });
        }
      }
    }
    if (errorFound > 0) {
      alert(`${t("zqwNVFrmEl21_x")}`);
      return false;
    }
    this.workflowClientInfo = true;
    const workflowType = this.state.workflowType;
    if (globalStepWorkflow === 2 && workflowType === 2) {
      this.setState({ ["globalStepWorkflow"]: 3 });
    } else if (globalStepWorkflow === 2 && workflowType === 3) {
      //go to 2
      this.workflowClientInfo = true;
      this.setState({ ["globalStepWorkflow"]: 2 });
    } else if (globalStepWorkflow === 3 && workflowType === 2) {
      //go to 4
      //this.workflowClientInfo=true;
      this.setState({ ["globalStepWorkflow"]: 4 });
    } else if (globalStepWorkflow === 3 && workflowType === 3) {
      //stay in 2 in fact
      this.workflowProductList = true;
      this.setState({ ["globalStepWorkflow"]: 2 });
    } else if (globalStepWorkflow === 2) {
      //go to 3
      this.workflowClientInfo = true;
      this.setState({ ["globalStepWorkflow"]: 3 });
    } else if (globalStepWorkflow === 3) {
      //go to 4
      this.workflowProductList = true;
      this.setState({ ["globalStepWorkflow"]: 4 });
    }

    this.navigationTopRefOnTop.current.focus();
  };

  handlePreviousStep = (event) => {
    const { t } = this.props;
    const globalStepWorkflow = this.state.globalStepWorkflow;
    const workflowType = this.state.workflowType;
    this.workflowClientInfo = false;
    this.workflowProductList = false;
    if (this.state.paymentRound > 0) {
      this.showToast(t("zqwNVFrmEl22_x"), "error");
      return false;
    }
    if (globalStepWorkflow === 4 && workflowType === 2) {
      //go to 3
      this.workflowProductList = true;
      this.setState({ ["globalStepWorkflow"]: 3 });
    } else if (globalStepWorkflow === 4 && workflowType === 3) {
      //go to 2
      this.workflowClientInfo = true;
      this.setState({ ["globalStepWorkflow"]: 2 });
    } else if (globalStepWorkflow === 3 && workflowType === 2) {
      //go to 2
      this.workflowClientInfo = true;
      this.setState({ ["globalStepWorkflow"]: 2 });
    } else if (globalStepWorkflow === 3 && workflowType === 3) {
      //stay in 3 in fact
      this.workflowProductList = true;
      this.setState({ ["globalStepWorkflow"]: 3 });
    } else if (globalStepWorkflow === 3) {
      //go to 2
      this.workflowClientInfo = true;
      this.setState({ ["globalStepWorkflow"]: 2 });
    } else if (globalStepWorkflow === 4) {
      //go to 3
      this.workflowProductList = true;
      this.setState({ ["globalStepWorkflow"]: 3 });
    } else if (globalStepWorkflow === 3) {
      //go to 2
      this.workflowClientInfo = true;
      this.setState({ ["globalStepWorkflow"]: 2 });
    }

    this.navigationTopRefOnTop.current.focus();
  };

  clientFullInfoValidate = (event) => {
    setTimeout(() => {
      let errorFound = 0;
      for (let [fieldName, obj] of Object.entries(FIELD_VALIDATION)) {
        if (FIELD_VALIDATION[fieldName] !== undefined && obj.check === true) {
          this.setState({ ["e_" + fieldName]: false });
          let fieldValue = this.state[fieldName];
          if (!this.validation.validate(obj.chkType, fieldValue)) {
            errorFound++;
            this.setState({ ["e_" + fieldName]: true });
          }
        }
      }
      //if(errorFound > 0){ alert(`{t('zqwerrorFoundonform_x')}`); return false; }
    });
  };

  handleFinalize = (event) => {
    const { t } = this.props;
    const productPriceTotal = this.state.grandTotal; //orderTotal
    const nowPayment = this.state.nowPayment;
    const paymentWay = this.state.paymentWay;
    const orderTotal = this.state.orderTotal;
    if (paymentWay === "partial" && orderTotal > 0) {
      this.showToast(t("zqwNVFrmEl23_x"), "error");
      return false;
    }

    const idClient = this.state.idClient;
    const firstname = this.state.firstname;
    const lastname = this.state.lastname;
    const cell = this.state.cell;
    const terms = 0; //this.state.terms;
    const active = 1; //this.state.active;
    const email = this.state.email;
    const id_users = this.state.id_users;
    const language = this.state.language;
    const push = 0; // this.state.push;
    const id_zendesk = this.state.id_zendesk;
    const sms = this.state.sms;
    const stripe_id = this.state.stripe_id;
    const occupation = this.state.occupation;
    const NeoClients = {
      idClient,
      firstname,
      lastname,
      cell,
      terms,
      active,
      email,
      id_users,
      language,
      push,
      id_zendesk,
      sms,
      stripe_id,
      occupation,
    };

    const idAddress = this.state.idAddress;
    const id_clients = this.state.id_clients;
    const address0 = this.state.address;
    const unitsuite = this.state.unitsuite;
    const city = this.state.city;
    const province = this.state.province;
    const cp = this.state.cp;
    const country = this.state.country;
    const active2 = 1; //this.state.active;
    const tel = ""; //this.state.tel; //this.state.cell;
    const ext = ""; //this.state.ext;
    const company = this.state.company;
    const nameNx2 = ""; //this.state.name;
    let address = address0;
    if (unitsuite !== "") {
      address = unitsuite + "-" + address;
    }
    const NeoAddress = {
      idAddress,
      id_clients,
      address,
      city,
      province,
      cp,
      country,
      active: active2,
      tel,
      ext,
      company,
      name: nameNx2,
    };

    const productId = this.state.productCart.length;
    const id = this.state.id;
    const name = this.state.name;
    const NeoVentes = [];

    const downPayment = this.state.nowPayment;
    const subNeoVentes = {
      isSub: true,
      id: 0,
      tax1: 0,
      tax2: 0,
      sub_total: 0,
      tax_total: 0,
      total: downPayment,
      amount: downPayment,
      options: [],
      tarifBase: downPayment,
      createTicket: true,
      pages: 0,
      perPage: 0,
      productPrice: 0,
      productPrice2: downPayment,
    };
    NeoVentes.push(subNeoVentes);
    const {salePrecision} = this.state;
    const tarifAmount = downPayment;
    const tax1 = 0;
    const tax2 = 0;
    const tax_total = 0;
    const totalForItem = (tarifAmount * 1 + tax_total * 1).toFixed(2);
    const mainNeoVentes = {
      isSub: false,
      tax1,
      tax2,
      sub_total: tarifAmount,
      tax_total,
      total: totalForItem,
      ...(salePrecision && {salePrecision})
    };
    NeoVentes.push(mainNeoVentes);

    let NeoPromocode = {
      id: 0,
      percentage: 0,
      name,
      amount: 0,
      tax1: 0,
      tax2: 0,
    };
    const cuponcodeId = this.state.cuponcodeId;
    const cuponcodeName = this.state.cuponcodeName;
    const promoAmount = this.state.promoAmount;
    const promoPercentage = this.state.promoPercentage;
    const promoTax1 = this.state.promoTax1;
    const promoTax2 = this.state.promoTax2;
    const stripeToken = this.state.stripeToken;
    let pMethod = 1;
    const dossier = this.state.dossier;
    pMethod = this.state.pMethod;

    const paymentRound = this.state.paymentRound;
    const isRecurrent = this.state.isRecurrentPayment;
    const qtyPayment = this.state.qtyPayment;
    const laterpayment = this.state.laterpayment;
    const fromDatePayment = this.state.fromDatePayment;
    const NeoPayments = {
      id,
      downPayment,
      paymentRound,
      paymentWay,
      isRecurrent,
      qtyPayment,
      recPaymentDay: laterpayment,
      recPaymentDate: fromDatePayment,
    };

    const id_invoice = this.state.id_invoice;
    if (id_invoice < 1) {
      this.showToast(t("zqwNVFrmEl24_x"), "error");
      return false;
    }

    const neoVentesBlocks = {
      client: NeoClients,
      address: NeoAddress,
      sales: NeoVentes,
      promocode: NeoPromocode,
      payment: NeoPayments,
      dossier,
      pMethod,
      stripeToken,
      id_invoice,
    };

    let sendPublicFormtxt = this.state.sendPublicFormtxt;
    let assignTicketFormtxt = this.state.assignTicketFormtxt;
    let bookingFormtxt = this.state.calendarBookingInfo;

    neoVentesBlocks["makeCloseOfSale"] = 100;
    let rawError = {};
    const upsaleTicket = this.state.upsaleTicket;

    this.setState({ isLoaderOpen: true });

    Promise.resolve(this.props.addOrUpdateNeoVentes(neoVentesBlocks, 1))
      .then((response) => {
        rawError = response;
        const resp = response.NeoVentes;

        if (resp.closeId > 0) {
          this.setState({
            openTransactionPopup: true,
            isTransactionClosed: true,
            ticket_id: resp.ticket_id,
            ticketList: resp.ticketList,
            finalizedTicketList: resp.ticketList,
            blockBtnByActionMessage: false,
            FUCK: true
          });

          if (
            bookingFormtxt === "" &&
            sendPublicFormtxt === "" &&
            assignTicketFormtxt === ""
          ) {
            this.setState({ blockBtnByActionMessage: true });
          }
          this.showToast(t("zqwNVFrmEl25_x"), "success");
          if (upsaleTicket !== null && upsaleTicket !== "") {
            this.getAllDoneActionsAftersalesList(event);
          }
        } else {
          this.showToast(t("zqwNVFrmEl26_x"), "error");
        }
        this.setState({ isLoaderOpen: false });
        return response;
      })
      .catch((error) => {
        const errObj = error?.response?.data ?? {};
        let errMessage =
          errObj?.data !== ""
            ? "Erreur " + " \n" + errObj?.data
            : "Erreur " + " \n" + errObj?.statusText;
        if (errObj?.status === undefined) {
          errMessage += "\n " + t("zqwNVFrmEl14_x");
        }
        this.setState({
          isLoaderOpen: false,
          errorTransacMessage: errMessage,
        });
        console.log("Error:", error);
      });
  };

  handleFinalizeCD = (event) => {
    const { t } = this.props;
    if (window.confirm(t("zqwNVFrmEl27_x"))) {
      this.handleFinalize(event);
    }
  };

  handleProductChange = (event) => {
    const { t } = this.props;
    const selVal = event.value; //productId
    this.setState({ ["productId"]: selVal });
    this.setState({ ["globalStepWorkflow"]: 2, ["workflowType"]: 2 });
    this.workflowProductList = true;
    const product = this.getProductChange(selVal);
    this.applyProductChange(selVal);
    this.showToast(t("zqwNVFrmEl28_x") + " #" + product.titre, "success");
  };

  handleProductChange2 = (event) => {
    const { t } = this.props;
    const selVal = event.value; //productId
    this.setState({ ["productId"]: selVal });
    const product = this.getProductChange(selVal);
    this.applyProductChange(selVal);
    this.showToast(t("zqwNVFrmEl28_x") + " #" + product.titre, "success");
  };

  getProductChange = (inputValue) => {
    let productItem = {};
    this.productsList.products.length > 0 &&
      this.productsList.products.map((item, i) => {
        if (
          item.type.indexOf("hidden") < 0 &&
          item.titre !== "" &&
          item.id == inputValue
        ) {
          productItem = item;
          return item;
        }
      });

    return productItem; //return false;
  };

  resetToNewCustomer = (event) => {
    const { t } = this.props;
    this.setState({ ["globalStepWorkflow"]: 3, ["workflowType"]: 3 });
    this.setState({
      ["valueClientSearch"]: "",
      ["inputValueClientSearch"]: "",
      ["selectedIdSuggestion"]: "",
      ["clientInfo"]: {},
      ["clientAddress"]: {},
    });

    const stateInitVar = {
      id: 0,
      name: "",
      e_name: false,
      description: "",
      e_description: false,
      amount: "",
      e_amount: false,

      idClient: "0",
      firstname: "",
      e_firstname: false,
      lastname: "",
      e_lastname: false,
      cell: "",
      e_cell: false,
      terms: "",
      e_terms: false,
      active: "",
      e_active: false,
      created: "",
      e_created: false,
      email: "",
      e_email: false,
      id_users: "0",
      e_id_users: false,
      language: "",
      e_language: false,
      push: "",
      e_push: false,
      id_zendesk: "",
      e_id_zendesk: false,
      sms: 1,
      e_sms: false,
      stripe_id: "",
      e_stripe_id: false,
      occupation: "",

      idAddress: 0,
      address: "",
      e_address: false,
      city: "",
      e_city: false,
      province: "QC",
      e_province: false,
      cp: "",
      e_cp: false,
      country: "Canada",
      e_country: false,
      company: "",
      e_company: false,
      existedClientRO: false,

      listOfSuggestion: [],
      valueClientSearch: "",
      inputValueClientSearch: "",
      selectedIdSuggestion: "",
      clientInfo: {},
      clientAddress: {},
      globalStepWorkflow: 3,
    };

    this.setState(stateInitVar);
    this.navigationTopRefOnTop.current.focus();
  };

  restartOver = (event) => {
    const { t } = this.props;
    let sendPublicFormtxt = this.state.sendPublicFormtxt;
    let assignTicketFormtxt = this.state.assignTicketFormtxt;
    let bookingFormtxt = this.state.calendarBookingInfo;
    const isTransactionClosed = this.state.isTransactionClosed;
    const upsaleTicket = this.state.upsaleTicket;

    if (
      bookingFormtxt === "" &&
      sendPublicFormtxt === "" &&
      assignTicketFormtxt === "" &&
      isTransactionClosed === true &&
      upsaleTicket === ""
    ) {
      this.showToast(t("zqwMesas026_x") + ", " + t("zqwMesas025_x"), "error");
    } else {
      this.handleCloseAndClean(event);
    }
  };
  setExtraServiceForProduct = (event) => {
    const taxInfo = this.state.taxInfo;
    const TAX1 = parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    const TAX2 = parseFloat(taxInfo["tax2x"]); //0.05; //tax2
    const TAX_TOTAL = TAX1 + TAX2; //0.14975;
    const TAX1_NAME = taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME = taxInfo["tax2_name"]; //"TPS";
    const TAX1l = taxInfo["tax1"]; //tax1 0.9975
    const TAX2l = taxInfo["tax2"];

    const selVal = event.target.value; //productId
    const arVal = selVal.split("-");
    const index = arVal[0];
    const prodId = arVal[1];
    const prodOptId = arVal[2];
    const amountOpt = parseFloat(arVal[3]);

    let productCart = this.state.productCart;
    let totTagTotOptions = productCart[index].totOptions * 1;
    let optionsProd = productCart[index].options;

    if (event.target.checked) {
      totTagTotOptions += amountOpt;
      optionsProd.push({ id: prodOptId, mnt: amountOpt });
    } else {
      totTagTotOptions -= amountOpt;
      const indz = this.indexOfElement(optionsProd, prodOptId, amountOpt);
      if (indz > -1) {
        optionsProd.splice(indz, 1);
      }
    }

    productCart[index].totOptions = totTagTotOptions;
    let tarif = productCart[index].productPrice * 1 + totTagTotOptions; // (''+item.tarif).replace(/\W\$/g, '');
    const productPriceTaxOnly = (tarif * 1 * TAX_TOTAL).toFixed(2);
    const productPriceTotal = (tarif * 1 * TAX_TOTAL + tarif * 1).toFixed(2);
    productCart[index].options = optionsProd;
    const orderServices = totTagTotOptions;
    const orderSubtotals = (tarif * 1).toFixed(2);

    productCart[index].productPriceTax = productPriceTotal;
    productCart[index].productPriceTaxOnly = productPriceTaxOnly;
    this.setState({ ["productCart"]: productCart });
    this.calculateGrandTotal(null, this.state.urgentSale);
  };

  showInfoForProduct = (event) => {
    const { t } = this.props;
    const prodId = event.currentTarget.dataset.id;
    const this00 = this;
    const arrProd = [6, 8, 9];
    let showProdMulti = 0;
    let lang = this.state.language;
    const agLang = t("curlang");
    if (lang === "") {
      lang = agLang;
    }
    if (prodId > 0) {
      if (arrProd.includes(parseInt(prodId))) {
        if (lang === "en") {
          showProdMulti = 2;
        } else {
          showProdMulti = 1;
        }
      }
      this.setState({
        ["isLoaderOpen"]: true,
        ["showProdMulti"]: showProdMulti,
      });
      Promise.resolve(this.props.getProductInfo(prodId, lang))
        .then((response) => {
          this.setState({ ["infoProduct"]: response.NeoCodePromoOne });
          this.setState({ ["isLoaderOpen"]: false });
          return response;
        })
        .catch(function (error) {
          this00.setState({ ["isLoaderOpen"]: false });
          window.alert(error);
        });
    }
  };

  setTicketForProduct = (event) => {
    //(event) {
    //event.preventDefault();
    const selVal = event.target.value; //productId
    const arVal = selVal.split("-");
    const index = arVal[0];
    const prodId = arVal[1]; //const prodOptId=arVal[2];  const amountOpt=parseFloat(arVal[3]);

    let productCart = this.state.productCart;

    if (event.target.checked) {
      productCart[index].cticket = true;
    } else {
      productCart[index].cticket = false;
    }
    //this.state.productCart[index].cticket

    //productCart[index].options=optionsProd;

    this.setState({ ["productCart"]: productCart });
  };

  setPageDocForProduct = (event) => {
    const { t } = this.props;
    event.preventDefault();
    const selVal = event.target.dataset.value; //event.target.value;
    let selPage = parseInt(event.target.value);
    const arVal = selVal.split("-");
    const index = arVal[0];
    const prodId = arVal[1];
    const prodOptId = arVal[2];
    const amountOpt = parseFloat(arVal[3]);
    const maximum = event.target.dataset.maximum * 1;
    const minimum = event.target.dataset.minimum * 1;
    //const perPage=(event.target.dataset.perpage);
    const perPage = amountOpt;
    const taxInfo = this.state.taxInfo;
    const TAX1 = parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    const TAX2 = parseFloat(taxInfo["tax2x"]); //0.05; //tax2
    const TAX_TOTAL = TAX1 + TAX2; //0.14975;
    const TAX1_NAME = taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME = taxInfo["tax2_name"]; //"TPS";
    const TAX1l = taxInfo["tax1"]; //tax1 0.9975
    const TAX2l = taxInfo["tax2"];

    if (selPage > maximum) {
      event.target.value = 1; //maximum;
      this.showToast(
        t("zqwNVFrmEl29_x", { maximum: maximum, selPage: selPage }),
        "error"
      );
      //return false;
    }

    let productCart = this.state.productCart;

    let totTagTotOptions = 0; //(productCart[index].totOptions*1);
    let optionsProd = []; //productCart[index].options;
    optionsProd = productCart[index].options;
    if (selPage <= minimum) {
      totTagTotOptions = 0;
    } else {
      totTagTotOptions = (selPage - minimum) * perPage;
    }
    optionsProd.id = "perPage";
    optionsProd.mnt = totTagTotOptions;
    productCart[index].totOptions = totTagTotOptions;
    const productPrice =
      productCart[index].productPrice2 === 0
        ? productCart[index].productPrice
        : productCart[index].productPrice2;

    let tarif = productPrice * 1 + totTagTotOptions; // (''+item.tarif).replace(/\W\$/g, '');
    const productPriceTaxOnly = (tarif * 1 * TAX_TOTAL).toFixed(2);
    const productPriceTotal = (tarif * 1 * TAX_TOTAL + tarif * 1).toFixed(2);
    productCart[index].options = optionsProd;
    const orderServices = totTagTotOptions;
    const orderSubtotals = (tarif * 1).toFixed(2);
    const orderTVQ = (tarif * 1 * TAX1).toFixed(2);
    const orderTPS = (tarif * 1 * TAX2).toFixed(2);

    productCart[index].productPriceTax = productPriceTotal;
    productCart[index].productPriceTaxOnly = productPriceTaxOnly;
    this.setState({ ["productCart"]: productCart });
    productCart[index].options = optionsProd;
    productCart[index].pages = selPage > 0 ? selPage : 1;
    productCart[index].perPage = perPage;

    this.setState({ ["productCart"]: productCart });
    this.calculateGrandTotal(null, this.state.urgentSale);
  };

  setNewPriceForProduct = (event, checkMin = false) => {
    //event.preventDefault();
    const selVal = (checkMin && event.target.value <= "1") ? "1" : event.target.value
    const selVal2 = event.target.dataset.value;
    const arVal = selVal2.split("-");
    const index = arVal[0];
    const prodId = arVal[1]; //const prodOptId=arVal[2];  const amountOpt=parseFloat(arVal[3]);

    let productCart = this.state.productCart;

    const taxInfo = this.state.taxInfo;
    const TAX1 = parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    const TAX2 = parseFloat(taxInfo["tax2x"]); //0.05; //tax2
    const TAX_TOTAL = TAX1 + TAX2; //0.14975;
    const TAX1_NAME = taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME = taxInfo["tax2_name"]; //"TPS";
    const TAX1l = taxInfo["tax1"]; //tax1 0.9975
    const TAX2l = taxInfo["tax2"];

    //productCart[index].options=optionsProd;
    productCart[index].productPrice2 = selVal;
    //this.prodPrice[index].current.value=selVal;

    //this.setState({ ["productCart"]:  productCart });

    let totTagTotOptions = productCart[index].totOptions * 1; // 0;
    let optionsProd = []; //productCart[index].options;

    // totTagTotOptions=((selPage-1)*perPage);
    // optionsProd.push({id: 'perPage', mnt:totTagTotOptions});

    // productCart[index].totOptions=totTagTotOptions;
    const productPrice =
      productCart[index].productPrice2 === 0
        ? productCart[index].productPrice
        : productCart[index].productPrice2;

    let tarif = productPrice * 1 + totTagTotOptions; // (''+item.tarif).replace(/\W\$/g, '');
    const productPriceTaxOnly = (tarif * 1 * TAX_TOTAL).toFixed(2);
    const productPriceTotal = (tarif * 1 * TAX_TOTAL + tarif * 1).toFixed(2);
    //productCart[index].options=optionsProd;
    const orderTVQ = (tarif * 1 * TAX1).toFixed(2);
    const orderTPS = (tarif * 1 * TAX2).toFixed(2);
    const orderServices = totTagTotOptions;
    const orderSubtotals = (tarif * 1).toFixed(2);

    productCart[index].productPriceTax = productPriceTotal;
    productCart[index].productPriceTaxOnly = productPriceTaxOnly;
    this.setState({ ["productCart"]: productCart });
    this.calculateGrandTotal(null, this.state.urgentSale);
  };

  setNewTaxfreeForProduct = (event) => {
    //event.preventDefault();
    const selVal = event.target.value; //productId
    const selVal2 = event.target.dataset.value;
    const arVal = selVal2.split("-");
    const index = arVal[0];
    const prodId = arVal[1]; //const prodOptId=arVal[2];  const amountOpt=parseFloat(arVal[3]);

    let productCart = this.state.productCart;
    const taxInfo = this.state.taxInfo;
    const TAX1 = parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    const TAX2 = parseFloat(taxInfo["tax2x"]); //0.05; //tax2
    const TAX_TOTAL = TAX1 + TAX2; //0.14975;
    const TAX1_NAME = taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME = taxInfo["tax2_name"]; //"TPS";
    const TAX1l = taxInfo["tax1"]; //tax1 0.9975
    const TAX2l = taxInfo["tax2"];
    productCart[index].discountFree = parseFloat(selVal);
    this.setState({ ["productCart"]: productCart });
    this.calculateGrandTotal(null, this.state.urgentSale);
    if (this.priceRef.current) {
      const input = this.priceRef.current;
      const syntheticEvent = new Event('input', { bubbles: true });
      Object.defineProperty(syntheticEvent, 'target', {
        writable: true,
        value: input
      });
      syntheticEvent.target.value = input.value;
      this.setNewPriceForProduct(syntheticEvent);
    }
  };

  handleRemoveProductItem = (event) => {
    const { t } = this.props;
    const index = event.target.dataset.prodindex; //const selVal= event.value;
    let productCart = this.state.productCart;
    let productExtraOptionCtrl2x = this.state.productExtraOptionCtrl2;
    const productTitre = productCart[index].productTitre;
    productExtraOptionCtrl2x[index] = <span key={index} />; //  productExtraOptionCtrl2x.splice(index, 1); ////productExtraOptionCtrl2x.push(divBlock);
    this.setState({ ["productExtraOptionCtrl2"]: productExtraOptionCtrl2x });

    const myCart = {
      index: index,
      id: index + "98989",
      productTitre: "",
      productPrice: 0,
      productPrice2: 0,
      productPriceTax: 0,
      productPriceTaxOnly: 0,
      totOptions: 0,
      options: [],
      isdelete: 1,
    };
    productCart[index] = myCart; //productCart.splice(index, 1); //productCart.push(myCart);
    this.setState({ ["productCart"]: productCart });
    this.showToast(t("zqwNVFrmEl123_x") + "  " + productTitre, "warning");
    this.calculateGrandTotal(null, this.state.urgentSale);
  };

  indexOfElement = (optionList, id, amount) => {
    let index = -1;
    optionList.length > 0 &&
      optionList.map((item, i) => {
        if (item.id === id && item.mnt === amount) {
          index = i;
          return i;
        }
      }, this);
    return index;
  };

  calculateGrandTotal = (event, urgentSale) => {
    const productCart = this.state.productCart;
    let subTotal = 0;
    let totTagTotOptions = 0;
    const taxInfo = this.state.taxInfo;
    const oldtax1 = this.state.oldtax1;
    const oldtax2 = this.state.oldtax2;
    let TAX1 = parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    let TAX2 = parseFloat(taxInfo["tax2x"]); //0.05; //tax2
    const TAX1_NAME = taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME = taxInfo["tax2_name"]; //"TPS";
    const TAX1l = taxInfo["tax1"]; //tax1 0.9975
    const TAX2l = taxInfo["tax2"];
    let TAX1lx = taxInfo["tax1"]; //tax1 0.9975
    let TAX2lx = taxInfo["tax2"];
    let TAX12 = 0;
    let TAX21 = 0;
    if (TAX2_NAME === "0") {
      TAX1lx = parseFloat(TAX1l) + parseFloat(TAX2l);
      TAX1 = TAX1 + TAX2;
      TAX12 = TAX1 + TAX2;
      TAX2 = 0;
    }
    if (TAX1_NAME === "0") {
      TAX2lx = parseFloat(TAX1l) + parseFloat(TAX2l);
      TAX2 = TAX2 + TAX1;
      TAX21 = TAX1 + TAX2;
      TAX1 = 0;
    }
    const TAX_TOTAL = TAX1 + TAX2; //0.14975;
    let discountFreetot = 0;

    productCart.length > 0 &&
      productCart.map((item, i) => {
        if (item.productTitre !== "") {
          const productPrice =
            item.productPrice2 === 0 ? item.productPrice : item.productPrice2;
          subTotal += parseFloat(productPrice); //item.productPrice
          if (item.discountFree !== undefined) {
            discountFreetot += parseFloat(item.discountFree);
          }
          totTagTotOptions += parseFloat(item.totOptions);
        }
      }, this);

    let tarif = subTotal * 1 + totTagTotOptions; // (''+item.tarif).replace(/\W\$/g, '');  TAX1 TAX2 TAX_TOTAL

    const percentage = this.state.promoPercentage;
    let tarifPromo = ((percentage * 1) / 100) * tarif;
    const orderBruteSubTotal = tarif;
    tarif = tarif - tarifPromo;
    let urgentSaleDollarAmount = 0;
    if (urgentSale) {
      urgentSaleDollarAmount = orderBruteSubTotal * URGENT_SALE_PERCENTAGE
    };
    tarif += urgentSaleDollarAmount
    let productPriceTaxOnly = (tarif * 1 * TAX_TOTAL).toFixed(2);
    let productPriceTVQ = (tarif * 1 * TAX1).toFixed(2);
    let productPriceTPS = (tarif * 1 * TAX2).toFixed(2);
    let productPriceTotalx =
      tarif * 1 * TAX_TOTAL + tarif * 1 + discountFreetot; //.toFixed(2) ;
    if (oldtax1 > 0 && oldtax2 > 0) {
      productPriceTaxOnly = (tarif * 1 * TAX_TOTAL).toFixed(2);
      productPriceTVQ = oldtax1;
      productPriceTPS = oldtax2;
      productPriceTotalx =
        oldtax1 * 1 + oldtax2 * 1 + tarif * 1 + discountFreetot;
    }

    const totalPaymentReceived = this.state.totalPaymentReceived;
    let productPriceTotal = (productPriceTotalx - totalPaymentReceived).toFixed(
      2
    );
    if (productPriceTotal == "-0.00") {
      productPriceTotal = "0.00";
    }

    this.setState({
      ["grandTotal"]: productPriceTotal,
      ["beforePaymentTotal"]: productPriceTotalx.toFixed(2),
    });
    this.setState({
      ["orderBruteSubTotal"]: orderBruteSubTotal,
      ["orderNetSubTotal"]: tarif,
      ["orderTVQ"]: productPriceTVQ,
      ["orderTPS"]: productPriceTPS,
      ["orderTotal"]: productPriceTotal,
      discountFreeTotal: discountFreetot,
      urgentSaleDollarAmount,
    });
    this.setState({ ["nowPayment"]: productPriceTotal });

    this.setState({ ["nowPayment"]: productPriceTotal });
  };

  applyProductChange = (inputValue) => {
    const { t } = this.props;
    const {groups, language, province} = this.state;
    const taxInfo = this.state.taxInfo;
    let TAX1 = parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    let TAX2 = parseFloat(taxInfo["tax2x"]); //0.05; //tax2
    //const TAX_TOTAL=TAX1+TAX2; //0.14975;
    const TAX1_NAME = taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME = taxInfo["tax2_name"]; //"TPS";
    const TAX1l = taxInfo["tax1"]; //tax1 0.9975
    const TAX2l = taxInfo["tax2"];
    let TAX1lx = taxInfo["tax1"]; //tax1 0.9975
    let TAX2lx = taxInfo["tax2"];
    let TAX12 = 0;
    let TAX21 = 0;
    if (TAX2_NAME === "0") {
      TAX1lx = parseFloat(TAX1l) + parseFloat(TAX2l);
      TAX1 = TAX1 + TAX2;
      TAX12 = TAX1 + TAX2;
      TAX2 = 0;
    }
    if (TAX1_NAME === "0") {
      TAX2lx = parseFloat(TAX1l) + parseFloat(TAX2l);
      TAX2 = TAX2 + TAX1;
      TAX21 = TAX1 + TAX2;
      TAX1 = 0;
    }

    const TAX_TOTAL = TAX1 + TAX2; //0.14975;

    const arrProd = [6, 8, 9];
    this.productsList.products.length > 0 &&
      this.productsList.products.map((item, i) => {
        if (
          item.type.indexOf("hidden") < 0 &&
          item.titre !== "" &&
          item.id == inputValue
        ) {
          let tarif = ("" + item.tarif).replace(/\W\$/g, "");
          tarif = tarif.replace("$", "");
          let discountFree = 0;
          let discountEnabled = false;
          if (item.discountFree !== undefined) {
            discountFree = parseFloat(item.discountFree);
            discountEnabled = true;
          }

          let tarifall = tarif;
          tarif = tarif - discountFree;

          const productPriceTaxOnly = (tarif * 1 * TAX_TOTAL).toFixed(2);
          //const productPriceTotal= (((tarif*1)*0.15)+(tarif*1)).toFixed(2); //(((tarif*1)+(productPriceTaxOnly)));
          const productPriceTotal = (
            tarif * 1 * TAX1 +
            tarif * 1 * TAX2 +
            tarif * 1 +
            discountFree
          ).toFixed(2);
          const orderTVQ = (tarif * 1 * TAX1).toFixed(2);
          const orderTPS = (tarif * 1 * TAX2).toFixed(2);
          const orderServices = 0;
          const orderSubtotals = (tarif * 1 + orderServices).toFixed(2);

          this.setState({ ["productTitre"]: item.titre });
          this.setState({ ["product"]: item });
          this.setState({ ["productPrice"]: tarifall });
          this.setState({ ["productPriceTax"]: productPriceTotal });
          this.setState({ ["productPriceTaxOnly"]: productPriceTaxOnly });
          const optionsEntries =
            item.options != undefined && !arrProd.includes(item.id)
              ? Object.entries(item.options)
              : [];
          const cartEntries = Object.entries(item.cart);

          const this00 = this;

          let productCart = this.state.productCart;
          const indexProd = productCart.length;
          const apartir = item.apartir;
          let apartirUsed = false;
          const optionsProd = item.options;

          const productExtraOptionCtrl = optionsEntries.map(function (
            elem,
            index
          ) {
            const [txtLabel, amount] = elem;
            const groupVal = "" + indexProd + "-" + item.id + "-" + amount;
            let titre = "";
            for (const [txtLabel2, labelValue] of cartEntries) {
              if (txtLabel === txtLabel2) {
                titre = labelValue;
                break;
              }
            }
            if (titre === "") {
              return <span key={index} />;
            } else {
              return (
                <p key={index}>
                  <input
                    type="checkbox"
                    value={amount}
                    onChange={(eventx) =>
                      this00.setExtraServiceForProduct(eventx)
                    }
                  />{" "}
                  {titre} (${amount})
                </p>
              );
            }
          });

          const productExtraOptionCtrl2 = optionsEntries.map(function (
            elem,
            index
          ) {
            const [txtLabel, amount] = elem;
            const groupVal =
              "" + indexProd + "-" + item.id + "-" + txtLabel + "-" + amount;
            let titre = "";
            for (const [txtLabel2, labelValue] of cartEntries) {
              if (txtLabel === txtLabel2) {
                titre = labelValue;
                break;
              }
            }
            if (titre === "" && apartir === false) {
              return <span key={index} />;
            }
            if (apartir === true) {
              //apartirUsed=true;
              if (apartirUsed === false) {
                apartirUsed = true;
                return (
                  <p key={index}>
                    {t("zqwNVFrmEl81_x")}:<br />
                    <input
                      type="number"
                      min="1"
                      step="1"
                      defaultValue={1}
                      data-value={groupVal}
                      data-perpage={optionsProd.perPage}
                      data-minimum={optionsProd.minimum}
                      data-maximum={optionsProd.maximum}
                      onChange={(eventx) => this.setPageDocForProduct(eventx)}
                    />{" "}
                    {t("zqwNVFrmEl82_x")} (zn x ${amount})
                  </p>
                );
              } else {
                return <span key={index} />;
              }
            } else {
              return (
                <p key={index}>
                  <input
                    type="checkbox"
                    value={groupVal}
                    onChange={(eventx) =>
                      this00.setExtraServiceForProduct(eventx)
                    }
                  />{" "}
                  {titre} (${amount})
                </p>
              );
            }
          });

          this.prodPrice[indexProd] = React.createRef();
          this.prodPriceTVQ[indexProd] = React.createRef();
          this.prodPriceTPS[indexProd] = React.createRef();
          this.prodTaxOnly[indexProd] = React.createRef();
          this.prodPriceAndTax[indexProd] = React.createRef();
          this.prodPriceAndTax2[indexProd] = React.createRef();
          this.prodPriceServices[indexProd] = React.createRef();
          this.prodPriceSubtotals[indexProd] = React.createRef();

          const rId = Math.floor(Math.random() * 16);
          this.setState({ ["productExtraOptionCtrl"]: productExtraOptionCtrl });
          this.setState({ ["showProductOption"]: true });
          let productExtraOptionCtrl2x = this.state.productExtraOptionCtrl2;
          this.setState({
            ["productExtraOptionCtrl2"]: productExtraOptionCtrl2x,
          });
          let myProOptions = []; //{} []
          if (optionsProd !== undefined && optionsProd.perPage !== undefined) {
            myProOptions = optionsProd;
          }

        const groupLanguage = langMap[language.toLowerCase()] ?? ""
        const groupProvince = provinceMap[province.toLowerCase()] ?? ""
        const itemGroups = [
          ...(item?.groups ? item.groups : []),
          ...(groupLanguage ? [groupLanguage] : []),
          ...(groupProvince ? [groupProvince] : [])
      ];
          const myCart = {
            index: indexProd,
            id: item.id,
            productTitre: item.titre,
            productPrice: tarif,
            productPrice2: 0,
            productPriceTax: productPriceTotal,
            //"productPriceTaxOnly": productPriceTaxOnly, "totOptions":0, "options": [],
            productPriceTaxOnly: productPriceTaxOnly,
            totOptions: 0,
            options: myProOptions,
            cticket: true,
            groups: itemGroups,
            pages: 0,
            perPage: 0,
            randcolor: rId,
            discountFree: discountFree,
            discountFree2: discountFree,
            discountEnabled: discountEnabled,
          };
          productCart.push(myCart);
          this.setState({ ["productCart"]: productCart });
          this.calculateGrandTotal(null, this.state.urgentSale);
          return false;
        }
        return null;
      }, this);

    return inputValue;
  };

  handleCancel = () => {
    if (this.actionMode === "new") {
      this.props.history.push(`/NeoVentes`);
    } else {
      const idx = this.props.match.params.id;
      this.props.history.push(`/NeoVentes/${idx}`);
      //this.props.history.push(`./NeoVentes/${this.props.NeoVentes.id}`);
    }
  };

  handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");
    return inputValue;
  };

  couponValidate = (event) => {
    const { t } = this.props;
    event.preventDefault();
    const inputValue = this.state.cuponcode; //event.target.value;
    if (inputValue === "") {
      this.showToast(t("zqwNVFrmEl30_x"), "error");
      return false;
    }

    Promise.resolve(this.props.getCodePromo(inputValue))
      .then((response) => {
        //this.setState(response.NeoVentesOne);
        const respObject = response.NeoCodePromoOne[0];
        if (respObject !== null || respObject !== undefined) {
          const percentage = respObject.percentage;
          //this.applyCouponValidationAmount(percentage);
          const cuponId = respObject.id;
          const cuponName = respObject.name;
          this.applyCouponValidationAmount(percentage, cuponId, cuponName);
        }
        return response;
      })
      .catch((e) => {
        console.error(e);
      });
    return inputValue;
  };

  handleCouponValidateChange = (event) => {
    const inputValue = ("" + event.value).split("-");
    const percentage = inputValue[1] * 1;
    const cuponId = inputValue[0];
    const cuponName = inputValue[2];
    this.setState({ ["cuponcode"]: cuponName });
    this.applyCouponValidationAmount(percentage, cuponId, cuponName);
  };

  //discountFree
  applyCouponValidationAmount = (percentage, cuponId, cuponName) => {
    const taxInfo = this.state.taxInfo;
    const TAX1 = parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    const TAX2 = parseFloat(taxInfo["tax2x"]); //0.05; //tax2
    const TAX_TOTAL = TAX1 + TAX2; //0.14975;
    const {urgentSaleDollarAmount} = this.state;
    if (percentage >= 0) {
      const productCart = this.state.productCart;
      const indexProd = productCart.length + 10000;
      let subTotal = 0;
      let totTagTotOptions = 0;
      let discountFreetot = 0;
      productCart.length > 0 &&
        productCart
          .filter((product) => product.productTitre !== "")
          .map((item, i) => {
            const productPrice =
              item.productPrice2 === 0 ? item.productPrice : item.productPrice2;
            subTotal += parseFloat(productPrice); //parseFloat(item.productPrice);
            if (item.discountFree !== undefined && item.discountFree > 0) {
              //discountFreetot +=parseFloat(item.discountFree);
              discountFreetot += item.discountFree;
            }
            totTagTotOptions += parseFloat(item.totOptions);
          }, this);

      let tarifall = subTotal * 1 + totTagTotOptions;
      let tarif = subTotal * 1 + totTagTotOptions;
      let tarifPromo = ((percentage * 1) / 100) * tarif;
      const orderBruteSubTotal = tarif;
      tarif = tarif - tarifPromo;
      tarif += urgentSaleDollarAmount
      tarifall += urgentSaleDollarAmount
      const productPriceTaxOnly = (tarif * 1 * TAX_TOTAL).toFixed(2);
      const productPriceTVQ = (tarif * 1 * TAX1).toFixed(2);
      const productPriceTPS = (tarif * 1 * TAX2).toFixed(2);
      const productPriceTotal = (
        tarif * 1 * TAX_TOTAL +
        tarif * 1 +
        discountFreetot
      ).toFixed(2);
      tarifall = tarifall - tarifPromo;
      this.setState({
        ["orderBruteSubTotal"]: orderBruteSubTotal,
        ["orderNetSubTotal"]: tarifall,
        ["orderTVQ"]: productPriceTVQ,
        ["orderTPS"]: productPriceTPS,
        ["orderTotal"]: productPriceTotal,
        ["grandTotal"]: productPriceTotal,
        ["discountFreeTotal"]: discountFreetot,
      });
      this.setState({ ["nowPayment"]: productPriceTotal });
      let tarifPromox = tarifPromo.toFixed(2);
      const promoTax1 = (tarifPromo * 1 * TAX1).toFixed(2);
      const promoTax2 = (tarifPromo * 1 * TAX2).toFixed(2);
      this.setState({
        ["cuponcodeId"]: cuponId,
        ["cuponcodeName"]: cuponName,
        ["showPromoLine"]: true,
        ["promoAmount"]: tarifPromox,
        ["promoPercentage"]: percentage,
        ["promoTax1"]: promoTax1,
        ["promoTax2"]: promoTax2,
      }, () => this.calculateGrandTotal(null, this.state.urgentSale));
    }
  };

  applyCouponValidationAmount00 = (percentage, cuponId, cuponName) => {
    const taxInfo = this.state.taxInfo;
    const TAX1 = parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    const TAX2 = parseFloat(taxInfo["tax2x"]); //0.05; //tax2
    const TAX_TOTAL = TAX1 + TAX2; //0.14975;

    if (percentage > 0) {
      const productCart = this.state.productCart;
      const indexProd = productCart.length + 10000;
      let subTotal = 0;
      let totTagTotOptions = 0;
      productCart.length > 0 &&
        productCart
          .filter((product) => product.productTitre !== "")
          .map((item, i) => {
            const productPrice =
              item.productPrice2 === 0 ? item.productPrice : item.productPrice2;
            subTotal += parseFloat(productPrice); //parseFloat(item.productPrice);
            totTagTotOptions += parseFloat(item.totOptions);
          }, this);

      let tarif = subTotal * 1 + totTagTotOptions; // (''+item.tarif).replace(/\W\$/g, '');  TAX1 TAX2 TAX_TOTAL
      let tarifPromo = ((percentage * 1) / 100) * tarif;
      const orderBruteSubTotal = tarif;
      tarif = tarif - tarifPromo;
      const productPriceTaxOnly = (tarif * 1 * TAX_TOTAL).toFixed(2);
      const productPriceTVQ = (tarif * 1 * TAX1).toFixed(2);
      const productPriceTPS = (tarif * 1 * TAX2).toFixed(2);
      const productPriceTotal = (tarif * 1 * TAX_TOTAL + tarif * 1).toFixed(2);
      this.setState({
        ["orderBruteSubTotal"]: orderBruteSubTotal,
        ["orderNetSubTotal"]: tarif,
        ["orderTVQ"]: productPriceTVQ,
        ["orderTPS"]: productPriceTPS,
        ["orderTotal"]: productPriceTotal,
        ["grandTotal"]: productPriceTotal,
      });
      this.setState({ ["nowPayment"]: productPriceTotal });
      let tarifPromox = tarifPromo.toFixed(2);
      const promoTax1 = (tarifPromo * 1 * TAX1).toFixed(2);
      const promoTax2 = (tarifPromo * 1 * TAX2).toFixed(2);
      this.setState({
        ["cuponcodeId"]: cuponId,
        ["cuponcodeName"]: cuponName,
        ["showPromoLine"]: true,
        ["promoAmount"]: tarifPromox,
        ["promoPercentage"]: percentage,
        ["promoTax1"]: promoTax1,
        ["promoTax2"]: promoTax2,
      });
      //event.target.disa
    }
  };

  handleCouponRemove = (event) => {
    //event.preventDefault();
    const taxInfo = this.state.taxInfo;
    const TAX1 = parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    const TAX2 = parseFloat(taxInfo["tax2x"]); //0.05; //tax2
    const TAX_TOTAL = TAX1 + TAX2; //0.14975;
    const {urgentSaleDollarAmount} = this.state;
    const percentage = 0;
    const productCart = this.state.productCart;
    const indexProd = productCart.length + 10000;
    let subTotal = 0;
    let totTagTotOptions = 0;
    let discountFreetot = 0;
    productCart.length > 0 &&
      productCart
        .filter((product) => product.productTitre !== "")
        .map((item, i) => {
          const productPrice =
            item.productPrice2 === 0 ? item.productPrice : item.productPrice2;
          subTotal += parseFloat(productPrice); //parseFloat(item.productPrice);
          if (item.discountFree !== undefined && item.discountFree > 0) {
            //discountFreetot +=parseFloat(item.discountFree);
            discountFreetot += item.discountFree;
          }
          totTagTotOptions += parseFloat(item.totOptions);
        }, this);

    let tarif = Number(subTotal) + totTagTotOptions + urgentSaleDollarAmount; // (''+item.tarif).replace(/\W\$/g, '');  TAX1 TAX2 TAX_TOTAL
    let tarifPromo = ((percentage * 1) / 100) * tarif;
    const orderBruteSubTotal = tarif;
    tarif = tarif - tarifPromo;
    const productPriceTaxOnly = (tarif * 1 * TAX_TOTAL).toFixed(2);
    const productPriceTVQ = (tarif * 1 * TAX1).toFixed(2);
    const productPriceTPS = (tarif * 1 * TAX2).toFixed(2);
    const productPriceTotal = (
      tarif * 1 * TAX_TOTAL +
      tarif * 1 +
      discountFreetot
    ).toFixed(2);
    this.setState({
      ["orderBruteSubTotal"]: orderBruteSubTotal,
      ["orderNetSubTotal"]: tarif,
      ["orderTVQ"]: productPriceTVQ,
      ["orderTPS"]: productPriceTPS,
      ["orderTotal"]: productPriceTotal,
      ["grandTotal"]: productPriceTotal,
    });
    this.setState({
      ["nowPayment"]: productPriceTotal,
      ["discountFreeTotal"]: discountFreetot,
    });

    let tarifPromox = tarifPromo.toFixed(2);
    const promoTax1 = (tarifPromo * 1 * TAX1).toFixed(2);
    const promoTax2 = (tarifPromo * 1 * TAX2).toFixed(2);
    this.setState({
      ["cuponcodeId"]: 0,
      ["cuponcodeName"]: "",
      ["showPromoLine"]: false,
      ["promoAmount"]: tarifPromox,
      ["promoPercentage"]: percentage,
      ["promoTax1"]: promoTax1,
      ["promoTax2"]: promoTax2,
    }, () => this.calculateGrandTotal(null, this.state.urgentSale));
  };

  getStripStatus = (stripobject) => {
    const { t } = this.props;
    //stripePaymentOpen to close
    //result.token.id  result.error.message
    const result = stripobject;
    const stripeNow = Date.now();
    const timesbetween = stripeNow * 1 - this.stripeTryTimes * 1;
    const stripeNeoToken = result.stripeNeoToken;
    if (timesbetween < 60000) {
      //this.stripeTryTimes=stripeNow;
      window.alert(t("zqwNVFrmEl31_x"));
      return false;
    } else {
      this.stripeTryTimes = stripeNow;
      this.stripeNeoTokenTrack = stripeNeoToken;
    }
    this.setState({
      ["stripePaymentOpen"]: false,
      ["stripeToken"]: result.token.id,
      ["isLoaderOpen"]: true,
    });
    this.showToast(t("zqwNVFrmEl32_x"), "success");
    //this.handleGetCustomerInfoPayment2(null);
    if (this.actionMode === "partialInvoice") {
      this.stripeAvoidManyTokens = 1;
      this.handleMakePartialPayment(null);
    } else {
      this.stripeAvoidManyTokens = 1;
      this.handleGetCustomerInfoPayment2(null);
    }
  };

  handleClosePostalCode = (anyx) => {
    this.setState({ ["openPossibleZipCode"]: false });
  };

  handleCloseListOfClient = (anyx) => {
    this.setState({ ["openPossibleListOfClient"]: false });
  };
  handleCloseListOfCDStatusInfo = (anyx) => {
    this.setState({ ["openPossibleCDStatusInfo"]: false });
  };

  handleCloseTransactionPopup = (event) => {
    this.setState({ ["openTransactionPopup"]: false });
  };

  handleCloseAndClean = (anyx) => {
    this.actionMode = "new";
    this.requestTimer = null;
    this.backInvoiceId = 0;
    this.requestTimer = null;
    this.stripeAvoidManyTokens = 0;
    this.stripeTryTimes = 0;
    this.stripeNeoTokenTrack = 0;
    this.paymentBtnTryTimes2 = 0;
    this.paymentBtnTryTimes3 = 0;
    this.setState({ ["openTransactionPopup"]: false });
    //this.showToast("Premiere etape, veuillez patieter un instant", "success");
    let codepromoOptions = [];
    const itemx = this.promoDefaultCode;
    codepromoOptions.push({
      value: itemx.id + "-" + itemx.percentage + "-" + itemx.name,
      label:
        itemx.description + " " + itemx.percentage + "% (" + itemx.name + ")",
    });

    const productsList = JSON.parse(
      JSON.stringify(this.state.productsList.products)
    );
    const reasonsList = JSON.parse(JSON.stringify(this.state.reasons)); //this.state.reasons;
    const {groups} = this.state;
    const listPromotionCodes = JSON.parse(
      JSON.stringify(this.state.listPromotionCodes)
    ); //this.state.listPromotionCodes;
    this.setState(JSON.parse(JSON.stringify(this.stateInit)));
    this.setState({
      ["listPromotionCodes"]: listPromotionCodes,
      ["productsList"]: { products: productsList },
      ["reasons"]: reasonsList,
      groups
    });
    const taxInfo = getProvinceTaxInfo("QC");
    this.setState({ ["taxInfo"]: taxInfo });
    this.actionMode = "new";
    this.requestTimer = null;
    this.backInvoiceId = 0;
    this.stripeAvoidManyTokens = 0;
    this.stripeTryTimes = 0;
    this.stripeNeoTokenTrack = 0;
    this.paymentBtnTryTimes2 = 0;
    this.paymentBtnTryTimes3 = 0;
    let promosList =
      listPromotionCodes.length > 0 &&
      listPromotionCodes.map((item, i) => {
        if (item.active == 1) {
          //if(item.percentage >0 && item.active==1){
          codepromoOptions.push({
            value: item.id + "-" + item.percentage + "-" + item.name,
            label:
              item.description +
              " " +
              item.percentage +
              "% (" +
              item.name +
              ")",
          });
        }
        return null;
      }, this);

    this.setState({ ["codepromoOptions"]: codepromoOptions });

    this.props.history.push(`/NeoVentes`);
  };
  handleCloseProdInfoPopup = (anyx) => {
    this.setState({ ["openInfoStepsPopup"]: false });
    //this.showToast("Premiere etape, veuillez patieter un instant", "success");
  };
  handleOpenProdInfoPopup = (event) => {
    const stepx = event.target.dataset.step;
    const stepcx = event.currentTarget.dataset.step;
    const step = stepcx;
    this.setState({ ["openInfoStepsPopup"]: true, ["infoStepsPopupno"]: step });
  };

  Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  handleClosePListAsOnTheSite = (anyEvent) => {
    this.setState({ ["openPListAsOnTheSite"]: false });
  };

  handleOpenPListAsOnTheSite = (anyEvent) => {
    this.setState({ ["openPListAsOnTheSite"]: true });
  };

  checkChargeDeskTransactionStatus = (event) => {
    const { t } = this.props;
    const chargeId = this.state.chargeDeskIdStr;
    if (chargeId === "") {
      window.alert(t("zqwNVFrmEl33_x"));
      return false;
    }
    if (chargeId === this.state.possibleCDStatusInfo.charge_id) {
      this.setState({ ["openPossibleCDStatusInfo"]: true });
    }
    Promise.resolve(this.props.getChargeDeskStatus(chargeId))
      .then((response) => {
        const info = response.NeoVentes; //JSON.parse(response); // response.NeoVentesOne;
        let pstatusInfo = [];
        pstatusInfo.push(
          <tr key={10}>
            <td>{t("zqwNVFrmEl34_x")}</td>
            <td>{info.customer_email}</td>
          </tr>
        );
        pstatusInfo.push(
          <tr key={19}>
            <td>{t("zqwNVFrmEl35_x")}</td>
            <td>{info.amount_formatted}</td>
          </tr>
        );
        pstatusInfo.push(
          <tr key={199}>
            <td>{t("zqwNVFrmEl36_x")}</td>
            <td>{info.description}</td>
          </tr>
        );
        pstatusInfo.push(
          <tr key={190}>
            <td>
              <b>{t("zqwNVFrmEl37_x")}</b>
            </td>
            <td>
              <b>{info.is_paid === "true" ? t("zqwoui_x") : t("zqwnon_x")}</b>
            </td>
          </tr>
        );
        pstatusInfo.push(
          <tr key={191}>
            <td>{t("zqwNVFrmEl38_x")}</td>
            <td>
              {info.status}/{info.status_text}
            </td>
          </tr>
        );
        pstatusInfo.push(
          <tr key={192}>
            <td>{t("zqwNVFrmEl39_x")}</td>
            <td>{info.amount_refunded}</td>
          </tr>
        );
        pstatusInfo.push(
          <tr key={193}>
            <td>{t("zqwNVFrmEl40_x")}</td>
            <td>{info.gateway_id}</td>
          </tr>
        );
        this.setState({
          ["openPossibleCDStatusInfo"]: true,
          ["possibleListOfCDStatusInfo"]: pstatusInfo,
          ["possibleCDStatusInfo"]: info,
        });
        return response;
      })
      .catch((e) => {
        console.error(e);
      });
  };

  frminputCloseFromChild = (event) => {
    this.setState({ ["showB2bLead"]: false });
  };

  gobackToLead = (event) => {
    //this.setState({["showB2bLead"]: false });
    if (this.actionMode === "fromLeadportal" && this.backLeadReId > 0) {
      const idx = this.backLeadReId;
      this.props.history.push(`/leads/${idx}`);
    }
  };

  callPhoneNumber = (event) => {
    const phoneno = event.currentTarget.dataset.phone;
    window["clicktocall"](phoneno);
  };

  render() {
    const { t } = this.props;
    let productOptions = [];
    const productsList = this.state.productsList.products;

    let productList =
      productsList.length > 0 &&
      productsList.map((item, i) => {
        if (item !== null) {
          // && item.id!==undefined
          const isUpsell = item.upsell;
          if (isUpsell !== null && isUpsell !== undefined) {
            productOptions.push({
              value: item.id,
              label: item.titre + " (" + item.tarif + ")",
            });
          } else {
            if (item.type.indexOf("hidden") < 0 && item.titre !== "") {
              productOptions.push({
                value: item.id,
                label: item.titre + " (" + item.tarif + ")",
              });
            }
          }
        }
        return null;
      }, this);

    const clientInfo = this.state.clientInfo;
    const clientAddress = this.state.clientAddress;

    if (clientInfo != null) {
    }
    const globalStepWorkflow = this.state.globalStepWorkflow;
    const myToastMessage = this.state.toastrMessage;
    const toastrType = this.state.toastrType;

    let days = [];
    for (let p = 1; p < 29; p++) {
      days.push(p);
    }

    const paymentDayList = days.map((item, i) => {
      return (
        <option key={item} value={item}>
          {item}
        </option>
      );
    }, this);

    const productPriceTotal = this.state.grandTotal; //orderTotal
    const nowPayment = this.state.nowPayment;
    const paymentWay = this.state.paymentWay;
    const paymentValid = {
      total: productPriceTotal,
      paymentWay: paymentWay,
      nowPayment: nowPayment,
    };
    const paymentRound = this.state.paymentRound;

    const ProductsOnInvoice = this.ProductsOnInvoice;
    const CustomerInfoAddress = this.CustomerInfoAddress;
    const PaymentSection = this.PaymentSection;

    const ZipCodePossibleAddress = this.ZipCodePossibleAddress;
    const ProductInfoStepsPopup = this.ProductInfoStepsPopup;
    const FinishTransactionPopup = this.FinishTransactionPopup;
    const ShowProductExtraInfo = this.ShowProductExtraInfo;
    const AutocompleteClient = this.AutocompleteClient;
    const AutocompleteClientB2B = this.AutocompleteClientB2B;
    const ProductListAsOnTheSite = this.ProductListAsOnTheSite;
    const Alert = this.Alert;
    const ListOfExistedPossibleClient = this.ListOfExistedPossibleClient;
    const CheckStatusOfChargeDeskCharge = this.CheckStatusOfChargeDeskCharge;
    const SaleTransactionClosingPopup = this.SaleTransactionClosingPopup;

    const listOfTickets = this.state.listOfTickets;
    const idpros = 0; //this.state.id;
    const firstname = this.state.firstname;
    const lastname = this.state.lastname;
    const cell = this.state.cell;
    const email = this.state.email;
    const gender = ""; //this.state.gender;
    const language = this.state.language;
    const dob = ""; //this.state.dob;
    const source = ""; //this.state.source;
    const current_insurances = "";
    const id_invoices = this.state.id_invoice;
    const rndidpros = Math.floor(Math.random() * 100000);
    const neoB2bleadProspects = {
      id: idpros,
      rndidpros: rndidpros,
      firstname: firstname,
      lastname: lastname,
      cell: cell,
      email: email,
      gender: gender,
      language: language,
      dob: dob,
      source: source,
      current_insurances: current_insurances,
      id_invoices: id_invoices,
    };
    const MessageInfoPopup = this.MessageInfoPopup;

    const backTicketId = 433332;
    const neoemail = "sidbenac_zx23@gmail.com";

    const PublicLinkFormPopup = this.PublicLinkFormPopup;
    const SAASFormLinkPopup = this.SAASFormLinkPopup;
    const AssignTicketFormPopup = this.AssignTicketFormPopup;
    const ZendFusionNotePopup = this.ZendFusionNotePopup;
    const assignTicketFormtxt = this.state.assignTicketFormtxt;
    const zendFusionNotetxt = this.state.zendFusionNotetxt;
    const ZendAddNotePopup = this.ZendAddNotePopup;
    const zendAddNotetxt = this.state.zendAddNotetxt;
    const sendPublicFormtxt = this.state.sendPublicFormtxt;
    const ZendAddTitleTAPopup = this.ZendAddTitleTAPopup;

    return (
      <div>
        <h3>
          {this.actionMode === "new"
            ? t("zqwacAddNeoVentes_x")
            : t("zqwacEditNeoVentes_x")}
        </h3>
        {this.actionMode !== "onSaleClose" ? (
          <div className="row">
            <div style={{width: "70%"}}>
              <span> </span>
              {this.actionMode === "fromLeadportal" && this.backLeadReId > 0 ? (
                <span>
                  <a href="#" onClick={this.gobackToLead}>
                    {t("zqwCViewEl58_x")} #{this.backLeadReId}
                  </a>{" "}
                </span>
              ) : (
                ""
              )}

              <input
                type="text"
                ref={this.navigationTopRefOnTop}
                style={{ position: "absolute", zIndex: -99 }}
              />
              <div className="form-group" style={{ display: "none" }}>
                <label htmlFor="id">{t("zqwid_x")}</label>
                <input
                  type="text"
                  name="id"
                  id="id"
                  defaultValue={this.state.id}
                  onChange={this.handleChange}
                  onBlur={this.handleFocusout}
                  className={
                    this.state.e_id ? "form-control fieldErr" : "form-control"
                  }
                  placeholder={t("zqwid_x")}
                />
                {this.state.e_id && (
                  <span className="text-danger">
                    {FIELD_VALIDATION["id"].message}
                  </span>
                )}
              </div>
              {globalStepWorkflow === 1 ? (
                <div className="form-group">
                  <label htmlFor="name">{t("zqwNVFrmEl41_x")}</label>
                  <Select
                    options={productOptions}
                    isDisabled={!this.state.groups.length || this.state.productAvailableLoading !== false}
                    isLoading={!this.state.groups.length || this.state.productAvailableLoading !== false}
                    onChange={this.handleProductChange}
                  />
                  {/* <this.AutocompleteClient /> */}
                  <span style={{ display: "inline" }}>
                    <a
                      href="#"
                      data-id={0}
                      onClick={this.handleOpenPListAsOnTheSite}
                    >
                      <HelpIcon />
                    </a>
                    &nbsp; &nbsp;
                    <a
                      href="#"
                      data-id={0}
                      onClick={this.getAllProductsList}
                      title="Rafraichir liste de produit/refresh product list"
                    >
                      <AutorenewIcon />
                    </a>
                  </span>
                  <div className="form-group">
                    <div className="md-8">
                      <AutocompleteClient key={91000} propstep={1} />
                    </div>
                    <div className="md-8">
                      <AutocompleteClientB2B key={91000} propstep={1} />
                    </div>
                    <div className="md-3">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={this.resetToNewCustomer}
                      >
                        {t("zqwNVFrmEl42_x")}
                      </button>
                    </div>

                    {/* <button type="button" className="btn btn-danger" onClick={this.handleOpenWinSTransaction}>FINALISER</button>     */}
                  </div>
                </div>
              ) : (
                <span />
              )}
              {globalStepWorkflow === 2 ? (
                <>
                  {this.state.firstname !== "" ? (
                    <div className="form-group">
                      <Alert severity="info">
                        {t("zqwNVFrmEl43_x")}:{" "}
                        <b>
                          {this.state.firstname + " " + this.state.lastname}
                        </b>
                      </Alert>
                    </div>
                  ) : (
                    ""
                  )}
                  <ProductsOnInvoice />
                </>
              ) : (
                <span />
              )}
              {globalStepWorkflow === 3 ? <CustomerInfoAddress /> : <span />}
              {globalStepWorkflow === 4 ? (
                <div className="md-12">
                  {/* Methode de Paiement du Client viendra ici! */}
                  <div className="form-group">
                    <Alert severity="info">
                      {t("zqwNVFrmEl43_x")}:{" "}
                      <b>{this.state.firstname + " " + this.state.lastname}</b>
                    </Alert>
                  </div>

                  {this.state.statusChargeDesk === 4 ? (
                    <div className="form-group">
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={this.checkChargeDeskTransactionStatus}
                      >
                        {t("zqwNVFrmEl44_x")}
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                  <PaymentSection listOfTickets={listOfTickets} />
                </div>
              ) : (
                <span />
              )}

              {globalStepWorkflow !== 1 && this.actionMode !== "onSaleClose" ? (
                <div className="form-group" style={{ marginTop: "150px" }}>
                  <a
                    href="#"
                    className="btn btn-default"
                    onClick={this.restartOver}
                  >
                    {t("zqwNVFrmEl45_x")}
                  </a>
                </div>
              ) : (
                ""
              )}
            </div>
            {this.state.productCart.length > 0 && (this.actionMode === "new" || this.actionMode === "fromLeadportal")  && <ProductAvailabilitiesList 
              handleGetSingleProductAvailabilities={this.handleGetSingleProductAvailabilities} 
              productAvailableLoading={this.state.productAvailableLoading} 
              products={this.state.productCart} 
              groups={this.state.groups}
              groupDomains={this.state.groupDomains} 
              handleProductGroupChange={this.handleProductGroupChange}
            />}
            {/* <div className="neox50">
              <div className="text-center b-t  b-grey p-t-20 p-b-20 p-l-40 p-r-40">
                <img
                  src="/static/assets/img/sceau-garantie-neo-fr.png"
                  height="100"
                  width="100"
                  className="m-b-10"
                />
                <div className="text-black fs-16 sm-fs-15 m-b-5">
                  {t("zqwNVFrmEl46_x")}
                </div>
              </div>
              <div style={{ margin: "20px" }}>
                <ShowProductExtraInfo proptest={"neo"} />
              </div>
            </div> */}
          </div>
        ) : (
          <div className="row" style={{display: "block"}}>
            <div className="">
              <div className="md-12">
                <div className="form-group">
                  <Alert severity="info">
                    {t("zqwNVFrmEl43_x")}:{" "}
                    <b>{this.state.firstname + " " + this.state.lastname}</b>
                  </Alert>
                </div>
                <PaymentSection listOfTickets={listOfTickets} />
              </div>
            </div>
            {/* <div className="neox50"> </div> */}
          </div>
        )}

        <Toastr messagex={myToastMessage} open={true} toastType={toastrType} />
        <Loader openx={this.state.isLoaderOpen} />
        <ZipCodePossibleAddress openx={this.state.isLoaderOpen} />
        <ProductInfoStepsPopup />
        <FinishTransactionPopup />
        <ProductListAsOnTheSite />
        <ListOfExistedPossibleClient />
        <CheckStatusOfChargeDeskCharge />
        <MessageInfoPopup />
        <SaleTransactionClosingPopup />
        <PublicLinkFormPopup />
        <SAASFormLinkPopup />
        <AssignTicketFormPopup />
        <ZendFusionNotePopup />
        <ZendAddNotePopup />
        <ZendAddTitleTAPopup />

        <div className="form-group">
          <br /> &nbsp; <br /> &nbsp; <br /> &nbsp; <br /> &nbsp; <br /> &nbsp;{" "}
          <br /> &nbsp; <br /> &nbsp; <br /> &nbsp;
        </div>
      </div>
    );
  }

  PaymentSection = (props) => {
    const { t } = this.props;
    const taxInfo = this.state.taxInfo;

    let TAX1 = parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    let TAX2 = parseFloat(taxInfo["tax2x"]); //0.05; //tax2
    const TAX_TOTAL = TAX1 + TAX2; //0.14975;
    const TAX1_NAME = taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME = taxInfo["tax2_name"]; //"TPS";
    const TAX1l = taxInfo["tax1"]; //tax1 0.9975
    const TAX2l = taxInfo["tax2"];
    let TAX1lx = taxInfo["tax1"]; //tax1 0.9975
    let TAX2lx = taxInfo["tax2"];
    let TAX12 = 0;
    let TAX21 = 0;
    if (TAX2_NAME === "0") {
      TAX1lx = parseFloat(TAX1l) + parseFloat(TAX2l);
      TAX1 = TAX1 + TAX2;
      TAX12 = TAX1 + TAX2;
      TAX2 = 0;
    }
    if (TAX1_NAME === "0") {
      TAX2lx = parseFloat(TAX1l) + parseFloat(TAX2l);
      TAX2 = TAX2 + TAX1;
      TAX21 = TAX1 + TAX2;
      TAX1 = 0;
    }

    const promoAmount = "-" + this.state.promoAmount;
    const orderBruteSubTotal = this.state.orderBruteSubTotal;
    const orderNetSubTotal = this.state.orderNetSubTotal;
    //const orderTVQ=this.state.orderTVQ;
    //const orderTPS=this.state.orderTPS;
    const orderTotal = this.state.orderTotal;
    const promoPercentage = this.state.promoPercentage;

    //paymentsReceived: [0], totalPaymentReceived: 0,
    const totalPaymentReceived = this.state.totalPaymentReceived;
    const beforePaymentTotal = this.state.beforePaymentTotal;

    const listOfTickets = props.listOfTickets; //this.state.listOfTickets;

    let orderTVQ = this.state.orderTVQ;
    let orderTPS = this.state.orderTPS;
    if (TAX2_NAME === "0") {
      const TAX1hard = parseFloat(orderTVQ) + parseFloat(orderTPS);
      orderTVQ = TAX1hard.toFixed(2);
    }
    if (TAX1_NAME === "0") {
      const TAX1hard = parseFloat(orderTVQ) + parseFloat(orderTPS);
      orderTPS = TAX1hard.toFixed(2);
    }

    let days = [];
    for (let p = 1; p < 29; p++) {
      days.push(p);
    }

    const paymentDayList = days.map((item, i) => {
      return (
        <option key={item} value={item}>
          {item}
        </option>
      );
    }, this);

    const listOfTicketsList = listOfTickets.map((item, i) => {
      if (item.ticket_id !== "" && item.ticket_id !== "0") {
        return (
          <option key={"tckt" + i} value={item.ticket_id}>
            {item.ticket_id + " (" + item.description + ")"}
          </option>
        );
      }
      return null;
    }, this);

    const productPriceTotal = this.state.grandTotal; //orderTotal
    const nowPayment = this.state.nowPayment;
    const paymentWay = this.state.paymentWay;
    const paymentValid = {
      total: productPriceTotal,
      paymentWay: paymentWay,
      nowPayment: nowPayment,
    };
    const paymentRound = this.state.paymentRound;

    const Alert = this.Alert;

    const showB2bLead = this.state.showB2bLead;
    const idpros = 0; //this.state.id;
    const firstname = this.state.firstname;
    const lastname = this.state.lastname;
    const cell = this.state.cell;
    const email = this.state.email;
    const gender = ""; //this.state.gender;
    const language = this.state.language;
    const dob = ""; //this.state.dob;
    const source = ""; //this.state.source;
    const current_insurances = "";
    const id_invoices = this.state.id_invoice;
    const rndidpros = Math.floor(Math.random() * 100000);
    const neoB2bleadProspects = {
      id: idpros,
      rndidpros: rndidpros,
      firstname: firstname,
      lastname: lastname,
      cell: cell,
      email: email,
      gender: gender,
      language: language,
      dob: dob,
      source: source,
      current_insurances: current_insurances,
      id_invoices: id_invoices,
    };

    const address = this.state.address;
    const unitsuite = this.state.unitsuite;
    const city = this.state.city;
    const province = this.state.province;
    const cp = this.state.cp;
    const country = this.state.country;
    const showSearchConflict = this.state.showSearchConflict;
    let ticket_id = this.state.ticket_id;
    //const ticket_id= "232358";
    const client_id = this.state.idClient; // client_id;

    let backTicketId = this.state.ticket_id; //433332;
    const invoiceId = this.state.id_invoice; //4750;
    const neoemail = this.state.email; //"sidbenac_zx23@gmail.com";  //client_email
    const client_email = this.state.email; //"sidbenac@gmail.com";
    const neoClientId = this.state.id_zendesk; //413958800713;
    const {ticket_idPopup} = this.props;
    const ticketToUse = ticket_idPopup || this.state.ticket_id || ""
    const clientObj = { firstname: firstname ?? "", lastname: lastname ?? "", email: client_email ?? "", id: client_id };
    const currentTicket = this.state.ticketList?.find(i => i.ta === ticketToUse) ?? {}
    const calendarBaseURL = process.env.REACT_APP_ENV === "production" ? "https://neodoc.app" : "https://stg.neodoc.app";
		const url = axios.getUri({
		  url: `/calendar/book/${ticketToUse}`,
		  params: {
      groups: currentTicket?.groups?.map(i => i.id) ?? [],
			domain_id: currentTicket?.product_id ?? currentTicket?.id_products ?? "", //product id
			// service_id: 1, //step id
			clients: JSON.stringify([clientObj]),
			lang: localStorage.getItem("i18nextLng")?.split('-')[0] ?? "fr",
		  },
		});
    const loginURL = axios.getUri({
      url: `${calendarBaseURL}/login-psj/${localStorage.getItem("admin_token")}`,
      params: {
        redirect: url,
      },
    });
    // 15;
    // import { encryptText, decryptText } from './appEncryption';
    let profile = null;
    let id_zendeskProf = "";
    if (
      localStorage.getItem("profile") !== undefined &&
      localStorage.getItem("profile") !== null
    ) {
      // profile=(decryptText(localStorage.getItem("profile"))).split(':');
      profile = decryptText(localStorage.getItem("profile"));
      const rProfile = JSON.parse(profile);
      if (rProfile.id_zendesk !== undefined && rProfile.id_zendesk !== null) {
        id_zendeskProf = rProfile.id_zendesk;
      }
    }

    const productCart = this.state.productCart;
    let subjectOne = "";

    productCart.length > 0 &&
      productCart.map((item, i) => {
        if (item.productTitre !== "" && subjectOne === "") {
          subjectOne = "" + item.productTitre;
        }
      }, this);

    const id_zendeskAgent = id_zendeskProf; //26141155848;
    const client_name = firstname + " " + lastname; //"John 007 Doe";
    const subject = subjectOne; //"Envoyez une mise en demeure avec un avocat";
    //const details="Jon Doe \n416 Av De La Pommeraie, Terrebonne(QC) J6Y 1V6 Canada";
    const details =
      client_name +
      " \n" +
      address +
      ", " +
      city +
      "(" +
      province +
      ") " +
      cp +
      " " +
      country;

    const isTransactionClosed = this.state.isTransactionClosed;
    const showRendevousBooking = this.state.showRendevousBooking;
    const sendMacro = this.state.sendMacro;
    const sendMacroInfo = this.state.sendMacroInfo;
    const mergeTicketNote = this.state.mergeTicketNote;
    const mergeTicketNoteInfo = this.state.mergeTicketNoteInfo;
    const showmergeTicketNote = this.state.showmergeTicketNote;
    const mergeTicketNotetxt = this.state.mergeTicketNotetxt;
    // const client_name=""+this.state.firstname+" "+this.state.lastname;
    const pMethod = this.state.pMethod;

    const backLeadClId = this.backLeadClId;
    const backLeadReId = this.backLeadReId;
    const backLeadTiId = this.backLeadTiId;
    let ticketListoo = [];

    if (this.props.frmGoXonSaleClosePopup !== undefined) {
      if (
        this.backLeadTiId > 0 &&
        (ticket_id < 1 || ticket_id === "" || ticket_id === "0")
      ) {
        backTicketId = this.backLeadTiId;
        ticket_id = this.backLeadTiId;
      }

      let ticketList = [];
      if (
        this.props.ticketList !== null &&
        this.props.ticketList !== undefined
      ) {
        ticketListoo = this.props.ticketList;
      }
    }
    if (this.state.showPromoLine === true) {
    }
    const reasonsList = this.state.reasons;
    const reasons =
      reasonsList.length > 0 &&
      reasonsList.map((item, i) => {
        const reasontxt = t("curlang") === "fr" ? item.name : item.name_en;
        return (
          <option value={item.id} key={"rs" + i}>
            {reasontxt}
          </option>
        );
        return null;
      }, this);

    const salereasons = this.state.salesreasontxt; // "";
    let sendPublicFormtxt = this.state.sendPublicFormtxt;
    let assignTicketFormtxt = this.state.assignTicketFormtxt;
    const zendFusionNotetxt = this.state.zendFusionNotetxt;
    let zendAddNotetxt = this.state.zendAddNotetxt;
    let zendAddTitleTAtxt = this.state.zendAddTitleTAtxt;

    let lead2actionFormtxt = this.state.lead2actionFormtxt;
    let searchConflicttxt = this.state.searchConflicttxt;
    //const bookingFormtxt= this.state.bookingFormtxt;
    let bookingFormtxt = this.state.calendarBookingInfo;
    let lead2actionFormtxt2 = this.state.lead2actionFormtxt2;
    let lead2actionFormtxt3 = this.state.lead2actionFormtxt3;

    let showActionMessage = 0;

    if (
      bookingFormtxt !== "" ||
      sendPublicFormtxt !== "" ||
      assignTicketFormtxt !== ""
    ) {
      showActionMessage = 7; //2
    }
    const statenameid = "" + this.state.statenameid;

    const refFuncAction = this.setMessageDoneAction;
    let saleReason = this.state.reason;
    const biginvoice = this.state.biginvoice;
    if (saleReason === "" && biginvoice !== undefined) {
      if (
        biginvoice !== undefined &&
        biginvoice.sales_reason !== undefined &&
        biginvoice.sales_reason !== ""
      ) {
        saleReason = biginvoice.sales_reason;
      }
    }

    let ticketList = this.state.ticketList;
    if (ticketList.length < 1 && ticketListoo.length > 0) {
      ticketList = ticketListoo;
    }
    const ticketSelected = ticket_id;
    const ticketListx =
      ticketList.length > 0 &&
      ticketList.map((item, i) => {
        if (item.ta !== "" && item.ta !== "0") {
          return (
            <option key={"gota" + i} value={item.ta}>
              {item.ta + " (" + item.description + ")"}
            </option>
          );
        }
        return null;
      }, this);

    const upsaleTicket = this.state.upsaleTicket;
    const actionsaftersales = this.state.actionsaftersales;
    const actionsafterx =
      actionsaftersales.length > 0 &&
      actionsaftersales.map((item, i) => {
        if (
          item.ticket_id === "" + ticketSelected ||
          (upsaleTicket !== "" && item.ticket_id === "" + upsaleTicket)
        ) {
          if (item.action_no === "1" && searchConflicttxt === "") {
            searchConflicttxt = item.action_text;
          } else if (item.action_no === "2" && bookingFormtxt === "") {
            bookingFormtxt = item.action_text;
            showActionMessage = 2;
          } else if (item.action_no === "3" && sendPublicFormtxt === "") {
            sendPublicFormtxt = item.action_text;
            showActionMessage = 3;
          } else if (item.action_no === "4" && zendAddNotetxt === "") {
            zendAddNotetxt = item.action_text;
          } else if (item.action_no === "5" && assignTicketFormtxt === "") {
            assignTicketFormtxt = item.action_text;
            showActionMessage = 5;
          } else if (item.action_no === "6" && zendAddTitleTAtxt === "") {
            zendAddTitleTAtxt = item.action_text;
          }
        }

        return null;
      }, this);

    if (showB2bLead === false && showSearchConflict === true) {
    }

    const credit3 = this.state.clientAddress.creditClient;
    let credit = null;
    if (credit3 !== undefined) {
      credit = credit3;
    }

    return (
      <div className="card card-primary card-outline">
        <div className="card-header">
          {/* {showSearchConflict===false?<h5 className="m-0">{t("zqwNVFrmEl47_x")}</h5>:<h5 className="m-0">{t("zqwNDICItxl_x")}</h5>} */}
          {isTransactionClosed === false &&
          showB2bLead === false &&
          showSearchConflict === false &&
          showRendevousBooking === false ? (
            <h5 className="m-0">{t("zqwNVFrmEl47_x")}</h5>
          ) : (
            ""
          )}
          {isTransactionClosed === true &&
          showB2bLead === false &&
          showSearchConflict === true ? (
            <h5 className="m-0">{t("zqwNDICItxl_x")}</h5>
          ) : (
            ""
          )}
          {isTransactionClosed === false &&
          showB2bLead === true &&
          showSearchConflict === false ? (
            <h5 className="m-0">...</h5>
          ) : (
            ""
          )}
          {isTransactionClosed === false &&
          showB2bLead === false &&
          showSearchConflict === false &&
          showRendevousBooking === true ? (
            <h5 className="m-0">Rendez-vous/Booking...</h5>
          ) : (
            ""
          )}
          {isTransactionClosed === false &&
          showB2bLead === false &&
          showSearchConflict === false &&
          (sendMacro === true || mergeTicketNote === true) ? (
            <h5 className="m-0">Fusion de Ticket/Ticket Merge</h5>
          ) : (
            ""
          )}
        </div>

        {isTransactionClosed === false &&
        showB2bLead === false &&
        showSearchConflict === false &&
        showRendevousBooking === false &&
        sendMacro === false &&
        mergeTicketNote === false ? (
          <div className="card-body">
            <div className="row">
              <div className="marginr md-8">
                <div className="card card-default card-outlinex">
                  <div className="card-header">
                    <h5 className="m-0">
                      {t("zqwNVFrmEl48_x")} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                    </h5>
                  </div>
                  <div className="card-body">
                    <div
                      id="coupon_div_form"
                      className="row"
                      style={{ visibility: "hidden" }}
                    >
                      <div className="col-xs-4 text-right neopad">
                        <label>
                          {t("zqwNVFrmEl49_x")}{" "}
                          <small>({t("zqwNVFrmEl50_x")})</small>
                        </label>
                      </div>
                      <span
                        id="coupon_div_success"
                        className="alert-success"
                      ></span>
                      <div id="coupon_div_field" className="col-xs-4 neopad">
                        <input
                          type="text"
                          name="cuponcode"
                          className="form-control"
                          onChange={this.handleChange}
                        />
                      </div>
                      <div id="coupon_div_field1" className="col-xs-4">
                        <button
                          type="button"
                          name="coupon_validate"
                          className="btn btn-info"
                          onClick={this.couponValidate}
                        >
                          {t("zqwNVFrmEl51_x")}
                        </button>
                      </div>
                    </div>
                    <div id="coupon_div_formqq" className="row">
                      <div className="neoinline30">
                        {/* <label>{t("zqwNVFrmEl52_x")} <small>({t("zqwNVFrmEl50_x")})</small></label> */}
                        <label>{t("zqwNVFrmEl52b_x")} &nbsp;&nbsp; </label>
                      </div>
                      <div className="neoinline70">
                        <Select
                          options={this.state.codepromoOptions}
                          placeholder={t("zqwNVFrmEl53_x")}
                          onChange={this.handleCouponValidateChange}
                        />
                      </div>
                    </div>
                    <div id="coupon_div_form4" className="row rowmbtm">
                      <div className="col-xs-4 text-right neopad">
                        <label>
                          <small>{genLang({en: "Accelerated fee", fr: "Frais accéléré"})}</small>
                        </label>
                      </div>
                      <div id="coupon_div_field4" className="col-xs-4 neopad">
                        {" "}
                        &nbsp;
                        <input
                          type="checkbox"
                          name="urgentSale"
                          className="form-controlx"
                          onClick={this.handleCheckBoxCheck}
                          checked={this.state.urgentSale}
                        />{" "}
                        {t("zqwoui_x")}
                      </div>
                      </div>
                    <div id="coupon_div_form4" className="row rowmbtm">
                      <div className="col-xs-4 text-right neopad">
                        <label>
                          <small>{t("zqwNVFrmEl54_x")}?</small>
                        </label>
                      </div>
                      {/* <span id="coupon_div_success4" className="alert-success"></span> */}
                      <div id="coupon_div_field4" className="col-xs-4 neopad">
                        {" "}
                        &nbsp;
                        <input
                          type="checkbox"
                          name="isAmerindian"
                          className="form-controlx"
                          onClick={this.handleCheckBoxCheck}
                          placeholder={"numéro amérindien"}
                          defaultChecked={this.state.isAmerindian}
                          disabled={this.state.paymentWayRO}
                        />{" "}
                        {t("zqwoui_x")}
                      </div>
                      {this.state.isAmerindian === true ? (
                        <div id="coupon_div_field14" className="col-xs-4">
                          <input
                            type="text"
                            name="no_amerindien"
                            className="form-control"
                            onChange={this.handleChange}
                            disabled={this.state.paymentWayRO}
                            defaultValue={this.state.no_amerindien}
                            onBlur={this.handleChangeAmerindian}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* <div className="row"><div className="neoinline100"> &nbsp; </div></div> */}
                    <div id="coupon_div_form5" className="row">
                      <div className="col-xs-4 text-right neopad">
                        <label>
                          <small>{t("zqwNVFrmEl55_x")}</small>
                        </label>
                      </div>
                      {/* <span id="coupon_div_success5" className="alert-success"></span> */}
                      <div id="coupon_div_field5" className="col-xs-4 neopad">
                        {" "}
                        &nbsp;
                        <input
                          type="checkbox"
                          name="isupsale"
                          className="form-controlx"
                          defaultChecked={this.state.isupsale}
                          onClick={this.handleCheckBoxCheck}
                          disabled={this.state.paymentWayRO}
                        />{" "}
                        {t("zqwoui_x")}
                      </div>
                      {this.state.isupsale === true ? (
                        <div id="coupon_div_field15" className="col-xs-4">
                          <select
                            name="upsaleTicket"
                            className={"form-control"}
                            defaultValue={this.state.upsaleTicket}
                            onChange={this.handleChangePaymentWay}
                            disabled={this.state.paymentWayRO}
                          >
                            <option value=""> {t("zqwNVFrmEl56_x")}</option>
                            {listOfTicketsList}
                          </select>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {(this.state.productCart.some(i => i.id == "117") || this.state.salePrecision) && <div style={{marginTop: "0.5rem"}}>
                      <span style={{fontWeight: "600"}}>{genLang({en: "Precision of sale", fr: "Précision de vente"})}</span>
                      <Input type="textarea" value={this.state.salePrecision} onChange={(e) => this.setState({salePrecision: e.target?.value ?? ""})}/>
                    </div>}
                    <div className="row">
                      <div className="neoinline100">
                        <hr />
                      </div>
                    </div>
                    <div className="row">
                      {/* <div className="md-12"><div className="neoinline100"><hr/></div></div> */}
                      <div className="col-md-3"></div>
                      <div className="col-md-8">
                        <div className="row">
                          <div className="neoinline50">
                            {t("zqwNVFrmEl57_x")}
                          </div>
                          <div className="neoinline50">
                            {" "}
                            <span id="subtotal">{orderBruteSubTotal}</span>$
                          </div>
                        </div>

                        {this.state.showPromoLine === true ? (
                          <div className="row">
                            <div className="neoinline50">
                              {t("zqwNVFrmEl58_x")} (
                              <span id="tax1_amount">{promoPercentage}</span>%)
                              <a href="#" onClick={this.handleCouponRemove}>
                                <DeleteForeverIcon />
                              </a>
                            </div>
                            <div className="neoinline50">
                              {" "}
                              <span id="subtotal">{promoAmount}</span>$
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {this.state.urgentSaleDollarAmount > 0 ? (
                          <div className="row">
                            <div className="neoinline50">
                              {/* {t("")}  */}
                              {genLang({en: "Accelerated fee", fr: "Frais accéléré"})}
                              (+<span id="tax1_amount">{URGENT_SALE_PERCENTAGE * 100}</span>%)
                            </div>
                            <div className="neoinline50">
                              {" "}
                              <span id="urgentSaleDollarAmount">{this.state.urgentSaleDollarAmount}</span>$
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {this.state.discountFreeTotal > 0 ? (
                          <div className="row">
                            <div className="neoinline50">
                              {/* {t("")}  */}
                              {"Gouv/Gov (non taxable)"}
                            </div>
                            <div className="neoinline50">
                              {" "}
                              <span id="urgentSaleDollarAmount">{this.state.discountFreeTotal}</span>$
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {TAX1_NAME !== "0" ? (
                          <div
                            className="row"
                            id="tax1_block"
                            style={{ display: "block" }}
                          >
                            {/* <div className="md-12 text-right"><span id="tax1_name">TVQ</span> (<span id="tax1_amount">9.975</span>%) <span id="tax1">16.86</span>$</div> */}
                            <div className="neoinline50 text-right">
                              <span id="tax1_name">
                                {TAX1_NAME !== "0" ? TAX1_NAME : ""}
                              </span>{" "}
                              (<span id="tax1_amount">{TAX1lx}</span>%)
                            </div>
                            <div className="neoinline50 text-right text-black">
                              {" "}
                              <span id="tax1">{orderTVQ}</span>$
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {TAX2_NAME !== "0" ? (
                          <div
                            className="row"
                            id="tax2_block"
                            style={{ display: "block" }}
                          >
                            {/* <div className="col-xs-6 text-right"><span id="tax2_name">TPS</span> (<span id="tax2_amount">5</span>%) <span id="tax2">8.45</span>$</div> */}
                            <div className="neoinline50">
                              <span id="tax2_name">
                                {TAX2_NAME !== "0" ? TAX2_NAME : ""}
                              </span>{" "}
                              (<span id="tax2_amount">{TAX2lx}</span>%)
                            </div>
                            <div className="neoinline50">
                              {" "}
                              <span id="tax2">{orderTPS}</span>$
                            </div>
                            {/* TPS TVQ */}
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="row">
                          <div className="neoinline100">
                            <hr />
                          </div>
                        </div>
                        {totalPaymentReceived > 0 ? (
                          <>
                            <div className="row">
                              <div className="neoinline50">
                                {t("zqwNVFrmEl59_x")}
                              </div>
                              <div className="neoinline50">
                                {" "}
                                <span id="totalap">{beforePaymentTotal}</span>$
                              </div>
                            </div>
                            <div
                              className="row"
                              id="tax2_block"
                              style={{ display: "block" }}
                            >
                              <div className="neoinline50">
                                <span id="tax2_name">
                                  {t("zqwNVFrmEl60_x")}
                                </span>
                              </div>
                              <div className="neoinline50">
                                {" "}
                                <span id="tax2">{totalPaymentReceived}</span>$
                              </div>
                            </div>
                            <div className="row">
                              <div className="neoinline100">
                                <hr />
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        <div className="row bold">
                          <div className="neoinline50">
                            {t("zqwNVFrmEl61_x")}
                          </div>
                          <div className="neoinline50">
                            {" "}
                            <span id="total">{orderTotal}</span>$
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br /> &nbsp; <br />
            <div className="row">
              <div className="marginr md-6">
                <div className="card card-default card-outlinex">
                  <div className="card-header">
                    <h5 className="m-0">
                      {t("zqwNVFrmEl62_x")} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                    </h5>
                  </div>
                  <div className="card-body">
                    {/* <div id="coupon_div_form3" className="rowx">                       */}

                    {credit !== null && credit.amount !== undefined ? (
                      <div className="form-group">
                        <Alert severity="info">
                          Crédit/Credit: <b>{credit.amount}$cad</b>
                        </Alert>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="form-group">
                      <label htmlFor="paymentWay">{t("zqwNVFrmEl63_x")}</label>
                      <select
                        name="paymentWay"
                        id="paymentWay"
                        ref={this.paymentWayRef}
                        className={"form-control"}
                        defaultValue={this.state.paymentWay}
                        onChange={this.handleChangePaymentWay}
                        disabled={this.state.paymentWayRO}
                      >
                        <option value="full"> {t("zqwNVFrmEl64_x")}</option>
                        <option value="partial">{t("zqwNVFrmEl65_x")}</option>
                        {/* <option value="recurrent"> Faire des paiements récurrents</option> */}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="nowPayment">{t("zqwNVFrmEl66_x")}</label>
                      <input
                        type="number"
                        min="1"
                        step="0.1"
                        name="nowPayment"
                        id="nowPayment"
                        value={this.state.nowPayment}
                        onChange={this.handleChangeRecurrent}
                        className={"form-control"}
                        placeholder={"quantité à abonner"}
                        readOnly={this.state.nowPaymentRO}
                      />
                    </div>
                    {this.state.isRecurrentPayment === 1 ? (
                      <>
                        <div className="form-group">
                          <label htmlFor="qtyPayment">
                            #{t("zqwNVFrmEl67_x")}
                          </label>
                          <div className="input-group">
                            <input
                              type="number"
                              min="1"
                              step="1"
                              name="qtyPayment"
                              id="qtyPayment"
                              defaultValue={1}
                              onChange={this.handleChangeRecurrent}
                              className={"form-control"}
                              placeholder={"quantité"}
                            />
                            <input
                              type="text"
                              name="recurPaymentAmount"
                              value={this.state.recurPaymentAmount}
                              onChange={this.handleChange}
                              className={"form-control"}
                              placeholder={"montant recurrent"}
                              readOnly={true}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="laterpayment">
                            {t("zqwNVFrmEl68_x")}{" "}
                          </label>
                          {/* <input type="text" name="laterpayment" id="laterpayment" defaultValue={this.state.laterpayment} onChange={this.handleChange} onBlur={this.handleFocusout}
                      className={'form-control'} placeholder={'date de paiement postérieur'} /> du mois */}
                          <select
                            id="laterpayment"
                            name="laterpayment"
                            className={"form-control"}
                            defaultValue={this.state.laterpayment}
                            onChange={this.handleChange}
                          >
                            {paymentDayList}
                          </select>
                        </div>
                        <div className="form-group">
                          <label htmlFor="fromDatePayment">
                            {t("zqwNVFrmEl69_x")}
                          </label>
                          <input
                            type="date"
                            name="fromDatePayment"
                            defaultValue={this.state.fromDatePayment}
                            onChange={this.handleChange}
                            onBlur={this.handleFocusout}
                            className={"form-control"}
                            placeholder={t("zqwNVFrmEl70_x")}
                          />
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
            <br /> &nbsp; <br />
            {paymentRound > 0 ? (
              <div className="form-group">
                {pMethod === 1 ? (
                  <Alert severity="success">
                    {t("zqwNVFrmEl71_x")} {t("zqwNVFrmEl72_x")} #{paymentRound}
                  </Alert>
                ) : (
                  <Alert severity="success">
                    {t("zqwNVFrmEl71b_x")} {t("zqwNVFrmEl72_x")} #{paymentRound}
                  </Alert>
                )}
                {/* <Alert severity="success">{t("zqwNVFrmEl71_x")} {t("zqwNVFrmEl72_x")} #{paymentRound}</Alert> */}
                <br /> &nbsp; <br />
              </div>
            ) : (
              ""
            )}
            {this.state.errorTransacMessage !== "" ? (
              <div className="form-group">
                <Alert severity="error">{this.state.errorTransacMessage}</Alert>
                <br /> &nbsp; <br />
              </div>
            ) : (
              ""
            )}
            {this.state.partialCanClose === 0 ? (
              <>
                <StripePayment
                  openx={this.state.stripePaymentOpen}
                  valid={paymentValid}
                  stripestatus={this.getStripStatus}
                  idInvoice={this.state.id_invoice}
                  cuponcodeId={this.state.cuponcodeId}
                />
                <input
                  type="checkbox"
                  id="tentative"
                  name="tentative"
                  className="form-controlx"
                  defaultChecked={this.state.tentative === "1" ? true : false}
                  value="1"
                  onClick={this.handleCheckBoxCheckOnOff}
                />{" "}
                {t("zqwMesas022_x")} <br />
                <button
                  name="interac"
                  type="submit"
                  value="Payer avec "
                  onClick={this.payWithtInterac}
                  className="btn btn-lg btn-rounded btn-cons btn-complete text-white"
                >
                  {t("zqwNVFrmEl73_x")}{" "}
                  <img
                    width="32"
                    src="/static/assets/img/cards/4.png"
                    alt="Interac"
                  />
                </button>
                <a
                  href="#"
                  className="btn btn-rounded btn-warning"
                  onClick={this.payWithtChargeDesk}
                >
                  {t("zqwNVFrmEl74_x")}{" "}
                  <img
                    height="40"
                    src="/static/assets/img/cards/5.png"
                    alt="Charge Desk"
                  />
                </a>
                &nbsp;
                {this.state.stripe_id !== "" ? (
                  <button
                    type="button"
                    name="coupon_validate"
                    className="btn btn-info"
                    onClick={this.payWithCardOnFile}
                  >
                    {t("zqwNVFrmEl75_x")}
                  </button>
                ) : (
                  ""
                )}
                {/* {orderTotal >= FLEXITI_MIN ? (
                  <button
                    name="flexiti"
                    type="submit"
                    value="Payer avec "
                    onClick={this.payWithFlexiti}
                    className="btn btn-lg btn-rounded btn-warning"
                  >
                    {t("zqwNVFrmEl73_x")}{" "}
                    <img
                      width="68"
                      src="/static/assets/img/cards/flexiti.svg"
                      alt="Interac"
                    />
                  </button>
                ) : (
                  ""
                )} */}
                {credit !== null && credit.amount !== undefined ? (
                  <button
                    name="noecredit"
                    type="submit"
                    value="Payer avec NeoCredit"
                    onClick={this.payWithNeoCredit}
                    data-neocreditamount={credit.amount}
                    data-neocreditid={credit.id}
                    className="btn btn-lg btn-rounded btn-cons btn-complete text-white"
                  >
                    {t("zqwNVFrmEl73_x")} NeoCredit{" "}
                    <img
                      width="38"
                      src="/static/assets/img/sceau-garantie-neo-fr.png"
                      alt="NeoCredit"
                    />
                  </button>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
            {/* <this.AppStripeFormPayment /> */}
            <br /> &nbsp; <br />
            <div className="form-group">
              {this.actionMode !== "partialInvoice" ? (
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={this.handlePreviousStep}
                >
                  {t("zqwNVFrmEl76_x")}
                </button>
              ) : (
                ""
              )}{" "}
              &nbsp;
              {this.state.twoStepSale === "interac" ? (
                <div className="form-group">
                  <Alert severity="warning">{t("zqwNVFrmEl77_x")}</Alert>
                  <br /> &nbsp; <br />
                </div>
              ) : (
                ""
              )}
              {this.state.twoStepSale === "flexiti" ? (
                <div className="form-group">
                  <Alert severity="warning">{t("zqwNVFrmEl77b_x")}</Alert>
                  <br /> &nbsp; <br />
                </div>
              ) : (
                ""
              )}
              {(paymentRound > 0 &&
                parseFloat(this.state.nowPayment) <= 0 &&
                this.state.pMethod === 1) ||
              (this.state.partialCanClose > 0 &&
                this.state.status_invoice !== "payed" &&
                this.state.statusChargeDesk === 0) ? (
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={this.handleFinalize}
                >
                  {t("zqwNVFrmEl78_x")}
                </button>
              ) : (
                ""
              )}
              {(paymentRound > 0 && this.state.pMethod != 1) ||
              this.state.partialCanClose > 0 ? (
                // <button type="button" className="btn btn-warning" onClick={this.restartOver}>{t("zqwNVFrmEl79_x")}</button></>
                <>
                  {" "}
                  &nbsp;
                  {this.actionMode === "fromLeadportal" &&
                  this.backLeadReId > 0 ? (
                    <span>
                      <a href="#" onClick={this.gobackToLead}>
                        {t("zqwCViewEl58_x")} #{this.backLeadReId}
                      </a>{" "}
                    </span>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-warning"
                      onClick={this.restartOver}
                    >
                      {t("zqwNVFrmEl79_x")}
                    </button>
                  )}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          <div className="card-body">
            {this.state.isTransactionClosed === true &&
            showB2bLead === false &&
            showSearchConflict === false &&
            showRendevousBooking === false &&
            sendMacro === false &&
            mergeTicketNote === false ? (
              <div className="card-bodyqqq">
                <div
                  className="form-group salereasonBlck"
                  key={"sr" + saleReason}
                >
                  <label htmlFor="reason">{t("zqwsalreason_x")}</label>
                  <select
                    name="reason"
                    id="reason"
                    defaultValue={saleReason}
                    onChange={this.handleChange}
                    onBlur={this.handleFocusout}
                    className={"form-control"}
                    placeholder={t("zqwreason_x")}
                  >
                    <option value="">{t("pleaseSel_x")}</option>
                    {reasons}
                  </select>
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={this.handleSaveSalesReasons}
                  >
                    {t("btnSave2_x")}
                  </button>
                  {salereasons !== "" ? (
                    <>
                      <br />
                      <br />
                      <Alert severity="success">{salereasons}</Alert>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <br />

                <div className="form-group" key={"talist000" + ticketSelected}>
                  <label htmlFor="reason">...# TA</label>
                  {/* <select name="ticket_id" id="ticket_id" defaultValue={ticketSelected}  onChange={this.handleChange} onBlur={this.handleFocusout} */}
                  <select
                    name="ticket_id"
                    id="ticket_id"
                    defaultValue={ticketSelected}
                    onChange={this.handleChangeTA}
                    className={"form-control"}
                    placeholder="liste de ticket/ticket List"
                  >
                    <option value="">{t("pleaseSel_x")}</option>
                    {ticketListx}
                  </select>
                </div>
                <br />

                <div className="form-row">
                  <div className="form-group col-md-7">
                    <div className="">
                      <div className="form-group">
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-statename="showSearchConflict"
                          onClick={this.handleCheckBoxCheckBtn}
                        >
                          {t("zqwNDICItx21_x")}
                        </button>
                        {searchConflicttxt !== "" ? (
                          <>
                          <Alert severity="success">
                            {searchConflicttxt}
                          </Alert>
                        </>
                        ) : (
                          ""
                        )}
                      </div>

                      <div
                        className="form-group"
                        style={{ display: "flex", gap: "0.5rem" }}
                      >
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={this.gotoSendPublicForm}
                        >
                          {t("zqwNFFrmZn09_x")}
                        </button>
                        {/* <button
                          type="button"
                          className="btn btn-primary"
                          onClick={this.gotoSendSAASForm}
                        >
                          {"SAAS FORM"}
                        </button> */}
                      </div>
                      {sendPublicFormtxt !== "" && (
                        <div style={{ marginBottom: "0.5rem" }}>
                          <Alert severity="success">{sendPublicFormtxt}</Alert>
                        </div>
                      )}

                      <div className="form-group">
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-statename="showRendevousBooking"
                          onClick={this.handleCheckBoxCheckBtn}
                        >
                          {t("zqwNDICItbook2_x")}
                        </button>
                        {bookingFormtxt !== "" ? (
                          <>
                            <Alert severity="success">{bookingFormtxt}</Alert>
                          </>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="form-group">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={this.gotoZendAddNoteForm}
                        >
                          {t("zqwMesas014_x")}
                        </button>
                        {zendAddNotetxt !== "" ? (
                          <>
                            <Alert severity="success">{zendAddNotetxt}</Alert>
                          </>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="form-group">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={this.gotoAssignTicketForm}
                        >
                          {t("zqwMesas08_x")}
                        </button>
                        {assignTicketFormtxt !== "" ? (
                          <>
                            <Alert severity="success">
                              {assignTicketFormtxt}
                            </Alert>
                          </>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="form-group">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={this.gotoZendAddTitleTAForm}
                        >
                          {t("Update_x")} Titre TA
                        </button>
                        {zendAddTitleTAtxt !== "" ? (
                          <>
                            <Alert severity="success">
                              {zendAddTitleTAtxt}
                            </Alert>
                          </>
                        ) : (
                          ""
                        )}
                      </div>

                      {showActionMessage < 1 ? (
                        <div className="form-group">
                          <>
                            <br />
                            <br />
                            <Alert severity="error">{t("zqwMesas025_x")}</Alert>
                          </>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="rowQQ" style={{ marginLeft: "10px" }}>
                      <div className="row">
                        <div className="form-groupX">
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-statename="showB2bLead"
                            data-statenameid="2"
                            onClick={this.handleCheckBoxCheckBtn}
                          >
                            B2B lead prospect
                          </button>
                          {lead2actionFormtxt !== "" ? (
                            <>
                              <br />
                              <br />
                              <Alert severity="success">
                                {lead2actionFormtxt}
                              </Alert>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {showB2bLead === true &&
            showSearchConflict === false &&
            showRendevousBooking === false ? (
              <div className="card-bodyqqq">
                <div className="form-row">
                  <input
                    type="checkbox"
                    name="showB2bLead"
                    className="form-controlx"
                    defaultChecked={this.state.showB2bLead}
                    onClick={this.handleCheckBoxCheck}
                  />{" "}
                  {t("zqwsendleadcny_x")}
                </div>
                <div className="form-rowx">
                  {statenameid === "2" ? (
                    <NeoB2bleadProspectsForm
                      frminput={neoB2bleadProspects}
                      invoiceId={"" + invoiceId}
                      ticket_id={"" + ticket_id}
                      client_id={"" + client_id}
                      backTicketId={backTicketId}
                      frminputClose={this.frminputCloseFromChild}
                      paStateFuncAction={refFuncAction}
                      ticketList={this.props?.ticketList ?? []}
                    />
                  ) : (
                    ""
                  )}
                </div>
                {/* const backLeadClId=this.backLeadClId; const backLeadReId=this.backLeadReId;
          const backLeadTiId=this.backLeadTiId; */}
              </div>
            ) : (
              <div className="card-bodyqqqq">
                {showB2bLead === false && showSearchConflict === true ? (
                  <div className="card-bodyqqqq">
                    <div className="form-row">
                      <input
                        type="checkbox"
                        id="showSearchConflict"
                        name="showSearchConflict"
                        className="form-controlx"
                        defaultChecked={this.state.showSearchConflict}
                        onClick={this.handleCheckBoxCheck}
                      />{" "}
                      {t("zqwNDICItx21_x")}
                    </div>
                    <div className="form-rowx">
                      <iframe
                        src={
                          NDIWebsite +
                          "/zendesk/" +
                          ticket_id +
                          "/ticket?ifr=neosales&limTab=2"
                        }
                        id="iframeNDIchild0007"
                        style={{
                          width: "100%",
                          minHeight: "500px",
                          border: "0",
                        }}
                      ></iframe>
                    </div>
                    <div className="form-group" style={{ display: "block" }}>
                      <br />
                      <br /> <br /> &nbsp;{" "}
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={this.handleConfActions4}
                      >
                        {t("zqwCViewEl56_x")}
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {showB2bLead === false &&
                showSearchConflict === false &&
                showRendevousBooking === true ? (
                  <div className="card-bodyqqqq">
                    <div className="form-row">
                      <input
                        type="checkbox"
                        id="showRendevousBooking"
                        name="showRendevousBooking"
                        className="form-controlx"
                        defaultChecked={this.state.showRendevousBooking}
                        onClick={this.handleCheckBoxCheck}
                      />{" "}
                      {t("zqwNDICItbook2_x") +
                        "" +
                        this.state.calendarBookingInfo}
                    </div>
                    <div className="form-rowx">
                      <iframe
                      src={loginURL}
                        style={{
                          width: "100%",
                          minHeight: "700px",
                          border: "0",
                        }}
                      ></iframe>
                    </div>
                    <div className="form-group" style={{ display: "block" }}>
                      <br />
                      <br /> <br /> &nbsp;{" "}
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={this.handleConfActions4}
                      >
                        {t("zqwCViewEl56_x")}
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {showB2bLead === false &&
                showSearchConflict === false &&
                mergeTicketNote === true ? (
                  <div className="card-bodyqqqq">
                    <div className="form-row">
                      <input
                        type="checkbox"
                        id="mergeTicketNote"
                        name="mergeTicketNote"
                        className="form-controlx"
                        defaultChecked={this.state.mergeTicketNote}
                        onClick={this.handleCheckBoxCheck}
                      />{" "}
                      {t("zqwFlowvtx1_x")}
                    </div>
                    <div className="form-rowx">
                      <ZendFusionNoteForm
                        backTicketId={backTicketId}
                        neoemail={neoemail}
                        client_id={"" + client_id}
                        client_name={"" + client_name}
                        invoiceId={invoiceId}
                        leadClientId={backLeadClId}
                        leadId={backLeadReId}
                        leadTicketId={backLeadTiId}
                      />
                      add note and merge tickeeeeeeeeeeeeeeeeeeeeeeeeeeeet
                    </div>
                    <div className="form-group" style={{ display: "block" }}>
                      <br />
                      <br /> <br /> &nbsp;{" "}
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={this.handleConfActions4}
                      >
                        {t("Cancel")}
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {showB2bLead === false &&
                showSearchConflict === false &&
                sendMacro === true ? (
                  <div className="card-bodyqqqq">
                    <div className="form-row">
                      <input
                        type="checkbox"
                        id="sendMacro"
                        name="sendMacro"
                        className="form-controlx"
                        defaultChecked={this.state.sendMacro}
                        onClick={this.handleCheckBoxCheck}
                      />{" "}
                      {t("zqwFlowvtx2_x")}
                    </div>
                    <div className="form-rowx">
                      <ZendMacrosSendForm
                        backTicketId={backTicketId}
                        neoemail={neoemail}
                        client_id={"" + client_id}
                        client_name={"" + client_name}
                        invoiceId={invoiceId}
                      />
                      send macrooooooooooooooooooooooooooooooooo
                    </div>
                    <div className="form-group" style={{ display: "block" }}>
                      <br />
                      <br /> <br /> &nbsp;{" "}
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={this.handleConfActions4}
                      >
                        {t("Cancel")}
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  getClientTransactionList = (clientIdNone) => {
    //const serachObj={search:search, searchEmail:""};
    const { t } = this.props;
    const clientId = this.state.idClient;
    const listOfTickets = this.state.listOfTickets;
    if (listOfTickets.length == 0 && clientId > 0) {
      const serachObj = { clientId: clientId };
      const this00 = this;
      this.setState({ ["isLoaderOpen"]: true, ["errorTransacMessage"]: "" });
      Promise.resolve(this.props.getClientTransactionsFullInfo(serachObj))
        .then((response) => {
          this.setState({
            ["listOfTickets"]: response.NeoVentesOne,
            ["isLoaderOpen"]: false,
          });
          return response;
        })
        .catch(function (error) {
          const errObj = error?.response?.data ?? {};
          let errMessage =
            errObj.data !== ""
              ? "Erreur " + " \n" + errObj.data
              : "Erreur " + " \n" + errObj.statusText;
          if (errObj.status === undefined) {
            errMessage += "\n " + t("zqwNVFrmEl14_x");
          }
          this00.setState({
            ["isLoaderOpen"]: false,
            ["errorTransacMessage"]: errMessage,
          });
          console.log(error);
        });
    }
  };

  handleConfActions4 = (event) => {
    this.setState({
      ["showSearchConflict"]: false,
      ["showB2bLead"]: false,
      ["showRendevousBooking"]: false,
      ["sendMacro"]: false,
      ["mergeTicketNote"]: false,
    });
  };

  AutocompleteClient = (props) => {
    //AutocompleteClient (props){
    const { t } = this.props;
    const listofSuggestion = this.state.listOfSuggestion;
    const valueClientSearch = this.state.valueClientSearch;
    const inputValueClientSearch = this.state.inputValueClientSearch;
    //const selectedIdSuggestion=this.state.selectedIdSuggestion;
    const propstep = props.propstep;
    const this2 = this;

    return (
      <Autocomplete
        value={valueClientSearch}
        inputValue={inputValueClientSearch}
        onInputChange={(event, newInputValue) => {
          this.setState({ ["inputValueClientSearch"]: newInputValue });
          if (newInputValue.length > 2) {
            setTimeout(() => {
              this.getClientSearchSuggestion(newInputValue);
            }, 0);
          }
        }}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            setTimeout(() => {
              this.setState({ ["valueClientSearch"]: newValue });
            });
          } else if (newValue && newValue.inputValue) {
            this.setState({ ["valueClientSearch"]: newValue.inputValue });
          } else {
            //setValue(newValue);
            this.setState({
              ["valueClientSearch"]:
                newValue.firstname +
                " " +
                newValue.lastname +
                " " +
                newValue.email,
            });

            this.setState({ ["clientInfo"]: newValue });
            const clientInfo = {
              id_clients: newValue.id,
              idClient: newValue.id,
              firstname: newValue.firstname,
              lastname: newValue.lastname,
              cell: newValue.cell,
              terms: newValue.terms,
              active: newValue.active,
              email: newValue.email,
              id_users: parseInt(newValue.id_users),
              language: newValue.language,
              push: newValue.push,
              id_zendesk: newValue.id_zendesk,
              sms: newValue.sms,
              stripe_id: newValue.stripe_id,
              occupation: newValue.occupation,
            };
            this.setState(clientInfo); //xaza  this.setState({ clientInfo });
            if (propstep == 1) {
              this.setState({ ["globalStepWorkflow"]: 3, ["workflowType"]: 3 });
            }
            let existedTF = true;
            if (
              (newValue.id_zendesk === null ||
                newValue.id_zendesk === "" ||
                newValue.id_zendesk === "0") &&
              (newValue.stripe_id === null || newValue.stripe_id === "")
            ) {
              existedTF = false;
            }
            this.setState({
              ["existedClientRO"]: existedTF,
              ["emailDoesNotExist"]: 1,
            });

            this.clientFullInfoValidate();
            //get client Address
            const clientId = newValue.id;
            setTimeout(() => {
              this.getClientFullAddress(clientId);
            }, 0);
          }
        }}
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        // options={listofSuggestion.map((option) => option.firstname+' '+option.lastname+' '+option.email)}
        options={listofSuggestion}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.firstname + " " + option.lastname + " " + option.email;
        }}
        renderOption={(option) =>
          option.firstname + " " + option.lastname + " " + option.email
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("zqwNVFrmEl80_x")}
            margin="normal"
            variant="outlined"
            InputProps={{ ...params.InputProps, type: "search" }}
          />
        )}
      />
    );
  };

  AutocompleteClientB2B = (props) => {
    //AutocompleteClient (props){
    const { t } = this.props;
    const listofSuggestion = this.state.listOfSuggestion2;
    const valueClientSearch = this.state.valueClientSearch2;
    const inputValueClientSearch = this.state.inputValueClientSearch2;
    //const selectedIdSuggestion=this.state.selectedIdSuggestion;
    const propstep = props.propstep;
    const this2 = this;
    let localSearch = "";

    return (
      <Autocomplete
        value={valueClientSearch}
        inputValue={inputValueClientSearch}
        onInputChange={(event, newInputValue) => {
          localSearch = newInputValue;
          this.setState({ ["inputValueClientSearch2"]: newInputValue });
          if (newInputValue.length > 2) {
            setTimeout(() => {
              this.getClientSearchSuggestion2(newInputValue);
            }, 0);
          }
        }}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              this.setState({ ["valueClientSearch2"]: newValue });
            });
          } else if (newValue && newValue.inputValue) {
            this.setState({ ["valueClientSearch2"]: newValue.inputValue });
          } else {
            const b2b = this.state.b2b;
            const b2b2 = this.myB2Bsetter(newValue);
            this.setState({ ...b2b, ["b2b"]: b2b2 });
            this.setState({
              ["b2bIDCustomer"]: newValue.id,
              ["b2bIDCustomerContract"]: newValue.id_b2b_list,
            });
            //this.setState({ ["valueClientSearch2"]: (newValue.firstname+' '+newValue.lastname+' '+newValue.email)});
            this.setState({
              ["valueClientSearch2"]:
                newValue.firstname +
                " " +
                newValue.lastname +
                " " +
                newValue.id_external +
                " " +
                newValue.postal_code +
                " " +
                newValue.email,
            });
            if (newValue.id_clients === null || newValue.id_clients === "") {
              const clientInfo = {
                id_clients: newValue.id_clients,
                idClient: newValue.id_clients,
                firstname: newValue.firstname,
                lastname: newValue.lastname,
                cell: newValue.tel,
                terms: newValue.terms,
                active: "1",
                email: newValue.email,
                push: 1,
                sms: 1,
              };
              this.setState(clientInfo); //xaza  this.setState({ clientInfo });

              const clientAddress = {
                address: newValue.address,
                city: newValue.city,
                province: newValue.province,
                cp: newValue.postal_code,
                country: "Canada",
              };

              this.setState(clientAddress);
            } else {
              const clientId = newValue.id_clients; //this.state.idClient;
              const serachObj = {
                search: "",
                searchEmail: "",
                clientId: clientId,
              };
              Promise.resolve(this.props.getClientSearch(serachObj))
                .then((response) => {
                  const newValue = response.NeoClientsOne;

                  this.setState({ ["clientInfo"]: newValue });
                  const clientInfo = {
                    id_clients: newValue.id,
                    idClient: newValue.id,
                    firstname: newValue.firstname,
                    lastname: newValue.lastname,
                    cell: newValue.cell,
                    terms: newValue.terms,
                    active: newValue.active,
                    email: newValue.email,
                    id_users: parseInt(newValue.id_users),
                    language: newValue.language,
                    push: newValue.push,
                    id_zendesk: newValue.id_zendesk,
                    sms: newValue.sms,
                    stripe_id: newValue.stripe_id,
                    occupation: newValue.occupation,
                  };
                  this.setState(clientInfo); //xaza  this.setState({ clientInfo });
                  if (propstep == 1) {
                    this.setState({
                      ["globalStepWorkflow"]: 3,
                      ["workflowType"]: 3,
                    });
                  }

                  this.setState({
                    ["existedClientRO"]: true,
                    ["emailDoesNotExist"]: 1,
                  });

                  this.clientFullInfoValidate();

                  //get client Address
                  const clientId = newValue.id;
                  setTimeout(() => {
                    this.getClientFullAddress(clientId);
                  }, 0);

                  return response;
                })
                .catch((e) => {
                  console.error(e);
                });
            }
            this.setState({ ["globalStepWorkflow"]: 2 });
          }
        }}
        freeSolo
        id="free-solo-22x-demo"
        disableClearable
        options={listofSuggestion}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          localSearch = this.state.inputValueClientSearch2;
          return (
            option.firstname +
            " " +
            option.lastname +
            " " +
            option.id_external +
            " " +
            option.postal_code +
            " " +
            option.email +
            " " +
            option.tel +
            " " +
            option.subcontractName +
            " " +
            localSearch
          );
        }}
        renderOption={(option) =>
          option.firstname +
          " " +
          option.lastname +
          " " +
          option.id_external +
          " " +
          option.postal_code +
          " " +
          option.email +
          " " +
          option.subcontractName
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("zqwTNVFtxt33_x")}
            margin="normal"
            variant="outlined"
            InputProps={{ ...params.InputProps, type: "search" }}
          />
        )}
      />
    );
  };

  myB2Bsetter = (newObj) => {
    const baseB2B0 = {
      id: 0,
      id_b2b_list: "",
      e_id_b2b_list: false,
      id_external: "",
      e_id_external: false,
      name_id_external: "",
      e_name_id_external: false,
      firstname: "",
      e_firstname: false,
      lastname: "",
      e_lastname: false,
      email: "",
      e_email: false,
      tel: "",
      e_tel: false,
      start_date: "",
      e_start_date: false,
      expiry_date: "",
      e_expiry_date: false,
      address: "",
      e_address: false,
      city: "",
      e_city: false,
      province: "QC",
      e_province: false,
      postal_code: "",
      e_postal_code: false,
      raw_data: "",
      e_raw_data: false,
      date_created: "",
      e_date_created: false,
      date_updated: "",
      e_date_updated: false,
      status: "",
      e_status: false,
      dob: "",
      e_dob: false,
      id_clients: "",
      e_id_clients: false,
      company_name: "",
    };
    const baseB2B = { ...baseB2B0, ...newObj };
    return baseB2B;
  };

  getClientSearchSuggestion2(search) {
    const valueClientSearch = this.state.valueClientSearch2;
    const inputValueClientSearch = this.state.inputValueClientSearch2;
    if (valueClientSearch === inputValueClientSearch) {
      return false;
    }
    const serachObj = { search: search, searchEmail: "" };
    Promise.resolve(this.props.getB2BClientSearch(serachObj))
      .then((response) => {
        let showCreateBtn = false;
        if (response.NeoClientsOne.length === 0) {
          showCreateBtn = true;
        }
        this.setState({
          ["listOfSuggestion2"]: response.NeoClientsOne,
          ["showCreateBtnManual"]: showCreateBtn,
        });
        return response;
      })
      .catch((e) => {
        console.error(e);
      });
  }

  ProductsOnInvoice = (event) => {
    const { t } = this.props;
    const taxInfo = this.state.taxInfo;
    let TAX1 = parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    let TAX2 = parseFloat(taxInfo["tax2x"]); //0.05; //tax2
    //const TAX_TOTAL=TAX1+TAX2; //0.14975;
    const TAX1_NAME = taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME = taxInfo["tax2_name"]; //"TPS";
    const TAX1l = taxInfo["tax1"]; //tax1 0.9975
    const TAX2l = taxInfo["tax2"];
    let TAX1lx = taxInfo["tax1"]; //tax1 0.9975
    let TAX2lx = taxInfo["tax2"];
    let TAX12 = 0;
    let TAX21 = 0;
    if (TAX2_NAME === "0") {
      TAX1lx = parseFloat(TAX1l) + parseFloat(TAX2l);
      TAX1 = TAX1 + TAX2;
      TAX12 = TAX1 + TAX2;
      TAX2 = 0;
    }
    if (TAX1_NAME === "0") {
      TAX2lx = parseFloat(TAX1l) + parseFloat(TAX2l);
      TAX2 = TAX2 + TAX1;
      TAX21 = TAX1 + TAX2;
      TAX1 = 0;
    }
    const TAX_TOTAL = TAX1 + TAX2; //0.14975;
    const arrProd = [6, 8, 9];
    let productOptions = [];
    let productList =
      this.productsList.products.length > 0 &&
      this.productsList.products.map((item, i) => {
        //productOptions.push({ value: item.id, label: item.titre });
        if (item.type.indexOf("hidden") < 0 && item.titre !== "") {
          productOptions.push({
            value: item.id,
            label: item.titre + " (" + item.tarif + ")",
          });
        }
        return null;
      }, this);

    const ProductExtraOptionCtrl2 = this.state.productExtraOptionCtrl2;
    const grandTotal = this.state.grandTotal;
    const orderBruteSubTotal = this.state.orderBruteSubTotal;
    const orderTVQ = this.state.orderTVQ; //shows at the end
    const orderTPS = this.state.orderTPS; //shows at the end
    let productCart = this.state.productCart;
    let subTotal = 0;
    let totTagTotOptions = 0;
    let totProd = 0;
    let discountFreetot = 0;
    const listOfCurrentProducts =
      productCart.length > 0 &&
      productCart.map((itemState, i) => {
        const isDelete = itemState.isdelete;
        const myItemtxt = "" + itemState.id;
        const index = i;
        if (myItemtxt.indexOf("98989") < 0 && isDelete === undefined) {
          const item = this.getProductChange(itemState.id);
          const optionsEntries =
            item.options != undefined && !arrProd.includes(item.id)
              ? Object.entries(item.options)
              : [];
          const cartEntries = Object.entries(item.cart);

          const this00 = this;

          let optionsProd = productCart[index].options;
          let selPage = productCart[index].pages;
          let perPage = productCart[index].perPage;
          if (selPage <= 1) {
            selPage = 1;
          }

          //totTagTotOptions=((selPage-1)*perPage);
          totTagTotOptions = productCart[index].totOptions;
          let discountFree = productCart[index].discountFree * 1;
          let discountEnabled = productCart[index].discountEnabled;
          let discountFree2 =
            productCart[index].discountFree2 * 1 > 0
              ? productCart[index].discountFree2 * 1
              : 0;
          const productPrice =
            itemState.productPrice2 === 0
              ? itemState.productPrice
              : itemState.productPrice2;
          let tarif = productPrice * 1 + totTagTotOptions;
          let tarifPrix = productPrice;
          const productPriceTaxOnly = (tarif * 1 * TAX_TOTAL).toFixed(2);
          const productPriceTotal = (
            tarif * 1 * TAX1 +
            tarif * 1 * TAX2 +
            tarif * 1 +
            discountFree
          ).toFixed(2);
          const orderTVQ = (tarif * 1 * TAX1).toFixed(2);
          const orderTPS = (tarif * 1 * TAX2).toFixed(2);
          const orderServices = totTagTotOptions;
          const orderSubtotals = (tarif * 1 + discountFree).toFixed(2);

          const indexProd = index; //productCart.length;
          const apartir = item.apartir;
          let apartirUsed = false;
          const productExtraOptionCtrl2 = optionsEntries.map(function (
            elem,
            index
          ) {
            const [txtLabel, amount] = elem;

            const groupVal =
              "" + indexProd + "-" + item.id + "-" + txtLabel + "-" + amount;
            let titre = "";
            for (const [txtLabel2, labelValue] of cartEntries) {
              if (txtLabel === txtLabel2) {
                titre = labelValue;
                break;
              }
            }
            if (titre === "" && apartir === false) {
              return <span key={index} />;
            }
            if (apartir === true) {
              //apartirUsed=true;
              if (apartirUsed === false) {
                apartirUsed = true;
                return (
                  <p key={index}>
                    {t("zqwNVFrmEl81_x")}:<br />
                    <input
                      type="number"
                      min="1"
                      step="1"
                      defaultValue={selPage}
                      data-value={groupVal}
                      data-perpage={optionsProd.perPage}
                      data-oraxpage={"1001"}
                      data-minimum={optionsProd.minimum}
                      data-maximum={optionsProd.maximum}
                      onChange={(eventx) => this00.setPageDocForProduct(eventx)}
                    />{" "}
                    {t("zqwNVFrmEl82_x")} (xn x ${amount})
                  </p>
                );
              } else {
                return <span key={index} />;
              }
            } else {
              let isSelected = false;
              const txtLabelx = txtLabel;
              const txtLabelzx = optionsProd.id;
              const indz = this00.indexOfElement(optionsProd, txtLabel, amount);
              if (indz > -1) {
                isSelected = true;
              }

              return (
                <p key={index}>
                  <input
                    type="checkbox"
                    value={groupVal}
                    defaultChecked={isSelected}
                    onChange={(eventx) =>
                      this00.setExtraServiceForProduct(eventx)
                    }
                  />{" "}
                  {titre} (${amount})
                </p>
              );
            }
          });

          const rId = itemState.randcolor;
          const divBlock = (
            <div key={indexProd} className="marginr md-4">
              <div className="card card-default neocardx">
                <div className={`card-header neocard${rId}`}>
                  <h6 style={{color: "black"}} className="m-0">{item.titre}</h6>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-10">
                      <div className="row" style={{ display: "block" }}>
                        <div className="neoinline50">
                          <span>{t("zqwNVFrmEl83_x")} (taxable)</span>
                        </div>
                        <div className="neoinline50l">                
                          <input
                            type="number"
                            step="0.1"
                            // min="1"
                            name="neoPrice"
                            value={tarifPrix}
                            ref={this.priceRef}
                            data-value={indexProd + "-" + item.id}
                            onChange={(e) => this00.setNewPriceForProduct(e)}
                            onBlur={(e) => this00.setNewPriceForProduct(e, true)}
                            className="form-control col-md-8x"
                            size="30"
                            style={{ display: "inline", width: "100px" }}
                          />
                          <span></span>
                        </div>
                      </div>
                      {discountFree2 === 0 && discountEnabled === true ? (
                        <div className="row" style={{ display: "block" }}>
                          <div className="neoinline50 text-right">
                            <span>Gouv/Gov (non taxable)</span>
                          </div>
                          <div className="neoinline50l">
                            <input
                              type="number"
                              step="0.1"
                              min="1"
                              name="neoDiscountFree"
                              defaultValue={discountFree}
                              data-value={indexProd + "-" + item.id}
                              onChange={(eventx) =>
                                this00.setNewTaxfreeForProduct(eventx)
                              }
                              className="form-control col-md-8x"
                              size="30"
                              style={{ display: "inline", width: "100px" }}
                            />
                            {/* <span>{discountFree}</span> */}$
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {discountFree2 > 0 && discountEnabled === true ? (
                        <div className="row" style={{ display: "block" }}>
                          <div className="neoinline50 text-right">
                            <span>Gouv/Gov (non taxable)</span>
                          </div>
                          <div className="neoinline50l">
                            {" "}
                            <span>{discountFree}</span>$
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="row" style={{ display: "block" }}>
                        <div className="neoinline50 text-right">
                          <span>{t("zqwNVFrmEl84_x")}</span>
                        </div>
                        <div className="neoinline50l">
                          {" "}
                          <span>{orderServices}</span>$
                        </div>
                      </div>
                      <div className="row" style={{ display: "block" }}>
                        <div className="neoinline50">
                          <span>{t("zqwNVFrmEl57_x")}</span>
                        </div>
                        <div className="neoinline50l">
                          {" "}
                          <span>{orderSubtotals}</span>$
                        </div>
                      </div>

                      {TAX1_NAME !== "0" ? (
                        <div
                          className="row"
                          id="tax1_block"
                          style={{ display: "block" }}
                        >
                          <div className="neoinline50 text-right">
                            <span>{TAX1_NAME !== "0" ? TAX1_NAME : ""}</span>(
                            <span>{TAX1lx}</span>%)
                          </div>
                          <div className="neoinline50l">
                            {" "}
                            <span>{orderTVQ}</span>$
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {TAX2_NAME !== "0" ? (
                        <div
                          className="row"
                          id="tax2_block"
                          style={{ display: "block" }}
                        >
                          <div className="neoinline50">
                            <span>{TAX2_NAME !== "0" ? TAX2_NAME : ""}</span> (
                            <span>{TAX2lx}</span>%)
                          </div>
                          <div className="neoinline50l">
                            {" "}
                            <span>{orderTPS}</span>$
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      <div
                        className="row"
                        id="tax2_block"
                        style={{ display: "block" }}
                      >
                        <div className="neoinline50">
                          <span>{t("zqwNVFrmEl85_x")}</span>
                        </div>
                        <div className="neoinline50l">
                          {" "}
                          <b>
                            <span>{productPriceTaxOnly}</span>
                          </b>
                          $
                        </div>
                      </div>
                      <div className="row">
                        <div className="neoinline30"> </div>
                        <div className="neoinline50">
                          <hr />
                        </div>
                      </div>
                      <div
                        className="row"
                        id="tax2_block"
                        style={{ display: "block" }}
                      >
                        <div className="neoinline50">
                          <span>{t("zqwNVFrmEl61_x")}</span>
                        </div>
                        <div className="neoinline50l">
                          {" "}
                          <span>{productPriceTotal}</span>$
                        </div>
                      </div>
                    </div>
                  </div>
                  {(itemState?.id === 117 || this.state.salePrecision) && 
                    <div>
                      <span style={{fontWeight: "600"}}>{genLang({en: "Precision of sale", fr: "Précision de vente"})}</span>
                      <Input type="textarea" value={this.state.salePrecision} onChange={(e) => this.setState({salePrecision: e.target?.value ?? ""})}/>
                    </div>
                  }
                  <br />
                  {optionsEntries.length > 0 && cartEntries.length > 0 ? (
                    <>
                      {t("zqwNVFrmEl86_x")}: <hr />
                    </>
                  ) : (
                    ""
                  )}

                  {productExtraOptionCtrl2}
                  <div className="ovalItemPrice">
                    <span>{productPriceTotal}</span>$
                  </div>
                  <p style={{ display: "none" }}>
                    <input
                      type="checkbox"
                      value={indexProd + "-" + item.id}
                      defaultChecked
                      onChange={(eventx) => this00.setTicketForProduct(eventx)}
                    />{" "}
                    {t("zqwNVFrmEl87_x")}
                  </p>

                  <a
                    href="#"
                    className="btn btn-danger"
                    data-prodindex={indexProd}
                    onClick={this.handleRemoveProductItem}
                  >
                    {t("zqwNVFrmEl88_x")}
                  </a>
                </div>
              </div>
            </div>
          );

          return divBlock;
        }

        return null;
      }, this);

    return (
      <>
        <div className="form-group">
          <label htmlFor="name">{t("zqwNVFrmEl41_x")}</label>
          <Select
            isDisabled={!this.state.groups.length || this.state.productAvailableLoading !== false}
            isLoading={!this.state.groups.length || this.state.productAvailableLoading !== false}
            options={productOptions}
            onChange={this.handleProductChange2}
          />
          <span style={{ display: "inline" }}>
            <a
              href="#"
              data-id={0}
              onClick={this.getAllProductsList}
              title="Rafraichir liste de produit/refresh product list"
            >
              <AutorenewIcon />
            </a>
          </span>
        </div>
        <div className="card card-primary card-outline">
          <div className="card-header">
            <h5 className="m-0">{t("zqwNVFrmEl89_x")}</h5>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="form-group">
                <div className="input-group">
                  {/* <label htmlFor="province">Taxe de {t('zqwprovince_x')} *</label>  */}
                  <label htmlFor="province">
                    {t("zqwNVFrmEl90_x")} {t("zqwprovince_x")} *
                  </label>{" "}
                  &nbsp;
                  <select
                    name="province"
                    id="province"
                    value={this.state.province}
                    onChange={this.handleChangeProvince}
                    onBlur={this.handleFocusout}
                    className={
                      this.state.e_province
                        ? "form-control fieldErr"
                        : "form-control"
                    }
                    placeholder={t("zqwprovince_x")}
                  >
                    <option value="AB">Alberta</option>
                    <option value="BC">British Columbia</option>
                    <option value="MB">Manitoba</option>
                    <option value="NB">New Brunswick</option>
                    <option value="NL">Newfoundland and Labrador</option>
                    <option value="NT">Northwest Territories</option>
                    <option value="NS">Nova Scotia</option>
                    <option value="NU">Nunavut</option>
                    <option value="ON">Ontario</option>
                    <option value="PE">Prince Edward Island</option>
                    <option value="QC">Quebec</option>
                    <option value="SK">Saskatchewan</option>
                    <option value="YT">Yukon</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row">{listOfCurrentProducts}</div>
            <div className="card-text">
              <div className="neoinline40">
                <div className="row" id="tax2_blockqqqqq2">
                  <div className="neoinline50">
                    <span>{t("zqwNVFrmEl57_x")}</span>
                  </div>
                  <div className="neoinline50l">
                    {" "}
                    <span>{orderBruteSubTotal}</span>$
                  </div>
                </div>

                {TAX1_NAME !== "0" ? (
                  <div className="row" id="tax1_blockqqqq">
                    <div className="neoinline50">
                      <span>{TAX1_NAME !== "0" ? TAX1_NAME : ""}</span> (
                      <span>{TAX1lx}</span>%)
                    </div>
                    <div className="neoinline50l">
                      {" "}
                      <span>{orderTVQ}</span>$
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {TAX2_NAME !== "0" ? (
                  <div className="row" id="tax2_blockqqqqq">
                    <div className="neoinline50">
                      <span>{TAX2_NAME !== "0" ? TAX2_NAME : ""}</span> (
                      <span>{TAX2lx}</span>%)
                    </div>
                    <div className="neoinline50l">
                      {" "}
                      <span>{orderTPS}</span>$
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="row" id="tax2_blockqqqqq3">
                  <div className="neoinline50">
                    <span>{t("zqwNVFrmEl61_x")}</span>
                  </div>
                  <div className="neoinline50l">
                    {" "}
                    <span>
                      <b>{grandTotal}</b>
                    </span>
                    $
                  </div>
                  <div className="ovalTotalPrice">
                    {" "}
                    &nbsp; <span>{t("zqwNVFrmEl91_x")}</span>{" "}
                    <span>
                      <b>{grandTotal}</b>
                    </span>
                    $ &nbsp;{" "}
                  </div>
                </div>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-default"
              onClick={this.handlePreviousStep}
            >
              {t("zqwNVFrmEl92_x")}
            </button>{" "}
            &nbsp;
            <a
              href="#"
              className="btn btn-primary"
              onClick={this.handleProductNextStep}
            >
              {t("zqwNVFrmEl93_x")}
            </a>
            <a
              href="#"
              className="btn btn-warning float-right"
              onClick={this.restartOver}
            >
              {t("zqwNVFrmEl94_x")}
            </a>
          </div>
        </div>
      </>
    );
  };

  handleFakeEmailGen = (event) => {
    const vvv = new Date().getTime();
    let fkid = Math.floor(Math.random() * 99 + 50);
    const dd = new Date();
    const ddStr =
      "" + dd.getFullYear() + "" + dd.getMonth() + "" + dd.getDate();
    const fakemail = "" + ddStr + "" + fkid + "@neolegal.app";
    this.setState({ ["email"]: fakemail });
    this.showToast("Nouveau/new email " + fakemail, "success");
    return false;
  };

  CustomerInfoAddress = (event) => {
    const { t } = this.props;

    const ProductExtraOptionCtrl2 = this.state.productExtraOptionCtrl2;

    const clientInfo = this.state.clientInfo;

    if (clientInfo != null) {
    }
    const firstLetter = /(?!.*[DFIOQU])[A-VXY]/i;
    const letter = /(?!.*[DFIOQU])[A-Z]/i;
    const digit = /[0-9]/;
    const mask = [firstLetter, digit, letter, " ", digit, letter, digit];
    const AutocompleteClient = this.AutocompleteClient;
    const AutocompleteClientB2B = this.AutocompleteClientB2B;
    const idClient = this.state.idClient;
    const idAddress = this.state.idAddress;
    const language = this.state.language;
    const compoKey =
      idClient === "0" ? "versGoooo" + idClient : "vx" + idClient + idAddress;
    //const compoKey=idClient==="0"?"versGoooo"+idClient+language:"vx"+idClient+idAddress+language;

    const switchChangeChkBox = this.handleChangeChkBox;
    const this0 = this;
    //const language=this.state.language;
    //const rndKey=Math.floor(Math.random()*100);
    const globalStepWorkflow = this.state.globalStepWorkflow;
    const email = this.state.email;
    const id_zendesk = this.state.id_zendesk;
    const stripe_id = this.state.stripe_id;

    return (
      <div className="card card-primary card-outline" key={compoKey}>
        <div className="card-header">
          <h5 className="m-0">{t("zqwNVFrmEl95_x")}</h5>
        </div>
        <div className="card-body">
          <div className="md-8">
            <AutocompleteClient key={91090} propstep={2} />
          </div>
          <div className="md-8">
            <AutocompleteClientB2B key={91000} propstep={2} />
          </div>
          <br />
          <div className="card-text">
            <form onSubmit={this.handleSubmitClientInfo} autoComplete="off">
              <div className="form-group" style={{ display: "none" }}>
                <label htmlFor="id">{t("zqwid_x")}</label>
                <input
                  type="text"
                  name="idClient"
                  id="idClient"
                  defaultValue={this.state.id}
                  onChange={this.handleChange}
                  onBlur={this.handleFocusout}
                  className={
                    this.state.e_id ? "form-control fieldErr" : "form-control"
                  }
                  placeholder={t("zqwid_x")}
                />
                {this.state.e_id && (
                  <span className="text-danger">
                    {FIELD_VALIDATION["id"].message}
                  </span>
                )}
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="firstname">{t("zqwfirstname_x")} *</label>
                  <input
                    type="text"
                    name="firstname"
                    id="firstname"
                    defaultValue={this.state.firstname}
                    onChange={this.handleChange}
                    onBlur={this.handleFocusout}
                    className={
                      this.state.e_firstname
                        ? "form-control fieldErr"
                        : "form-control"
                    }
                    placeholder={t("zqwfirstname_x")}
                    readOnly={this.state.existedClientRO}
                  />
                  {this.state.e_firstname && (
                    <span className="text-danger">
                      {FIELD_VALIDATION["firstname"].message}
                    </span>
                  )}
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="lastname">{t("zqwlastname_x")} *</label>
                  <input
                    type="text"
                    name="lastname"
                    id="lastname"
                    defaultValue={this.state.lastname}
                    onChange={this.handleChange}
                    onBlur={this.handleFocusout}
                    className={
                      this.state.e_lastname
                        ? "form-control fieldErr"
                        : "form-control"
                    }
                    placeholder={t("zqwlastname_x")}
                    readOnly={this.state.existedClientRO}
                  />
                  {this.state.e_lastname && (
                    <span className="text-danger">
                      {FIELD_VALIDATION["lastname"].message}
                    </span>
                  )}
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="company">
                  {t("zqwcompany_x")} (Facultatif)
                </label>
                <input
                  type="text"
                  name="company"
                  id="company"
                  defaultValue={this.state.company}
                  onChange={this.handleChange}
                  onBlur={this.handleFocusout}
                  className={
                    this.state.e_company
                      ? "form-control fieldErr"
                      : "form-control"
                  }
                  placeholder={t("zqwcompany_x")}
                />
                {this.state.e_company && (
                  <span className="text-danger">
                    {FIELD_VALIDATION["company"].message}
                  </span>
                )}
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="email">
                    {t("zqwemail_x")} *<span> </span> &nbsp;
                    {globalStepWorkflow === 3 &&
                    (email === null || email === "") ? (
                      <span style={{ height: "12px" }}>
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <Typography color="inherit">
                                Email Client
                              </Typography>
                              <b>{t("zqwNDICItxl6_x")}</b> <br />
                              {t("zqwNDICItxl7_x")}
                            </React.Fragment>
                          }
                        >
                          <a href="#" onClick={this.handleFakeEmailGen}>
                            <ContactMailIcon />
                          </a>
                        </HtmlTooltip>
                      </span>
                    ) : (
                      ""
                    )}
                  </label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    defaultValue={this.state.email}
                    onChange={this.handleChange}
                    onBlur={this.handleChangeEmailFocus}
                    className={
                      this.state.e_email
                        ? "form-control fieldErr"
                        : "form-control"
                    }
                    placeholder={t("zqwemail_x")}
                    readOnly={this.state.existedClientRO}
                  />
                  {this.state.e_email && (
                    <span className="text-danger">
                      {FIELD_VALIDATION["email"].message}
                    </span>
                  )}
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="cell">{t("zqwcell_x")} *</label>
                  {/* <input type="text" name="cell" id="cell" defaultValue={this.state.cell} onChange={this.handleChange} onBlur={this.handleFocusout}
                      className={this.state.e_cell ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwcell_x')} />  */}
                  <InputMask
                    name="cell"
                    mask="(999) 999-9999"
                    onChange={this.handleChange}
                    value={this.state.cell}
                    onBlur={this.handleFocusout}
                    className={
                      this.state.e_cell
                        ? "form-control fieldErr"
                        : "form-control"
                    }
                    placeholder={t("zqwcell_x")}
                  />
                  {this.state.e_cell && (
                    <span className="text-danger">
                      {FIELD_VALIDATION["cell"].message}
                    </span>
                  )}
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="language">{t("zqwlanguage_x")} *</label>
                <select
                  name="language"
                  id="language"
                  defaultValue={language}
                  onChange={this.handleChange}
                  onBlur={this.handleFocusout}
                  className={
                    this.state.e_language
                      ? "form-control fieldErr"
                      : "form-control"
                  }
                  placeholder={t("zqwlanguage_x")}
                >
                  <option value="">{t("zqwNVFrmEl96_x")}...</option>
                  <option value="fr">{t("zqwNVFrmEl97_x")}</option>
                  <option value="en">{t("zqwNVFrmEl98_x")}</option>
                </select>
                {this.state.e_language && (
                  <span className="text-danger">
                    {FIELD_VALIDATION["language"].message}
                  </span>
                )}
              </div>

              <div className="form-group">
                <div className="checkbox-inline">
                  <label>
                    {" "}
                    <Switch
                      checked={this.state.sms === "1" ? true : false}
                      onChange={(e, c) => {
                        this0.setState({
                          ["sms"]: e.target.checked ? "1" : "0",
                        });
                      }}
                      name="sms"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      color="primary"
                      value={this.state.sms}
                    />
                    &nbsp; {t("zqwNVFrmEl99_x")}
                  </label>
                </div>
                {this.state.e_sms && (
                  <span className="text-danger">
                    {FIELD_VALIDATION["sms"].message}
                  </span>
                )}
              </div>

              <div className="form-row neobgGA">
                <div className="form-group col-md-6">
                  <GoogleAddressSearch
                    parentCallback={this.callbackFunction}
                    placeholder={t("zqwNVFrmEl100_x")}
                    searchOptions={this.searchOptions}
                  />
                </div>
                <div className="form-group col-md-4">
                  <select
                    name="countryTuner"
                    defaultValue={"ca"}
                    onChange={this.handleCountryTuner}
                    className={"form-control"}
                    placeholder={t("zqwNVFrmEl103_x")}
                  >
                    <option value="ca">{t("zqwNVFrmEl101_x")} </option>
                    <option value="us">{t("zqwNVFrmEl102_x")}</option>
                    <option value="fr">{t("zqwNVFrmEl104_x")}</option>
                    <option value="">{t("zqwNVFrmEl105_x")}</option>
                  </select>
                </div>
                <div className="form-group col-md-2">
                  <Tooltip title={t("zqwNVFrmEl106_x")} aria-label="add">
                    <a href="#" onClick={this.openZipCodeAddressSetter}>
                      <DraftsIcon />
                    </a>
                  </Tooltip>
                </div>
              </div>

              <div className="form-group" style={{ display: "none" }}>
                <label htmlFor="id">{t("zqwid_x")}</label>
                <input
                  type="text"
                  name="idAddress"
                  id="idAddress"
                  defaultValue={this.state.idAddress}
                  onChange={this.handleChange}
                  onBlur={this.handleFocusout}
                  className={
                    this.state.e_idAddress
                      ? "form-control fieldErr"
                      : "form-control"
                  }
                  placeholder={t("zqwid_x")}
                />
                {this.state.e_idAddress && (
                  <span className="text-danger">
                    {FIELD_VALIDATION["idAddress"].message}
                  </span>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="address">{t("zqwaddress_x")} *</label>
                <input
                  type="text"
                  name="address"
                  id="address"
                  defaultValue={this.state.address}
                  onChange={this.handleChange}
                  onBlur={this.handleFocusout}
                  className={
                    this.state.e_address
                      ? "form-control fieldErr"
                      : "form-control"
                  }
                  placeholder={t("zqwaddress_x")}
                />
                {this.state.e_address && (
                  <span className="text-danger">
                    {FIELD_VALIDATION["address"].message}
                  </span>
                )}
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="unitsuite">#{t("zqwNVFrmEl107_x")}</label>
                  <input
                    type="text"
                    name="unitsuite"
                    defaultValue={this.state.unitsuite}
                    onChange={this.handleChange}
                    onBlur={this.handleFocusout}
                    className={
                      this.state.e_unitsuite
                        ? "form-control fieldErr"
                        : "form-control"
                    }
                    placeholder={t("zqwNVFrmEl108_x")}
                  />
                  {this.state.e_unitsuite && (
                    <span className="text-danger">
                      {FIELD_VALIDATION["unitsuite"].message}
                    </span>
                  )}
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="city">{t("zqwcity_x")} *</label>
                  <input
                    type="text"
                    name="city"
                    id="city"
                    defaultValue={this.state.city}
                    onChange={this.handleChange}
                    onBlur={this.handleFocusout}
                    className={
                      this.state.e_city
                        ? "form-control fieldErr"
                        : "form-control"
                    }
                    placeholder={t("zqwcity_x")}
                  />
                  {this.state.e_city && (
                    <span className="text-danger">
                      {FIELD_VALIDATION["city"].message}
                    </span>
                  )}
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="country">{t("zqwcountry_x")} *</label>
                <div className="input-group">
                  <select
                    name="countrypays"
                    className={"form-control"}
                    value={this.state.country}
                    onChange={this.handleChangeCountrypays}
                  >
                    <option value="Canada">Canada</option>
                    <option value="Etats-Unis">États-Unis (USA)</option>
                    {/* <option value="USA">Etats-Unis</option> */}
                    <option value="France">France</option>

                    <option value="Afghanistan">Afghanistan</option>
                    <option value="Afrique_Centrale">Afrique_Centrale</option>
                    <option value="Afrique_du_sud">Afrique_du_Sud</option>
                    <option value="Albanie">Albanie</option>
                    <option value="Algerie">Algerie</option>
                    <option value="Allemagne">Allemagne</option>
                    <option value="Andorre">Andorre</option>
                    <option value="Angola">Angola</option>
                    <option value="Anguilla">Anguilla</option>
                    <option value="Arabie_Saoudite">Arabie_Saoudite</option>
                    <option value="Argentine">Argentine</option>
                    <option value="Armenie">Armenie</option>
                    <option value="Australie">Australie</option>
                    <option value="Autriche">Autriche</option>
                    <option value="Azerbaidjan">Azerbaidjan</option>

                    <option value="Bahamas">Bahamas</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="Barbade">Barbade</option>
                    <option value="Bahrein">Bahrein</option>
                    <option value="Belgique">Belgique</option>
                    <option value="Belize">Belize</option>
                    <option value="Benin">Benin</option>
                    <option value="Bermudes">Bermudes</option>
                    <option value="Bielorussie">Bielorussie</option>
                    <option value="Bolivie">Bolivie</option>
                    <option value="Botswana">Botswana</option>
                    <option value="Bhoutan">Bhoutan</option>
                    <option value="Boznie_Herzegovine">
                      Boznie_Herzegovine
                    </option>
                    <option value="Bresil">Bresil</option>
                    <option value="Brunei">Brunei</option>
                    <option value="Bulgarie">Bulgarie</option>
                    <option value="Burkina_Faso">Burkina_Faso</option>
                    <option value="Burundi">Burundi</option>

                    <option value="Caiman">Caiman</option>
                    <option value="Cambodge">Cambodge</option>
                    <option value="Cameroun">Cameroun</option>
                    <option value="Canaries">Canaries</option>
                    <option value="Cap_vert">Cap_Vert</option>
                    <option value="Chili">Chili</option>
                    <option value="Chine">Chine</option>
                    <option value="Chypre">Chypre</option>
                    <option value="Colombie">Colombie</option>
                    <option value="Comores">Colombie</option>
                    <option value="Congo">Congo</option>
                    <option value="Congo_democratique">
                      Congo_democratique
                    </option>
                    <option value="Cook">Cook</option>
                    <option value="Coree_du_Nord">Coree_du_Nord</option>
                    <option value="Coree_du_Sud">Coree_du_Sud</option>
                    <option value="Costa_Rica">Costa_Rica</option>
                    <option value="Cote_d_Ivoire">Côte_d_Ivoire</option>
                    <option value="Croatie">Croatie</option>
                    <option value="Cuba">Cuba</option>

                    <option value="Danemark">Danemark</option>
                    <option value="Djibouti">Djibouti</option>
                    <option value="Dominique">Dominique</option>

                    <option value="Egypte">Egypte</option>
                    <option value="Emirats_Arabes_Unis">
                      Emirats_Arabes_Unis
                    </option>
                    <option value="Equateur">Equateur</option>
                    <option value="Erythree">Erythree</option>
                    <option value="Espagne">Espagne</option>
                    <option value="Estonie">Estonie</option>
                    <option value="Ethiopie">Ethiopie</option>

                    <option value="Falkland">Falkland</option>
                    <option value="Feroe">Feroe</option>
                    <option value="Fidji">Fidji</option>
                    <option value="Finlande">Finlande</option>
                    <option value="France">France</option>

                    <option value="Gabon">Gabon</option>
                    <option value="Gambie">Gambie</option>
                    <option value="Georgie">Georgie</option>
                    <option value="Ghana">Ghana</option>
                    <option value="Gibraltar">Gibraltar</option>
                    <option value="Grece">Grece</option>
                    <option value="Grenade">Grenade</option>
                    <option value="Groenland">Groenland</option>
                    <option value="Guadeloupe">Guadeloupe</option>
                    <option value="Guam">Guam</option>
                    <option value="Guatemala">Guatemala</option>
                    <option value="Guernesey">Guernesey</option>
                    <option value="Guinee">Guinee</option>
                    <option value="Guinee_Bissau">Guinee_Bissau</option>
                    <option value="Guinee equatoriale">
                      Guinee_Equatoriale
                    </option>
                    <option value="Guyana">Guyana</option>
                    <option value="Guyane_Francaise ">Guyane_Francaise</option>

                    <option value="Haiti">Haiti</option>
                    <option value="Hawaii">Hawaii</option>
                    <option value="Honduras">Honduras</option>
                    <option value="Hong_Kong">Hong_Kong</option>
                    <option value="Hongrie">Hongrie</option>

                    <option value="Inde">Inde</option>
                    <option value="Indonesie">Indonesie</option>
                    <option value="Iran">Iran</option>
                    <option value="Iraq">Iraq</option>
                    <option value="Irlande">Irlande</option>
                    <option value="Islande">Islande</option>
                    <option value="Israel">Israel</option>
                    <option value="Italie">italie</option>

                    <option value="Jamaique">Jamaique</option>
                    <option value="Jan Mayen">Jan Mayen</option>
                    <option value="Japon">Japon</option>
                    <option value="Jersey">Jersey</option>
                    <option value="Jordanie">Jordanie</option>

                    <option value="Kazakhstan">Kazakhstan</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Kirghizstan">Kirghizistan</option>
                    <option value="Kiribati">Kiribati</option>
                    <option value="Koweit">Koweit</option>

                    <option value="Laos">Laos</option>
                    <option value="Lesotho">Lesotho</option>
                    <option value="Lettonie">Lettonie</option>
                    <option value="Liban">Liban</option>
                    <option value="Liberia">Liberia</option>
                    <option value="Liechtenstein">Liechtenstein</option>
                    <option value="Lituanie">Lituanie</option>
                    <option value="Luxembourg">Luxembourg</option>
                    <option value="Lybie">Lybie</option>

                    <option value="Macao">Macao</option>
                    <option value="Macedoine">Macedoine</option>
                    <option value="Madagascar">Madagascar</option>
                    <option value="Madère">Madère</option>
                    <option value="Malaisie">Malaisie</option>
                    <option value="Malawi">Malawi</option>
                    <option value="Maldives">Maldives</option>
                    <option value="Mali">Mali</option>
                    <option value="Malte">Malte</option>
                    <option value="Man">Man</option>
                    <option value="Mariannes du Nord">Mariannes du Nord</option>
                    <option value="Maroc">Maroc</option>
                    <option value="Marshall">Marshall</option>
                    <option value="Martinique">Martinique</option>
                    <option value="Maurice">Maurice</option>
                    <option value="Mauritanie">Mauritanie</option>
                    <option value="Mayotte">Mayotte</option>
                    <option value="Mexique">Mexique</option>
                    <option value="Micronesie">Micronesie</option>
                    <option value="Midway">Midway</option>
                    <option value="Moldavie">Moldavie</option>
                    <option value="Monaco">Monaco</option>
                    <option value="Mongolie">Mongolie</option>
                    <option value="Montserrat">Montserrat</option>
                    <option value="Mozambique">Mozambique</option>

                    <option value="Namibie">Namibie</option>
                    <option value="Nauru">Nauru</option>
                    <option value="Nepal">Nepal</option>
                    <option value="Nicaragua">Nicaragua</option>
                    <option value="Niger">Niger</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="Niue">Niue</option>
                    <option value="Norfolk">Norfolk</option>
                    <option value="Norvege">Norvege</option>
                    <option value="Nouvelle_Caledonie">
                      Nouvelle_Caledonie
                    </option>
                    <option value="Nouvelle_Zelande">Nouvelle_Zelande</option>

                    <option value="Oman">Oman</option>
                    <option value="Ouganda">Ouganda</option>
                    <option value="Ouzbekistan">Ouzbekistan</option>

                    <option value="Pakistan">Pakistan</option>
                    <option value="Palau">Palau</option>
                    <option value="Palestine">Palestine</option>
                    <option value="Panama">Panama</option>
                    <option value="Papouasie_Nouvelle_Guinee">
                      Papouasie_Nouvelle_Guinee
                    </option>
                    <option value="Paraguay">Paraguay</option>
                    <option value="Pays_Bas">Pays_Bas</option>
                    <option value="Perou">Perou</option>
                    <option value="Philippines">Philippines</option>
                    <option value="Pologne">Pologne</option>
                    <option value="Polynesie">Polynesie</option>
                    <option value="Porto_Rico">Porto_Rico</option>
                    <option value="Portugal">Portugal</option>

                    <option value="Qatar">Qatar</option>

                    <option value="Republique_Dominicaine">
                      Republique_Dominicaine
                    </option>
                    <option value="Republique_Tcheque">
                      Republique_Tcheque
                    </option>
                    <option value="Reunion">Reunion</option>
                    <option value="Roumanie">Roumanie</option>
                    <option value="Royaume_Uni">Royaume_Uni</option>
                    <option value="Russie">Russie</option>
                    <option value="Rwanda">Rwanda</option>

                    <option value="Sahara Occidental">Sahara Occidental</option>
                    <option value="Sainte_Lucie">Sainte_Lucie</option>
                    <option value="Saint_Marin">Saint_Marin</option>
                    <option value="Salomon">Salomon</option>
                    <option value="Salvador">Salvador</option>
                    <option value="Samoa_Occidentales">
                      Samoa_Occidentales
                    </option>
                    <option value="Samoa_Americaine">Samoa_Americaine</option>
                    <option value="Sao_Tome_et_Principe">
                      Sao_Tome_et_Principe
                    </option>
                    <option value="Senegal">Senegal</option>
                    <option value="Seychelles">Seychelles</option>
                    <option value="Sierra Leone">Sierra Leone</option>
                    <option value="Singapour">Singapour</option>
                    <option value="Slovaquie">Slovaquie</option>
                    <option value="Slovenie">Slovenie</option>
                    <option value="Somalie">Somalie</option>
                    <option value="Soudan">Soudan</option>
                    <option value="Sri_Lanka">Sri_Lanka</option>
                    <option value="Suede">Suede</option>
                    <option value="Suisse">Suisse</option>
                    <option value="Surinam">Surinam</option>
                    <option value="Swaziland">Swaziland</option>
                    <option value="Syrie">Syrie</option>

                    <option value="Tadjikistan">Tadjikistan</option>
                    <option value="Taiwan">Taiwan</option>
                    <option value="Tonga">Tonga</option>
                    <option value="Tanzanie">Tanzanie</option>
                    <option value="Tchad">Tchad</option>
                    <option value="Thailande">Thailande</option>
                    <option value="Tibet">Tibet</option>
                    <option value="Timor_Oriental">Timor_Oriental</option>
                    <option value="Togo">Togo</option>
                    <option value="Trinite_et_Tobago">Trinite_et_Tobago</option>
                    <option value="Tristan da cunha">Tristan de cuncha</option>
                    <option value="Tunisie">Tunisie</option>
                    <option value="Turkmenistan">Turmenistan</option>
                    <option value="Turquie">Turquie</option>

                    <option value="Ukraine">Ukraine</option>
                    <option value="Uruguay">Uruguay</option>

                    <option value="Vanuatu">Vanuatu</option>
                    <option value="Vatican">Vatican</option>
                    <option value="Venezuela">Venezuela</option>
                    <option value="Vierges_Americaines">
                      Vierges_Americaines
                    </option>
                    <option value="Vierges_Britanniques">
                      Vierges_Britanniques
                    </option>
                    <option value="Vietnam">Vietnam</option>

                    <option value="Wake">Wake</option>
                    <option value="Wallis et Futuma">Wallis et Futuma</option>

                    <option value="Yemen">Yemen</option>
                    <option value="Yougoslavie">Yougoslavie</option>

                    <option value="Zambie">Zambie</option>
                    <option value="Zimbabwe">Zimbabwe</option>
                  </select>
                  <input
                    type="text"
                    name="country"
                    id="country"
                    value={this.state.country}
                    onChange={this.handleChange}
                    onBlur={this.handleFocusout}
                    className={
                      this.state.e_country
                        ? "form-control fieldErr"
                        : "form-control"
                    }
                    placeholder={t("zqwcountry_x")}
                  />
                  {this.state.e_country && (
                    <span className="text-danger">
                      {FIELD_VALIDATION["country"].message}
                    </span>
                  )}
                </div>
              </div>
              {this.state.country === "Canada" ||
              this.state.country === "Etats-Unis" ? (
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="province">{t("zqwprovince_x")} *</label>
                    {this.state.country === "Canada" ? (
                      <select
                        name="province"
                        id="province"
                        value={this.state.province}
                        onChange={this.handleChangeProvince}
                        onBlur={this.handleFocusout}
                        className={
                          this.state.e_province
                            ? "form-control fieldErr"
                            : "form-control"
                        }
                        placeholder={t("zqwprovince_x")}
                      >
                        <option value="AB">Alberta</option>
                        <option value="BC">British Columbia</option>
                        <option value="MB">Manitoba</option>
                        <option value="NB">New Brunswick</option>
                        <option value="NL">Newfoundland and Labrador</option>
                        <option value="NT">Northwest Territories</option>
                        <option value="NS">Nova Scotia</option>
                        <option value="NU">Nunavut</option>
                        <option value="ON">Ontario</option>
                        <option value="PE">Prince Edward Island</option>
                        <option value="QC">Quebec</option>
                        <option value="SK">Saskatchewan</option>
                        <option value="YT">Yukon</option>
                      </select>
                    ) : (
                      ""
                    )}
                    {this.state.country === "Etats-Unis" ? (
                      <select
                        name="province"
                        id="province"
                        value={this.state.province}
                        onChange={this.handleChange}
                        onBlur={this.handleFocusout}
                        className={
                          this.state.e_province
                            ? "form-control fieldErr"
                            : "form-control"
                        }
                        placeholder={t("zqwprovince_x")}
                      >
                        <option value="">{t("zqwNVFrmEl109_x")}</option>
                        <option value="AL">Alabama</option>
                        <option value="AK">Alaska</option>
                        <option value="AZ">Arizona</option>
                        <option value="AR">Arkansas</option>
                        <option value="CA">California</option>
                        <option value="CO">Colorado</option>
                        <option value="CT">Connecticut</option>
                        <option value="DE">Delaware</option>
                        <option value="DC">District Of Columbia</option>
                        <option value="FL">Florida</option>
                        <option value="GA">Georgia</option>
                        <option value="HI">Hawaii</option>
                        <option value="ID">Idaho</option>
                        <option value="IL">Illinois</option>
                        <option value="IN">Indiana</option>
                        <option value="IA">Iowa</option>
                        <option value="KS">Kansas</option>
                        <option value="KY">Kentucky</option>
                        <option value="LA">Louisiana</option>
                        <option value="ME">Maine</option>
                        <option value="MD">Maryland</option>
                        <option value="MA">Massachusetts</option>
                        <option value="MI">Michigan</option>
                        <option value="MN">Minnesota</option>
                        <option value="MS">Mississippi</option>
                        <option value="MO">Missouri</option>
                        <option value="MT">Montana</option>
                        <option value="NE">Nebraska</option>
                        <option value="NV">Nevada</option>
                        <option value="NH">New Hampshire</option>
                        <option value="NJ">New Jersey</option>
                        <option value="NM">New Mexico</option>
                        <option value="NY">New York</option>
                        <option value="NC">North Carolina</option>
                        <option value="ND">North Dakota</option>
                        <option value="OH">Ohio</option>
                        <option value="OK">Oklahoma</option>
                        <option value="OR">Oregon</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="RI">Rhode Island</option>
                        <option value="SC">South Carolina</option>
                        <option value="SD">South Dakota</option>
                        <option value="TN">Tennessee</option>
                        <option value="TX">Texas</option>
                        <option value="UT">Utah</option>
                        <option value="VT">Vermont</option>
                        <option value="VA">Virginia</option>
                        <option value="WA">Washington</option>
                        <option value="WV">West Virginia</option>
                        <option value="WI">Wisconsin</option>
                        <option value="WY">Wyoming</option>
                        <option value="AS">American Samoa</option>
                        <option value="GU">Guam</option>
                        <option value="MP">Northern Mariana Islands</option>
                        <option value="PR">Puerto Rico</option>
                        <option value="UM">
                          United States Minor Outlying Islands
                        </option>
                        <option value="VI">Virgin Islands</option>
                      </select>
                    ) : (
                      ""
                    )}
                    {this.state.e_province && (
                      <span className="text-danger">
                        {FIELD_VALIDATION["province"].message}
                      </span>
                    )}
                  </div>

                  <div className="form-group neoCap col-md-6">
                    <label htmlFor="cp">{t("zqwcp_x")} *</label>
                    {/* <input type="text" name="cp" id="cp" defaultValue={this.state.cp} onChange={this.handleChange} onBlur={this.handleFocusout}
                      className={this.state.e_cp ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwcp_x')} /> */}
                    {this.state.country === "Canada" ? (
                      <InputMask
                        name="cp"
                        mask={mask}
                        onChange={this.handleChange}
                        value={this.state.cp}
                        onBlur={this.handleFocusout}
                        className={
                          this.state.e_cell
                            ? "form-control fieldErr"
                            : "form-control"
                        }
                        placeholder={t("zqwcp_x")}
                      />
                    ) : (
                      ""
                    )}
                    {this.state.country === "Etats-Unis" ? (
                      <input
                        type="text"
                        name="cp"
                        id="cp"
                        defaultValue={this.state.cp}
                        onChange={this.handleChange}
                        onBlur={this.handleFocusout}
                        className={
                          this.state.e_cp
                            ? "form-control fieldErr"
                            : "form-control"
                        }
                        placeholder={t("zqwcp_x")}
                      />
                    ) : (
                      ""
                    )}
                    {this.state.e_cp && (
                      <span className="text-danger">
                        {FIELD_VALIDATION["cp"].message}
                      </span>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}

              {this.state.country !== "Canada" &&
              this.state.country !== "Etats-Unis" ? (
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="province">{t("zqwprovince_x")} *</label>
                    <input
                      type="text"
                      name="province"
                      id="province"
                      value={this.state.province}
                      onChange={this.handleChange}
                      onBlur={this.handleFocusout}
                      className={
                        this.state.e_province
                          ? "form-control fieldErr"
                          : "form-control"
                      }
                      placeholder={t("zqwprovince_x")}
                      maxLength={2}
                    />
                    {this.state.e_province && (
                      <span className="text-danger">
                        {FIELD_VALIDATION["province"].message}
                      </span>
                    )}
                  </div>

                  <div className="form-group col-md-6">
                    <label htmlFor="cp">{t("zqwcp_x")} *</label>
                    <input
                      type="text"
                      name="cp"
                      id="cp"
                      defaultValue={this.state.cp}
                      onChange={this.handleChange}
                      onBlur={this.handleFocusout}
                      className={
                        this.state.e_cp
                          ? "form-control fieldErr"
                          : "form-control"
                      }
                      placeholder={t("zqwcp_x")}
                    />
                    {this.state.e_cp && (
                      <span className="text-danger">
                        {FIELD_VALIDATION["cp"].message}
                      </span>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="form-group">
                <label htmlFor="occupation">{t("zqwoccupation_x")} *</label>
                <input
                  type="text"
                  name="occupation"
                  id="occupation"
                  defaultValue={this.state.occupation}
                  onChange={this.handleChange}
                  onBlur={this.handleFocusout}
                  className={
                    this.state.e_occupation
                      ? "form-control fieldErr"
                      : "form-control"
                  }
                  placeholder={t("zqwoccupation_x")}
                />
                {this.state.e_occupation && (
                  <span className="text-danger">
                    {FIELD_VALIDATION["occupation"].message}
                  </span>
                )}
              </div>
              {this.state.emailDoesNotExist === 1 ? (
                <div className="form-group">
                  {/* <button type="submit" className="btn btn-primary">{this.actionMode==='new'? t('Create'): t('Update')} Suivant -- Paiement</button> &nbsp;  */}
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={this.handlePreviousStep}
                  >
                    {t("zqwNVFrmEl92_x")}
                  </button>{" "}
                  &nbsp;
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.handleClientInfoNextStep}
                  >
                    {t("zqwNVFrmEl93_x")}
                  </button>{" "}
                  &nbsp;
                  <a
                    href="#"
                    className="btn btn-warning float-right"
                    onClick={this.restartOver}
                  >
                    {t("zqwNVFrmEl94_x")}
                  </a>
                </div>
              ) : (
                ""
              )}

              {this.state.idClient > 0 &&
              (id_zendesk === null ||
                id_zendesk === "" ||
                id_zendesk === "0") &&
              (stripe_id === null || stripe_id === "") ? (
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={this.handleUpdateClient}
                  >
                    {t("Update")} Client
                  </button>{" "}
                  &nbsp;
                </div>
              ) : (
                ""
              )}
            </form>
          </div>
        </div>
      </div>
    );
  };

  ZipCodePossibleAddress = (event) => {
    //() {
    const open = this.state.openPossibleZipCode; //lg sm md  fullWidth={"sm"} maxWidth={"lg"}
    const { t } = this.props;
    const listAddress = this.state.listAddressOnCode;

    return (
      <div>
        <Dialog
          maxWidth={"sm"}
          open={open}
          onClose={this.handleClosePostalCode}
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="form-dialog-confirm"
        >
          <DialogContent id="form-dialog-confirm">
            <div className="text-center"></div>
            <div className="text-center">
              <h2>{t("zqwNVFrmEl110_x")}</h2>
            </div>
            <p>{t("zqwNVFrmEl111_x")}</p>
            {/* <p> &nbsp; </p> */}
            <div className="form-row neoxtbl">
              <table style={{ minWidth: "300px" }}>
                <thead>
                  <tr>
                    <th colSpan="2">{t("zqwNVFrmEl112_x")}</th>
                    {/* <th> &nbsp; &nbsp;  </th>  */}
                  </tr>
                </thead>
                <tbody>{listAddress}</tbody>
              </table>

              <div className="form-group col-md-9">
                <br />
                <input
                  type="button"
                  data-confirmyesno="1"
                  onClick={this.handleClosePostalCode}
                  className="btn btn-default"
                  value={t("zqwNVFrmbtn_x")}
                />{" "}
                &nbsp; &nbsp;
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  };

  ShowProductExtraInfo = (event) => {
    const { t } = this.props;
    //const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const productInfo = this.state.infoProduct;

    let productOptions = [];
    let descriptionLiList = [];
    let infoProduct_title = "";
    let infoProduct_excerpt = "";

    let descriptionList =
      productInfo.data.length > 0 &&
      productInfo.data.map((item, i) => {
        if (i == 1 && item[0] !== undefined && item[0].title !== undefined) {
          infoProduct_title = item[0].title;
          infoProduct_excerpt = item[0].excerpt;
          const infoDesc = item[0].Description;
          const infoDescLi =
            infoDesc.length > 0 &&
            infoDesc.map((descr, id) => {
              return (
                <li key={id} className="pl-20  font-18">
                  {descr.value}
                </li>
              );
            }, this);
          return infoDescLi;
        }
        return null;
      }, this);

    let pourquoiList =
      productInfo.data.length > 0 &&
      productInfo.data.map((item, i) => {
        if (i == 1 && item[0] !== undefined && item[0].Pourquoi !== undefined) {
          const infoDesc = item[0].Pourquoi;
          const infoDescLi =
            infoDesc.length > 0 &&
            infoDesc.map((descr, id) => {
              return (
                <li key={id} className="pl-20  font-18">
                  {descr.value}
                </li>
              );
            }, this);
          return infoDescLi;
        } else {
          return;
        }
      }, this);

    const TabPanel = this.TabPanel;
    let lang = this.state.language;
    const agLang = t("curlang");
    if (lang === "") {
      lang = agLang;
    }
    const whyNeolegal = lang === "en" ? "Why Neolegal?" : "Pourquoi Neolegal ?";
    const howitworks =
      lang === "en" ? "How does it work?" : "Comment ça marche ?";
    const guarantee = lang === "en" ? "Guarantee" : "Garantie";
    const showProdMulti = this.state.showProdMulti;

    return (
      <div>
        <h1 className="product-title font-32 text-theme-colored">
          {infoProduct_title}
        </h1>
        <div className="line-bottom short-description ">
          <p>{infoProduct_excerpt}</p>
          {showProdMulti === 2 ? (
            <>
              <p class="font-16">
                {" "}
                You can use our services for:
                <br /> - Get legal advice on your situation
                <br /> - Open your case in Small Claims Court
                <br /> - Train you to present your case to the judge
                <br /> <br />{" "}
                <strong>
                  {" "}
                  Note that the sending of a letter of formal notice before
                  starting small claims proceedings is required in the vast
                  majority of cases. To send a formal notice{" "}
                </strong>{" "}
              </p>
            </>
          ) : (
            ""
          )}
          {showProdMulti === 1 ? (
            <>
              <p className="font-16">
                Vous pouvez utiliser nos services pour :
                <br />- Obtenir des conseils juridiques sur votre situation
                <br />- Ouvrir votre dossier auprès de la Cour des petites
                créances
                <br />- Vous former à présenter votre dossier devant le juge
                <br />
                <br />{" "}
                <strong>
                  Noter que l'envoi d'une lettre de mise en demeure avant
                  d'entamer des procédures aux petites créances est exigé dans
                  la grande majorité des cas. Pour envoyer une mise en demeure{" "}
                </strong>
              </p>
            </>
          ) : (
            ""
          )}
        </div>

        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Description" {...this.a11yProps(0)} />
            <Tab label={whyNeolegal} {...this.a11yProps(1)} />
            <Tab label={howitworks} {...this.a11yProps(2)} />
            <Tab label={guarantee} {...this.a11yProps(3)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <ul className="list text-black theme-colored angle-right  ">
            {descriptionList}
          </ul>
        </TabPanel>
        <TabPanel value={value} index={1}>
          {showProdMulti === 2 ? (
            <p className="font-16">
              <strong>OUR LAWYERS CAN HELP YOU WITH: </strong>{" "}
            </p>
          ) : (
            ""
          )}
          {showProdMulti === 1 ? (
            <p className="font-16">
              <strong>NOS AVOCATS PEUVENT VOUS AIDER À: </strong>{" "}
            </p>
          ) : (
            ""
          )}
          <ul className="list text-black theme-colored angle-right  ">
            {pourquoiList}
          </ul>
          {showProdMulti === 2 ? (
            <>
              <p class="font-16">
                {" "}
                OUR PACKAGE PRICES INCLUDE:
                <br /> - No hidden fees
                <br /> - No hourly rates
              </p>
            </>
          ) : (
            ""
          )}
          {showProdMulti === 1 ? (
            <>
              <p className="font-16">
                UNE TARIFICATION AU FORFAIT:
                <br />- Pas de frais cachés
                <br />- Pas de taux horaires
              </p>
            </>
          ) : (
            ""
          )}
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div className="section-content">
            <div className="row ">
              <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                <div className="text-center mb-30 mt-50 mt-sm-10 p-10">
                  <a
                    href="#"
                    className="fa-stack fa-2x text-theme-colored2"
                    data-step="1"
                    onClick={this.handleOpenProdInfoPopup}
                  >
                    <i className="fa fa-circle fa-stack-2x text-theme-colored2"></i>
                    <strong className="fa-stack-1x text-white">1</strong>
                  </a>

                  <a
                    href="#"
                    data-step="1"
                    onClick={this.handleOpenProdInfoPopup}
                  >
                    <h4 className="font-weight-600 mt-20">
                      {t("zqwNVFrmEl113_x")}
                    </h4>
                  </a>
                  <p className="pt-60 pt-sm-20">
                    <a
                      href="#"
                      className="btn btn-sm btn-theme-colored2 text-white"
                      data-step="1"
                      onClick={this.handleOpenProdInfoPopup}
                    >
                      {t("zqwNVFrmEl114_x")}
                    </a>
                  </p>
                </div>
              </div>

              <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                <div className="text-center mb-30 mt-50 mt-sm-10 p-10">
                  <a
                    href="#"
                    className="fa-stack fa-2x text-theme-colored2"
                    data-step="2"
                    onClick={this.handleOpenProdInfoPopup}
                  >
                    <i className="fa fa-circle fa-stack-2x text-theme-colored2"></i>
                    <strong className="fa-stack-1x text-white">2</strong>
                  </a>
                  <a
                    href="#"
                    data-step="2"
                    onClick={this.handleOpenProdInfoPopup}
                  >
                    <h4 className="mt-20">{t("zqwNVFrmEl115_x")}</h4>
                  </a>
                  <p className="pt-40  pt-sm-20">
                    <a
                      href="#"
                      className="btn btn-sm btn-theme-colored2 text-white"
                      data-step="2"
                      onClick={this.handleOpenProdInfoPopup}
                    >
                      {t("zqwNVFrmEl114_x")}
                    </a>
                  </p>
                </div>
              </div>

              <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                <div className="text-center mb-30 mt-50 mt-sm-10 p-10">
                  <a
                    href="#"
                    className="fa-stack fa-2x text-theme-colored2"
                    data-step="3"
                    onClick={this.handleOpenProdInfoPopup}
                  >
                    <i className="fa fa-circle fa-stack-2x text-theme-colored2"></i>
                    <strong className="fa-stack-1x text-white">3</strong>
                  </a>
                  <a
                    href="#"
                    data-step="3"
                    onClick={this.handleOpenProdInfoPopup}
                  >
                    <h4 className="mt-20">{t("zqwNVFrmEl116_x")}</h4>
                  </a>
                  <p className="pt-20  pt-sm-20">
                    <a
                      href="#"
                      className="btn btn-sm btn-theme-colored2 text-white"
                      data-step="3"
                      onClick={this.handleOpenProdInfoPopup}
                    >
                      {t("zqwNVFrmEl114_x")}
                    </a>
                  </p>
                </div>
              </div>

              <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                <div className="text-center mb-30 mt-50 mt-sm-10 p-10">
                  <a
                    href="#"
                    className="fa-stack fa-2x text-theme-colored2"
                    data-step="4"
                    onClick={this.handleOpenProdInfoPopup}
                  >
                    <i className="fa fa-circle fa-stack-2x text-theme-colored2"></i>
                    <strong className="fa-stack-1x text-white">4</strong>
                  </a>
                  <a
                    href="#"
                    data-step="4"
                    onClick={this.handleOpenProdInfoPopup}
                  >
                    <h4 className="mt-20">{t("zqwNVFrmEl117_x")}</h4>
                  </a>
                  <p className="pt-40  pt-sm-20">
                    <a
                      href="#"
                      className="btn btn-sm btn-theme-colored2 text-white"
                      data-step="4"
                      onClick={this.handleOpenProdInfoPopup}
                    >
                      {t("zqwNVFrmEl114_x")}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <div className="text-center mt-20 mt-sm-20">
            <img
              width="200"
              src="/static/assets/img/sceau-garantie-neo-fr.png"
              alt="Sceau esprit tranquille Neolegal"
            />
            <h4 className="title mt-0">{t("zqwNVFrmEl118_x")}</h4>
            <p className="desc mb-20">{t("zqwNVFrmEl119_x")}</p>
          </div>
        </TabPanel>
      </div>
    );
  };

  TabPanel = (props) => {
    //return (<div className="text-center mt-20 mt-sm-20">Bonjour</div>);
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  };

  a11yProps = (index) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  };

  ProductInfoStepsPopup = (props) => {
    const { t } = this.props;
    //const { t, i18n } = useTranslation("common");
    const myLang = t("curlang");
    if (myLang === "fr") {
      return this.xProductInfoStepsPopupFr(props);
    } else {
      return this.xProductInfoStepsPopupEn(props);
    }
  };

  xProductInfoStepsPopupFr = (props) => {
    const open = this.state.openInfoStepsPopup; //lg sm md  fullWidth={"sm"} maxWidth={"lg"}
    const infoStepsPopupno = this.state.infoStepsPopupno;
    const { t } = this.props;
    //const { t, i18n } = useTranslation("common");

    return (
      <div>
        <Dialog
          maxWidth={"lg"}
          open={open}
          onClose={this.handleCloseProdInfoPopup}
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="customized-dialog-title"
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={this.handleCloseProdInfoPopup}
          >
            {infoStepsPopupno === "1" ? (
              <h3 className="mt-0 offset modal-title" id="inscriptionmodal">
                Votre <span className="text-theme-colored2">inscription</span>
              </h3>
            ) : (
              ""
            )}

            {infoStepsPopupno === "2" ? (
              <h3 className="mt-0 offset modal-title" id="portailmodal">
                Votre <span className="text-theme-colored2">Portail</span>{" "}
                Juridique
              </h3>
            ) : (
              ""
            )}

            {infoStepsPopupno === "3" ? (
              <h3 className="mt-0 offset modal-title" id="consultationmodal">
                Votre <span className="text-theme-colored2 ">consultation</span>{" "}
                avec l'avocat
              </h3>
            ) : (
              ""
            )}

            {infoStepsPopupno === "4" ? (
              <h3 className="mt-0 offset modal-title" id="signaturemodal">
                Votre{" "}
                <span className="text-theme-colored2 ">
                  signature électronique
                </span>
              </h3>
            ) : (
              ""
            )}
          </DialogTitle>
          <DialogContent id="form-dialog-infoprod" dividers>
            <div className="form-row neoxtbl">
              {infoStepsPopupno === "1" ? (
                <div className="col-md-12 m-10">
                  <div>
                    <div className="icon-box clearfix">
                      <a
                        href="#"
                        className="icon icon-circled bg-theme-colored icon-lg pull-left flip sm-pull-none"
                      >
                        <i className="fa fa-2x fa-user-plus text-white font-36"></i>
                      </a>
                      <div className="ml-120 ml-sm-0">
                        <h4 className="icon-box-title text-uppercase letter-space-1">
                          Enregistrez-vous
                        </h4>
                        <p>
                          Afin de vous servir, nous aurons besoin de quelques
                          informations sur vous:{" "}
                        </p>
                        <ul className="list theme-colored angle-double-right">
                          <li>Courriel</li>
                          <li>
                            Un mot de passe que vous choisissez pour avoir accès
                            à votre portail juridique
                          </li>
                          <li>Téléphone</li>
                          <li>Adresse postale</li>
                        </ul>
                        <p className="mt-10">
                          Vous pouvez vous enregistrer par téléphone également.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="icon-box clearfix">
                      <a
                        href="#"
                        className="icon icon-circled bg-theme-colored2 icon-lg pull-left flip sm-pull-none"
                      >
                        <i className="fa fa-2x fa-credit-card text-white font-36"></i>
                      </a>
                      <div className="ml-120 ml-sm-0">
                        <h4 className="icon-box-title text-uppercase letter-space-1">
                          Paiement en ligne ou par téléphone
                        </h4>
                        <p>Vous pouvez payer en ligne par: </p>
                        <ul className="list theme-colored angle-double-right">
                          <li>Carte de crédit</li>
                          <li>Interac en Ligne</li>
                          <li>Paypal</li>
                          <li>Virement Interac</li>
                        </ul>
                        <p className="mt-10">
                          Vous pouvez aussi procéder au paiement avec un de nos
                          agents par téléphone.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {infoStepsPopupno === "2" ? (
                <div className=" col-md-12 ">
                  <div className="mt-20">
                    <div className="icon-box clearfix">
                      <a
                        href="/#products"
                        className="icon icon-circled bg-theme-colored2 icon-lg pull-left flip sm-pull-none"
                      >
                        <i className="fa fa-2x fa-briefcase text-white font-36"></i>
                      </a>
                      <div className="ml-120 ml-sm-0">
                        <h4 className="icon-box-title text-uppercase letter-space-1">
                          C'est quoi le portail juridique?
                        </h4>
                        <p>
                          <b>
                            Le portail est une partie du site Neolegal qui est
                            dédiée à votre dossier.
                          </b>
                          <br />
                          Vous y accédez en cliquant sur Connexion{" "}
                          <cite title="Source Title">
                            (en haut à droite de la page d'accueil)
                          </cite>{" "}
                          ou en{" "}
                          <a className="text-black" href="/login">
                            cliquant ici
                          </a>
                          . Vous avez besoin de votre courriel et du mot de
                          passe que vous avez créé à l'inscription ou qui vous a
                          été fourni lors de l'achat par téléphone.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="icon-box clearfix">
                      <a
                        href="#"
                        className="icon icon-circled bg-theme-colored icon-lg pull-left  flip sm-pull-none"
                      >
                        <i className="fa fa-2x fa-question text-white font-36"></i>
                      </a>
                      <div className="ml-120 ml-sm-0">
                        <h4 className="icon-box-title text-uppercase letter-space-1">
                          À quoi çà sert?
                        </h4>
                        <p>
                          Dès que vous avez acheté un service juridique, vous
                          pouvez:{" "}
                        </p>
                        <ul className="list theme-colored angle-double-right">
                          <li>
                            Remplir un formulaire pour accélérer le processus
                            (Pour certains de nos produits)
                          </li>
                          <li>
                            Précisez des éléments sur votre dossier (détails sur
                            la partie adverse, chronologie des événements...)
                          </li>
                          <li>
                            Ajouter des documents reliés à votre dossier pour
                            que votre avocat puisse les consulter
                          </li>
                          <li>Écrire à votre avocat</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="icon-box clearfix">
                      <a
                        href="#"
                        className="icon icon-circled bg-theme-colored2 icon-lg pull-left  flip sm-pull-none"
                      >
                        <i className="fa fa-2x fa-life-saver text-white font-36"></i>
                      </a>
                      <div className="ml-120 ml-sm-0">
                        <h4 className="icon-box-title text-uppercase letter-space-1">
                          Vous n'êtes pas très technologique? Pas de problème!
                        </h4>
                        <p>
                          Nous avons pensé à vous. Vous pouvez également
                          communiquer avec nous
                        </p>
                        <ul className="list theme-colored angle-double-right">
                          <li>Par téléphone</li>
                          <li>Par courriel</li>
                          <li>Par SMS</li>
                          <li>Par Fax</li>
                          <li>
                            Et bien sûr, vous pouvez toujours chatter sur notre
                            site pour avoir de l'aide immédiate
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {infoStepsPopupno === "3" ? (
                <div className=" col-md-12 ">
                  <div className="mt-20">
                    <div className="icon-box clearfix">
                      <a
                        href="/#products"
                        className="icon icon-circled bg-theme-colored2 icon-lg pull-left flip sm-pull-none"
                      >
                        <i className="fa fa-2x fa-umbrella text-white font-36"></i>
                      </a>
                      <div className="ml-120 ml-sm-0">
                        <h4 className="icon-box-title text-uppercase letter-space-1">
                          La relation avec votre avocat Neolegal
                        </h4>
                        <h4>
                          Nos avocats sont tous Membres du Barreau du Québec.
                        </h4>

                        <p>
                          Dès que vous procédez à l'achat:
                          <br />
                        </p>
                        <ul className="list theme-colored angle-double-right">
                          <li>
                            Un avocat de Neolegal vous est attribué en fonction
                            de votre problématique et de nos disponibilités
                          </li>
                          <li>
                            Un rendez-vous téléphonique est planifié avec vous
                            par notre service à la clientèle
                          </li>
                          <li>
                            À l'heure prévue, notre avocat vous appelle (Vous
                            voyez notre numéro <b>1 855 996 9695</b> sur votre
                            afficheur)
                          </li>
                        </ul>
                        <p></p>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="icon-box clearfix">
                      <a
                        href="#"
                        className="icon icon-circled bg-theme-colored2 icon-lg pull-left  flip sm-pull-none"
                      >
                        <i className="fa fa-2x fa-folder-o text-white font-36"></i>
                      </a>
                      <div className="ml-120 ml-sm-0">
                        <h4 className="icon-box-title text-uppercase letter-space-1">
                          Suivi de votre dossier
                        </h4>
                        <p>
                          Suite à l'appel initial, votre avocat communiquera
                          avec vous selon la méthode que vous préférez (en
                          général dans le portail).{" "}
                        </p>
                        <p>
                          N'hésitez pas à nous{" "}
                          <a className="text-black" href="/contact">
                            contacter{" "}
                            <i className="fa fa-2x fa-mobile text-black font-24"></i>
                          </a>{" "}
                          pour toute question !
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {infoStepsPopupno === "4" ? (
                <div className=" col-md-12 ">
                  <div className="mt-20">
                    <div className="icon-box clearfix">
                      <a
                        href="#products"
                        className="icon icon-circled bg-theme-colored2 icon-lg pull-left flip sm-pull-none"
                      >
                        <i className="fa fa-2x fa-pencil-square-o text-white font-36"></i>
                      </a>
                      <div className="ml-120 ml-sm-0">
                        <h4 className="icon-box-title text-uppercase letter-space-1">
                          C'est quoi la signature électronique
                        </h4>
                        <p>
                          La signature électronique est une méthode qui vous
                          permet de signer un document sans l'imprimer et le
                          renvoyer.
                          <br />
                          Ne vous inquiétez pas, c'est très simple!
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="icon-box clearfix">
                      <a
                        href="#"
                        className="icon icon-circled bg-theme-colored icon-lg pull-left  flip sm-pull-none"
                      >
                        <i className="fa fa-2x fa-question text-white font-36"></i>
                      </a>
                      <div className="ml-120 ml-sm-0">
                        <h4 className="icon-box-title text-uppercase letter-space-1">
                          Comment ça marche?
                        </h4>
                        <p>
                          Dès que vous avez validé le document à signer avec
                          votre avocat Neolegal:{" "}
                        </p>
                        <ul className="list theme-colored angle-double-right">
                          <li>
                            Vous recevez un courriel avec un lien à cliquer
                          </li>
                          <li>
                            Vous visualisez le document et vous le relisez une
                            dernière fois
                          </li>
                          <li>Vous cliquez à l'endroit de la signature</li>
                          <li>
                            Sur votre téléphone, vous pouvez signer avec votre
                            doigt sur votre écran
                          </li>
                          <li>
                            Sur un ordinateur, vous pouvez signer avec votre
                            souris
                          </li>
                          <li>
                            Vous pouvez également, sélectionner une signature
                            automatique générée à partir de votre nom
                          </li>
                          <li>
                            Dès que vous avez signé, nous recevons le document
                            immédiatement
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="icon-box clearfix">
                      <a
                        href="#"
                        className="icon icon-circled bg-theme-colored2 icon-lg pull-left  flip sm-pull-none"
                      >
                        <i className="fa fa-2x fa-plus-square-o text-white font-36"></i>
                      </a>
                      <div className="ml-120 ml-sm-0">
                        <h4 className="icon-box-title text-uppercase letter-space-1">
                          Peut-il y avoir plusieurs signataires?
                        </h4>
                        <p>Oui bien sûr!</p>
                        <p>
                          Nous aurons besoin, si possible, d'une adresse
                          courriel par signataire
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="form-group col-md-9">
                <br />
                <input
                  type="button"
                  data-confirmyesno="1"
                  onClick={this.handleCloseProdInfoPopup}
                  className="btn btn-default"
                  value={"Fermer/Close"}
                />{" "}
                &nbsp; &nbsp;
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  };

  xProductInfoStepsPopupEn = (props) => {
    const open = this.state.openInfoStepsPopup; // lg sm md fullWidth = {"sm"} maxWidth = {"lg"}
    const infoStepsPopupno = this.state.infoStepsPopupno;
    const { t } = this.props;
    // const {t, i18n} = useTranslation ("common");

    return (
      <div>
        <Dialog
          maxWidth={"lg"}
          open={open}
          onClose={this.handleCloseProdInfoPopup}
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="customized-dialog-title"
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={this.handleCloseProdInfoPopup}
          >
            {infoStepsPopupno === "1" ? (
              <h3 className="mt-0 offset modal-title" id="inscriptionmodal">
                {" "}
                Your <span className="text-theme-colored2">
                  {" "}
                  inscription{" "}
                </span>{" "}
              </h3>
            ) : (
              ""
            )}

            {infoStepsPopupno === "2" ? (
              <h3 className="mt-0 offset modal-title" id="portalmodal">
                {" "}
                Your <span className="text-theme-colored2">
                  {" "}
                  Portal{" "}
                </span> Legal{" "}
              </h3>
            ) : (
              ""
            )}

            {infoStepsPopupno === "3" ? (
              <h3 className="mt-0 offset modal-title" id="consultationmodal">
                {" "}
                Your <span className="text-theme-colored2">
                  {" "}
                  consultation{" "}
                </span>{" "}
                with lawyer{" "}
              </h3>
            ) : (
              ""
            )}

            {infoStepsPopupno === "4" ? (
              <h3 className="mt-0 offset modal-title" id="signaturemodal">
                {" "}
                Your{" "}
                <span className="text-theme-colored2">
                  {" "}
                  electronic signature{" "}
                </span>{" "}
              </h3>
            ) : (
              ""
            )}
          </DialogTitle>
          <DialogContent id="form-dialog-infoprod" dividers>
            <div className="form-row neoxtbl">
              {infoStepsPopupno === "1" ? (
                <div className="col-md-12 m-10">
                  <div>
                    <div className="icon-box clearfix">
                      <a
                        href="#"
                        className="icon icon-circled bg-theme-colored icon-lg pull-left flip sm-pull-none"
                      >
                        <i className="fa fa-2x fa-user-plus text-white font-36">
                          {" "}
                        </i>
                      </a>
                      <div className="ml-120 ml-sm-0">
                        <h4 className="icon-box-title text-uppercase letter-space-1">
                          {" "}
                          Register{" "}
                        </h4>
                        <p>
                          {" "}
                          In order to serve you, we will need some information
                          about you:{" "}
                        </p>
                        <ul className="list theme-colored angle-double-right">
                          <li> Email </li>
                          <li>
                            {" "}
                            A password that you choose to access your legal
                            portal{" "}
                          </li>
                          <li> Phone </li>
                          <li> Postal address </li>
                        </ul>
                        <p className="mt-10">
                          {" "}
                          You can register by phone as well.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="icon-box clearfix">
                      <a
                        href="#"
                        className="icon icon-circled bg-theme-colored2 icon-lg pull-left flip sm-pull-none"
                      >
                        <i className="fa fa-2x fa-credit-card text-white font-36">
                          {" "}
                        </i>
                      </a>
                      <div className="ml-120 ml-sm-0">
                        <h4 className="icon-box-title text-uppercase letter-space-1">
                          {" "}
                          Online or phone payment{" "}
                        </h4>
                        <p> You can pay online by: </p>
                        <ul className="list theme-colored angle-double-right">
                          <li> Credit card </li>
                          <li> Interac Online </li>
                          <li> Paypal </li>
                          <li> Interac e-Transfer </li>
                        </ul>
                        <p className="mt-10">
                          {" "}
                          You can also proceed to payment with one of our agents
                          by phone.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {infoStepsPopupno === "2" ? (
                <div className="col-md-12">
                  <div className="mt-20">
                    <div className="icon-box clearfix">
                      <a
                        href="/#products"
                        className="icon icon-circled bg-theme-colored2 icon-lg pull-left flip sm-pull-none"
                      >
                        <i className="fa fa-2x fa-briefcase text-white font-36">
                          {" "}
                        </i>
                      </a>
                      <div className="ml-120 ml-sm-0">
                        <h4 className="icon-box-title text-uppercase letter-space-1">
                          {" "}
                          What is the legal portal?{" "}
                        </h4>
                        <p>
                          {" "}
                          <b>
                            {" "}
                            The portal is a part of the Neolegal site which is
                            dedicated to your file.{" "}
                          </b>{" "}
                          <br />
                          You access it by clicking on Login{" "}
                          <cite title="Source Title">
                            {" "}
                            (top right of the home page){" "}
                          </cite>{" "}
                          or by{" "}
                          <a className="text-black" href="/login">
                            {" "}
                            clicking here{" "}
                          </a>
                          . You will need your email address and the password
                          that you created during registration or that was
                          provided to you during the purchase by phone.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="icon-box clearfix">
                      <a
                        href="#"
                        className="icon icon-circled bg-theme-colored icon-lg pull-left flip sm-pull-none"
                      >
                        <i className="fa fa-2x fa-question text-white font-36">
                          {" "}
                        </i>
                      </a>
                      <div className="ml-120 ml-sm-0">
                        <h4 className="icon-box-title text-uppercase letter-space-1">
                          {" "}
                          What is this for?{" "}
                        </h4>
                        <p>
                          {" "}
                          Once you have purchased a legal service, you can:{" "}
                        </p>
                        <ul className="list theme-colored angle-double-right">
                          <li>
                            {" "}
                            Fill out a form to speed up the process (For some of
                            our products){" "}
                          </li>
                          <li>
                            {" "}
                            Specify elements in your file (details on the
                            opposing party, chronology of events ...){" "}
                          </li>
                          <li>
                            {" "}
                            Add related documents to your file so that your
                            lawyer can consult them{" "}
                          </li>
                          <li> Write to your lawyer </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="icon-box clearfix">
                      <a
                        href="#"
                        className="icon icon-circled bg-theme-colored2 icon-lg pull-left flip sm-pull-none"
                      >
                        <i className="fa fa-2x fa-life-saver text-white font-36">
                          {" "}
                        </i>
                      </a>
                      <div className="ml-120 ml-sm-0">
                        <h4 className="icon-box-title text-uppercase letter-space-1">
                          {" "}
                          Are you not very tech-savvy? No problem!{" "}
                        </h4>
                        <p> We've got you covered. You can also contact us </p>
                        <ul className="list theme-colored angle-double-right">
                          <li> By phone </li>
                          <li> By email </li>
                          <li> By SMS </li>
                          <li> By Fax </li>
                          <li>
                            {" "}
                            And of course you can always chat on our site for
                            immediate help{" "}
                          </li>
                        </ul>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              ) : (
                ""
              )}

              {infoStepsPopupno === "3" ? (
                <div className="col-md-12">
                  <div className="mt-20">
                    <div className="icon-box clearfix">
                      <a
                        href="/#products"
                        className="icon icon-circled bg-theme-colored2 icon-lg pull-left flip sm-pull-none"
                      >
                        <i className="fa fa-2x fa-umbrella text-white font-36">
                          {" "}
                        </i>
                      </a>
                      <div className="ml-120 ml-sm-0">
                        <h4 className="icon-box-title text-uppercase letter-space-1">
                          {" "}
                          The relationship with your Neolegal lawyer{" "}
                        </h4>
                        <h4>
                          {" "}
                          Our lawyers are all Members of the Barreau du Québec.{" "}
                        </h4>
                        <p>
                          {" "}
                          As soon as you make the purchase: <br />
                        </p>{" "}
                        <ul className="list theme-colored angle-double-right">
                          <li>
                            {" "}
                            A Neolegal lawyer is assigned to you according to
                            your problem and our availability{" "}
                          </li>
                          <li>
                            {" "}
                            A telephone appointment is scheduled with you by our
                            customer service{" "}
                          </li>
                          <li>
                            {" "}
                            At the scheduled time, our lawyer calls you (You can
                            see our number <b> 1 855 996 9695 </b> on your
                            display){" "}
                          </li>
                        </ul>
                        <p> </p>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="icon-box clearfix">
                      <a
                        href="#"
                        className="icon icon-circled bg-theme-colored2 icon-lg pull-left flip sm-pull-none"
                      >
                        <i className="fa fa-2x fa-folder-o text-white font-36">
                          {" "}
                        </i>
                      </a>
                      <div className="ml-120 ml-sm-0">
                        <h4 className="icon-box-title text-uppercase letter-space-1">
                          {" "}
                          Track your folder{" "}
                        </h4>
                        <p>
                          {" "}
                          Following the initial call, your lawyer will contact
                          you using the method you prefer (usually through the
                          portal).{" "}
                        </p>
                        <p>
                          Please feel free to contact us{" "}
                          <a className="text-black" href="/contact">
                            {" "}
                            contact{" "}
                            <i className="fa fa-2x fa-mobile text-black font-24">
                              {" "}
                            </i>{" "}
                          </a>{" "}
                          for any question!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {infoStepsPopupno === "4" ? (
                <div className="col-md-12">
                  <div className="mt-20">
                    <div className="icon-box clearfix">
                      <a
                        href="#products"
                        className="icon icon-circled bg-theme-colored2 icon-lg pull-left flip sm-pull-none"
                      >
                        <i className="fa fa-2x fa-pencil-square-o text-white font-36">
                          {" "}
                        </i>
                      </a>
                      <div className="ml-120 ml-sm-0">
                        <h4 className="icon-box-title text-uppercase letter-space-1">
                          {" "}
                          What is the electronic signature{" "}
                        </h4>
                        <p>
                          {" "}
                          The electronic signature is a method that allows you
                          to sign a document without printing it and sending it
                          back. <br />
                          Don't worry, it's very easy!
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="icon-box clearfix">
                      <a
                        href="#"
                        className="icon icon-circled bg-theme-colored icon-lg pull-left flip sm-pull-none"
                      >
                        <i className="fa fa-2x fa-question text-white font-36">
                          {" "}
                        </i>
                      </a>
                      <div className="ml-120 ml-sm-0">
                        <h4 className="icon-box-title text-uppercase letter-space-1">
                          {" "}
                          How does it work?{" "}
                        </h4>
                        <p>
                          {" "}
                          As soon as you have validated the document to be
                          signed with your Neolegal lawyer:{" "}
                        </p>
                        <ul className="list theme-colored angle-double-right">
                          <li> You receive an email with a link to click </li>
                          <li>
                            {" "}
                            You view the document and reread it one last time{" "}
                          </li>
                          <li> You click at the place of the signature </li>
                          <li>
                            {" "}
                            On your phone, you can sign with your finger on your
                            screen{" "}
                          </li>
                          <li> On a computer, you can sign with your mouse </li>
                          <li>
                            {" "}
                            You can also select an automatic signature generated
                            from your name{" "}
                          </li>
                          <li>
                            {" "}
                            As soon as you have signed, we receive the document
                            immediately{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="icon-box clearfix">
                      <a
                        href="#"
                        className="icon icon-circled bg-theme-colored2 icon-lg pull-left flip sm-pull-none"
                      >
                        <i className="fa fa-2x fa-plus-square-o text-white font-36">
                          {" "}
                        </i>
                      </a>
                      <div className="ml-120 ml-sm-0">
                        <h4 className="icon-box-title text-uppercase letter-space-1">
                          {" "}
                          Can there be multiple signers?{" "}
                        </h4>
                        <p> Yes of course! </p>
                        <p>
                          {" "}
                          We will need, if possible, one email address per
                          signer{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="form-group col-md-9">
                <br />
                <input
                  type="button"
                  data-confirmyesno="1"
                  onClick={this.handleCloseProdInfoPopup}
                  className="btn btn-default"
                  value={"Close"}
                />{" "}
                & nbsp; & nbsp;
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  };

  FinishTransactionPopup = (props) => {
    //() {
    const open = this.state.openTransactionPopup; //lg sm md  fullWidth={"sm"} maxWidth={"lg"}
    const { t } = this.props;
    //const { t, i18n } = useTranslation("common");

    return (
      <div>
        <Dialog
          maxWidth={"sm"}
          open={open}
          onClose={this.handleCloseTransactionPopup}
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="form-dialog-confirm"
        >
          <DialogContent id="form-dialog-transactionPopup">
            <div className="text-center">
              <p>{t("zqwNVFrmEl120_x")}</p>
              <p>{t("zqwNVFrmEl121_x")}</p>
              <p>{t("zqwNVFrmEl122_x")}</p>
            </div>

            <div className="form-row neoxtbl">
              <div className="form-group col-md-9">
                <br />
                <input
                  type="button"
                  onClick={this.handleCloseTransactionPopup}
                  className="btn btn-success"
                  value={t("zqwNVFrmbtn_x")}
                />{" "}
                &nbsp; &nbsp;
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  };

  ProductListAsOnTheSite = (event) => {
    const { t } = this.props;
    //const { t, i18n } = useTranslation("common");
    const myLang = t("curlang");
    if (myLang === "fr") {
      return this.xProductListAsOnTheSiteFr(event);
    } else {
      return this.xProductListAsOnTheSiteEn(event);
    }
  };

  xProductListAsOnTheSiteFr = (event) => {
    const open = this.state.openPListAsOnTheSite; //lg sm md  fullWidth={"sm"} maxWidth={"lg"}
    const { t } = this.props;
    const [value, setValue] = React.useState(0);
    let nomenu = 0;

    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const [anchorEl3, setAnchorEl3] = React.useState(null);
    const [anchorEl4, setAnchorEl4] = React.useState(null);
    const [anchorEl5, setAnchorEl5] = React.useState(null);
    const [anchorEl6, setAnchorEl6] = React.useState(null);
    const [anchorEl7, setAnchorEl7] = React.useState(null);
    const [anchorEl8, setAnchorEl8] = React.useState(null);
    const [anchorEl9, setAnchorEl9] = React.useState(null);
    const [anchorEl10, setAnchorEl10] = React.useState(null);
    const [anchorEl11, setAnchorEl11] = React.useState(null);
    const [anchorEl12, setAnchorEl12] = React.useState(null);
    const [anchorEl13, setAnchorEl13] = React.useState(null);

    const handleClick = (event) => {
      const xnomenu = event.target.dataset.nomenu; //event.currentTarget.dataset
      const href = event.target.dataset.href;
      if (xnomenu === "1") {
        setAnchorEl1(event.currentTarget);
      } else if (xnomenu === "2") {
        setAnchorEl2(event.currentTarget);
      } else if (xnomenu === "3") {
        setAnchorEl3(event.currentTarget);
      } else if (xnomenu === "4") {
        setAnchorEl4(event.currentTarget);
      } else if (xnomenu === "5") {
        setAnchorEl5(event.currentTarget);
      } else if (xnomenu === "6") {
        setAnchorEl6(event.currentTarget);
      } else if (xnomenu === "7") {
        setAnchorEl7(event.currentTarget);
      } else if (xnomenu === "8") {
        setAnchorEl8(event.currentTarget);
      } else if (xnomenu === "9") {
        setAnchorEl9(event.currentTarget);
      } else if (xnomenu === "10") {
        setAnchorEl10(event.currentTarget);
      } else if (xnomenu === "11") {
        setAnchorEl11(event.currentTarget);
      } else if (xnomenu === "12") {
        setAnchorEl12(event.currentTarget);
      } else if (xnomenu === "13") {
        setAnchorEl13(event.currentTarget);
      }
    };

    const handleClose = () => {
      setAnchorEl1(null);
      setAnchorEl2(null);
      setAnchorEl3(null);
      setAnchorEl4(null);
      setAnchorEl5(null);
      setAnchorEl6(null);
      setAnchorEl7(null);
      setAnchorEl8(null);
      setAnchorEl9(null);
      setAnchorEl10(null);
      setAnchorEl11(null);
      setAnchorEl12(null);
      setAnchorEl13(null);
    };

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const handleOpenItem = (hrefVal) => {
      setAnchorEl1(null);
      setAnchorEl2(null);
      setAnchorEl3(null);
      setAnchorEl4(null);
      setAnchorEl5(null);
      setAnchorEl6(null);
      setAnchorEl7(null);
      setAnchorEl8(null);
      setAnchorEl9(null);
      setAnchorEl10(null);
      setAnchorEl11(null);
      setAnchorEl12(null);
      setAnchorEl13(null);
      const selVal = parseInt(hrefVal); //parseInt(event.target.dataset.href);
      const globalStepWorkflow = this.state.globalStepWorkflow;
      if (selVal > 0) {
        this.setState({ ["productId"]: selVal });
        if (globalStepWorkflow < 2) {
          this.setState({ ["globalStepWorkflow"]: 2, ["workflowType"]: 2 });
        }
        this.workflowProductList = true;
        const product = this.getProductChange(selVal);
        this.applyProductChange(selVal);
        this.handleClosePListAsOnTheSite(null);
      }
    };
    const TabPanel = this.TabPanel;

    return (
      <div>
        <Dialog
          maxWidth={"sm"}
          open={open}
          onClose={this.handleClosePListAsOnTheSite}
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="form-dialog-confirm"
        >
          <DialogContent id="form-dialog-confirm">
            <div className="text-center">
              <h2>
                Liste de Produit Comme le site &nbsp; &nbsp;{" "}
                <a href="#" onClick={this.handleClosePListAsOnTheSite}>
                  <CloseIcon />
                </a>
              </h2>
            </div>
            <div className="form-row neoxtbl">
              <AppBar position="static" color="default">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab label="Particuliers" {...this.a11yProps(0)} />
                  <Tab label="Entreprises" {...this.a11yProps(1)} />
                </Tabs>
              </AppBar>

              <TabPanel value={value} index={0}>
                <div className="megamenu">
                  <div className="megamenu-row">
                    <div className="col3   ">
                      <h4 className="megamenu-col-title ml-5">
                        Démarches juridiques
                      </h4>
                      <ul className="list-dashed ">
                        <li>
                          <a
                            data-href="0"
                            href="#"
                            className="text-theme-colored"
                            aria-controls="simple-menu-m1"
                            aria-haspopup="true"
                            data-nomenu="1"
                            onClick={handleClick}
                          >
                            Mise en demeure
                          </a>
                          <Menu
                            id="simple-menu-m1"
                            anchorEl={anchorEl1}
                            keepMounted
                            open={Boolean(anchorEl1)}
                            onClose={handleClose}
                          >
                            <MenuItem onClick={(eventx) => handleOpenItem(4)}>
                              Envoyer une mise en demeure
                            </MenuItem>
                            <MenuItem onClick={(eventx) => handleOpenItem(5)}>
                              Répondre à une mise en demeure
                            </MenuItem>
                            <MenuItem onClick={(eventx) => handleOpenItem(16)}>
                              Envoyer une mise en demeure pour vice caché
                            </MenuItem>
                            <MenuItem onClick={(eventx) => handleOpenItem(47)}>
                              Répondre à une mise en demeure pour vice caché
                            </MenuItem>
                          </Menu>
                        </li>
                        {/* <li><a href="/categorie/2/petites-creances" className="text-theme-colored">Petites créances</a></li> */}
                        <li>
                          <a
                            data-href="0"
                            href="#"
                            className="text-theme-colored"
                            aria-controls="simple-menu-m2"
                            aria-haspopup="true"
                            data-nomenu="2"
                            onClick={handleClick}
                          >
                            Petites créances
                          </a>
                          <Menu
                            id="simple-menu-m2"
                            anchorEl={anchorEl2}
                            keepMounted
                            open={Boolean(anchorEl2)}
                            onClose={handleClose}
                          >
                            <MenuItem onClick={(eventx) => handleOpenItem(8)}>
                              Poursuivre à la Cour des petites créances -
                              Coaching
                            </MenuItem>
                            <MenuItem onClick={(eventx) => handleOpenItem(6)}>
                              Poursuivre à la Cour des petites créances - De
                              base
                            </MenuItem>
                            <MenuItem onClick={(eventx) => handleOpenItem(9)}>
                              Poursuivre à la Cour des petites créances -
                              Complet
                            </MenuItem>

                            <MenuItem onClick={(eventx) => handleOpenItem(8)}>
                              Défendez-vous à la Cour des petites créances -
                              Coaching
                            </MenuItem>
                            <MenuItem onClick={(eventx) => handleOpenItem(6)}>
                              Défendez-vous à la Cour des petites créances - De
                              base
                            </MenuItem>
                            <MenuItem onClick={(eventx) => handleOpenItem(9)}>
                              Défendez-vous à la Cour des petites créances -
                              Complet
                            </MenuItem>

                            <MenuItem onClick={(eventx) => handleOpenItem(62)}>
                              Poursuivre à la Cour des petites créances pour un
                              vice caché - De base
                            </MenuItem>
                            <MenuItem onClick={(eventx) => handleOpenItem(63)}>
                              Poursuivre à la Cour des petites créances pour un
                              vice caché - Complet
                            </MenuItem>

                            <MenuItem onClick={(eventx) => handleOpenItem(62)}>
                              Se défendre à la Cour des petites créances pour un
                              vice caché - De base
                            </MenuItem>
                            <MenuItem onClick={(eventx) => handleOpenItem(63)}>
                              Se défendre à la Cour des petites créances pour un
                              vice caché - Complet
                            </MenuItem>
                          </Menu>
                        </li>
                        {/* <li><a href="/categorie/9/cour-du-quebec" className="text-theme-colored">Cour du Québec</a></li> */}
                        <li>
                          <a
                            data-href="0"
                            href="#"
                            className="text-theme-colored"
                            aria-controls="simple-menu-m3"
                            aria-haspopup="true"
                            data-nomenu="3"
                            onClick={handleClick}
                          >
                            Cour du Québec
                          </a>
                          <Menu
                            id="simple-menu-m3"
                            anchorEl={anchorEl3}
                            keepMounted
                            open={Boolean(anchorEl3)}
                            onClose={handleClose}
                          >
                            <MenuItem onClick={(eventx) => handleOpenItem(12)}>
                              Poursuivre à la Cour du Québec
                            </MenuItem>
                            <MenuItem onClick={(eventx) => handleOpenItem(67)}>
                              Interrogatoires
                            </MenuItem>
                            <MenuItem onClick={(eventx) => handleOpenItem(68)}>
                              Préparation au procès
                            </MenuItem>

                            <MenuItem onClick={(eventx) => handleOpenItem(66)}>
                              Conseils stratégiques sur votre dossier
                            </MenuItem>
                            <MenuItem onClick={(eventx) => handleOpenItem(13)}>
                              Se défendre à la Cour du Québec
                            </MenuItem>
                          </Menu>
                        </li>
                        {/* <li><a href="/categorie/8/negociation-hors-cour" className="text-theme-colored">Négociation hors cour</a></li> */}
                        <li>
                          <a
                            data-href="0"
                            href="#"
                            className="text-theme-colored"
                            aria-controls="simple-menu-m4"
                            aria-haspopup="true"
                            data-nomenu="4"
                            onClick={handleClick}
                          >
                            Négociation hors cour
                          </a>
                          <Menu
                            id="simple-menu-m4"
                            anchorEl={anchorEl4}
                            keepMounted
                            open={Boolean(anchorEl4)}
                            onClose={handleClose}
                          >
                            <MenuItem onClick={(eventx) => handleOpenItem(31)}>
                              Négociez avec la partie adverse
                            </MenuItem>
                            <MenuItem onClick={(eventx) => handleOpenItem(64)}>
                              Négociez avec la partie adverse pour vice-caché
                            </MenuItem>
                          </Menu>
                        </li>
                        {/* <li><a href="/service/multi/3/regie-du-logement" className="text-theme-colored">Tribunal administratif du logement</a></li> */}
                        <li>
                          <a
                            data-href="0"
                            href="#"
                            className="text-theme-colored"
                            aria-controls="simple-menu-m5"
                            aria-haspopup="true"
                            data-nomenu="5"
                            onClick={handleClick}
                          >
                            Tribunal administratif du logement
                          </a>
                          <Menu
                            id="simple-menu-m5"
                            anchorEl={anchorEl5}
                            keepMounted
                            open={Boolean(anchorEl5)}
                            onClose={handleClose}
                          >
                            <MenuItem onClick={(eventx) => handleOpenItem(58)}>
                              Tribunal administratif du logement - Coaching
                            </MenuItem>
                            <MenuItem onClick={(eventx) => handleOpenItem(56)}>
                              Tribunal administratif du logement - De base
                            </MenuItem>
                            <MenuItem onClick={(eventx) => handleOpenItem(57)}>
                              Tribunal administratif du logement - Clé en main
                            </MenuItem>
                          </Menu>
                        </li>
                        {/* <li><a href="/categorie/10/matiere-familiale" className="text-theme-colored">Matière Familiale</a></li> */}
                        <li>
                          <a
                            data-href="0"
                            href="#"
                            className="text-theme-colored"
                            aria-controls="simple-menu-m6"
                            aria-haspopup="true"
                            data-nomenu="6"
                            onClick={handleClick}
                          >
                            Matière Familiale
                          </a>
                          <Menu
                            id="simple-menu-m6"
                            anchorEl={anchorEl6}
                            keepMounted
                            open={Boolean(anchorEl6)}
                            onClose={handleClose}
                          >
                            <MenuItem onClick={(eventx) => handleOpenItem(73)}>
                              Parler à un avocat spécialisé en droit de la
                              famille
                            </MenuItem>
                            <MenuItem onClick={(eventx) => handleOpenItem(76)}>
                              Envoyer une lettre d'un avocat en matière
                              familiale
                            </MenuItem>
                            <MenuItem onClick={(eventx) => handleOpenItem(77)}>
                              Convention de vie commune
                            </MenuItem>

                            <MenuItem disabled={true}>
                              Divorce à l'amiable
                            </MenuItem>
                            <MenuItem onClick={(eventx) => handleOpenItem(81)}>
                              Divorce à l'amiable - Sans enfant, Sans pension
                              alimentaire
                            </MenuItem>
                            <MenuItem onClick={(eventx) => handleOpenItem(82)}>
                              Divorce à l'amiable - Avec enfant, Sans pension
                              alimentaire
                            </MenuItem>
                            <MenuItem onClick={(eventx) => handleOpenItem(83)}>
                              Divorce à l'amiable - Sans enfant, Avec pension
                              alimentaire
                            </MenuItem>
                            <MenuItem onClick={(eventx) => handleOpenItem(84)}>
                              Divorce à l'amiable - Avec enfant et pension
                              alimentaire
                            </MenuItem>

                            <MenuItem onClick={(eventx) => handleOpenItem(85)}>
                              Négociation en matière familiale
                            </MenuItem>
                          </Menu>
                        </li>
                        <li>
                          <a
                            data-href="0"
                            href="#"
                            className="text-theme-colored"
                            aria-controls="simple-menu-m7"
                            aria-haspopup="true"
                            data-nomenu="7"
                            onClick={handleClick}
                          >
                            Testament
                          </a>
                          <Menu
                            id="simple-menu-m7"
                            anchorEl={anchorEl7}
                            keepMounted
                            open={Boolean(anchorEl7)}
                            onClose={handleClose}
                          >
                            <MenuItem onClick={(eventx) => handleOpenItem(78)}>
                              Rédaction d’un testament notarié
                            </MenuItem>
                            <MenuItem onClick={(eventx) => handleOpenItem(79)}>
                              Rédaction d’un mandat de protection ou un mandat
                              en cas d'inaptitude
                            </MenuItem>
                            <MenuItem onClick={(eventx) => handleOpenItem(80)}>
                              Rédaction d’un testament devant témoins
                            </MenuItem>
                            <MenuItem onClick={(eventx) => handleOpenItem(89)}>
                              Parler à un notaire en ligne
                            </MenuItem>
                          </Menu>
                        </li>
                      </ul>
                    </div>
                    <div className="col3   ">
                      <h4 className="megamenu-col-title ml-5">
                        Infractions routières
                      </h4>
                      <ul className="list-dashed ">
                        <li>
                          <a
                            href="#"
                            onClick={(eventx) => handleOpenItem(33)}
                            className="text-theme-colored"
                          >
                            1 à 5 points d'inaptitude
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            onClick={(eventx) => handleOpenItem(34)}
                            className="text-theme-colored"
                          >
                            6 points et plus d'inaptitude
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="col3   ">
                      <h4 className="megamenu-col-title ml-5">Conseils</h4>
                      <ul className="list-dashed ">
                        {/* <li><a href="/categorie/7/consulter-un-avocat-immediatement" className="text-theme-colored">Parler à un avocat</a></li> */}
                        <li>
                          <a
                            data-href="0"
                            href="#"
                            className="text-theme-colored"
                            aria-controls="simple-menu-m8"
                            aria-haspopup="true"
                            data-nomenu="8"
                            onClick={handleClick}
                          >
                            Parler à un avocat
                          </a>
                          <Menu
                            id="simple-menu-m8"
                            anchorEl={anchorEl8}
                            keepMounted
                            open={Boolean(anchorEl8)}
                            onClose={handleClose}
                          >
                            <MenuItem onClick={(eventx) => handleOpenItem(2)}>
                              Conseil Juridique
                            </MenuItem>
                            <MenuItem onClick={(eventx) => handleOpenItem(73)}>
                              Conseil juridique en droit de la famille
                            </MenuItem>
                            <MenuItem onClick={(eventx) => handleOpenItem(75)}>
                              Conseil juridique en droit du travail
                            </MenuItem>
                            <MenuItem onClick={(eventx) => handleOpenItem(52)}>
                              Parler à un avocat spécialisé en droit criminel
                            </MenuItem>
                          </Menu>
                        </li>
                        <li>
                          <a
                            href="#"
                            onClick={(eventx) => handleOpenItem(3)}
                            className="text-theme-colored"
                          >
                            Réviser un document
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            onClick={(eventx) => handleOpenItem(89)}
                            className="text-theme-colored"
                          >
                            Parler à un notaire
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                {/* <p className = "font-16"><strong>OUR LAWYERS CAN HELP YOU WITH: </strong> </p> */}

                <div className="megamenu none">
                  <div className="megamenu-row">
                    <div className="col3">
                      <h4 className="megamenu-col-title ml-5">
                        Lancement d'entreprise
                      </h4>
                      <ul className="list-dashed ">
                        <li>
                          <a
                            href="#"
                            onClick={(eventx) => handleOpenItem(51)}
                            className="text-theme-colored"
                          >
                            Enregistrement et immatriculation
                          </a>
                        </li>
                        {/* <li><a href="#" className="text-theme-colored">Incorporation</a></li> */}
                        <li>
                          <a
                            data-href="0"
                            href="#"
                            className="text-theme-colored"
                            aria-controls="simple-menu-m9"
                            aria-haspopup="true"
                            data-nomenu="9"
                            onClick={handleClick}
                          >
                            Incorporation
                          </a>
                          <Menu
                            id="simple-menu-m9"
                            anchorEl={anchorEl9}
                            keepMounted
                            open={Boolean(anchorEl9)}
                            onClose={handleClose}
                          >
                            <MenuItem onClick={(eventx) => handleOpenItem(47)}>
                              Incorporer une entreprise au Québec
                            </MenuItem>
                            <MenuItem onClick={(eventx) => handleOpenItem(49)}>
                              Incorporer une entreprise au Canada
                            </MenuItem>
                          </Menu>
                        </li>
                      </ul>
                    </div>
                    <div className="col3   ">
                      <h4 className="megamenu-col-title ml-5">
                        Documents légaux et corporatifs
                      </h4>
                      <ul className="list-dashed ">
                        <li>
                          <a
                            data-href="0"
                            href="#"
                            className="text-theme-colored"
                            aria-controls="simple-menu-m13"
                            aria-haspopup="true"
                            data-nomenu="13"
                            onClick={handleClick}
                          >
                            Rédaction de documents
                          </a>
                          <Menu
                            id="simple-menu-m13"
                            anchorEl={anchorEl13}
                            keepMounted
                            open={Boolean(anchorEl13)}
                            onClose={handleClose}
                          >
                            <MenuItem onClick={(eventx) => handleOpenItem(45)}>
                              Rédaction d'un contrat de travail
                            </MenuItem>
                            <MenuItem onClick={(eventx) => handleOpenItem(43)}>
                              Rédaction d'un contrat de service
                            </MenuItem>
                            <MenuItem onClick={(eventx) => handleOpenItem(50)}>
                              Rédaction d'une entente de confidentialité
                            </MenuItem>
                          </Menu>
                        </li>
                        <li>
                          <a
                            href="#"
                            onClick={(eventx) => handleOpenItem(29)}
                            className="text-theme-colored"
                          >
                            Révision de documents
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            onClick={(eventx) => handleOpenItem(48)}
                            className="text-theme-colored"
                          >
                            Tenue de livres
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="col3   ">
                      <h4 className="megamenu-col-title ml-5">
                        Démarches juridiques
                      </h4>
                      <ul className="list-dashed ">
                        <li>
                          <a
                            data-href="0"
                            href="#"
                            className="text-theme-colored"
                            aria-controls="simple-menu-m10"
                            aria-haspopup="true"
                            data-nomenu="10"
                            onClick={handleClick}
                          >
                            Mise en demeure
                          </a>
                          <Menu
                            id="simple-menu-m10"
                            anchorEl={anchorEl10}
                            keepMounted
                            open={Boolean(anchorEl10)}
                            onClose={handleClose}
                          >
                            <MenuItem onClick={(eventx) => handleOpenItem(18)}>
                              Mise en demeure pour entreprises - Envoyer une
                              mise en demeure
                            </MenuItem>
                            <MenuItem onClick={(eventx) => handleOpenItem(60)}>
                              Mise en demeure pour entreprises - Répondre à une
                              mise en demeure
                            </MenuItem>
                          </Menu>
                        </li>
                        <li>
                          <a
                            data-href="0"
                            href="#"
                            className="text-theme-colored"
                            aria-controls="simple-menu-m11"
                            aria-haspopup="true"
                            data-nomenu="11"
                            onClick={handleClick}
                          >
                            Petites créances
                          </a>
                          <Menu
                            id="simple-menu-m11"
                            anchorEl={anchorEl11}
                            keepMounted
                            open={Boolean(anchorEl11)}
                            onClose={handleClose}
                          >
                            <MenuItem onClick={(eventx) => handleOpenItem(8)}>
                              Poursuivre à la Cour des petites créances -
                              Coaching
                            </MenuItem>
                            <MenuItem onClick={(eventx) => handleOpenItem(6)}>
                              Poursuivre à la Cour des petites créances - De
                              base
                            </MenuItem>
                            <MenuItem onClick={(eventx) => handleOpenItem(9)}>
                              Poursuivre à la Cour des petites créances -
                              Complet
                            </MenuItem>

                            <MenuItem onClick={(eventx) => handleOpenItem(8)}>
                              Défendez-vous à la Cour des petites créances -
                              Coaching
                            </MenuItem>
                            <MenuItem onClick={(eventx) => handleOpenItem(6)}>
                              Défendez-vous à la Cour des petites créances - De
                              base
                            </MenuItem>
                            <MenuItem onClick={(eventx) => handleOpenItem(9)}>
                              Défendez-vous à la Cour des petites créances -
                              Complet
                            </MenuItem>

                            <MenuItem onClick={(eventx) => handleOpenItem(62)}>
                              Poursuivre à la Cour des petites créances pour un
                              vice caché - De base
                            </MenuItem>
                            <MenuItem onClick={(eventx) => handleOpenItem(63)}>
                              Poursuivre à la Cour des petites créances pour un
                              vice caché - Complet
                            </MenuItem>

                            <MenuItem onClick={(eventx) => handleOpenItem(62)}>
                              Se défendre à la Cour des petites créances pour un
                              vice caché - De base
                            </MenuItem>
                            <MenuItem onClick={(eventx) => handleOpenItem(63)}>
                              Se défendre à la Cour des petites créances pour un
                              vice caché - Complet
                            </MenuItem>
                          </Menu>
                        </li>
                        <li>
                          <a
                            data-href="0"
                            href="#"
                            className="text-theme-colored"
                            aria-controls="simple-menu-m12"
                            aria-haspopup="true"
                            data-nomenu="12"
                            onClick={handleClick}
                          >
                            Cour du Québec
                          </a>
                          <Menu
                            id="simple-menu-m12"
                            anchorEl={anchorEl12}
                            keepMounted
                            open={Boolean(anchorEl12)}
                            onClose={handleClose}
                          >
                            <MenuItem onClick={(eventx) => handleOpenItem(12)}>
                              Poursuive à la Cour du Québec
                            </MenuItem>
                            <MenuItem onClick={(eventx) => handleOpenItem(13)}>
                              Se défendre à la Cour du Québec
                            </MenuItem>
                          </Menu>
                        </li>
                        <li>
                          <a
                            href="#"
                            onClick={(eventx) => handleOpenItem(31)}
                            className="text-theme-colored"
                          >
                            Négociation hors cour
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="col3">
                      <h4 className="megamenu-col-title ml-5">Solutions</h4>
                      <ul className="list-dashed ">
                        <li>
                          <a
                            href="#"
                            onClick={(eventx) => handleOpenItem(26)}
                            className="text-theme-colored"
                          >
                            Parler à un avocat
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            onClick={(eventx) => handleOpenItem(30)}
                            className="text-theme-colored"
                          >
                            Recouvrer des comptes à recevoir
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            onClick={(eventx) => handleOpenItem(15)}
                            className="text-theme-colored"
                          >
                            Négocier le départ d'un employé
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </TabPanel>

              <div className="form-group col-md-9">
                <br />
                <input
                  type="button"
                  onClick={this.handleClosePListAsOnTheSite}
                  className="btn btn-default"
                  value={"Fermer/Close"}
                />{" "}
                &nbsp; &nbsp;
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  };

  xProductListAsOnTheSiteEn = (event) => {
    return "";
  };
  ListOfExistedPossibleClient = (event) => {
    const open = this.state.openPossibleListOfClient; //lg sm md  fullWidth={"sm"} maxWidth={"lg"}
    const { t } = this.props;
    const listClient = this.state.possibleListOfClient;

    return (
      <div>
        <Dialog
          maxWidth={"sm"}
          open={open}
          onClose={this.handleCloseListOfClient}
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="form-dialog-confirmclient"
        >
          <DialogContent id="form-dialog-confirmclient">
            <div className="text-center"></div>
            <div className="text-center">
              <h2>Client Existant avec Email</h2>
            </div>
            <p>
              La liste de possible Client éxistant avec le même email{" "}
              {this.state.email}{" "}
            </p>
            {/* <p> &nbsp; </p> */}
            <div className="form-row neoxtbl">
              <table style={{ minWidth: "300px" }}>
                <thead>
                  <tr>
                    {/* <th colSpan="4">Prénom</th>  */}
                    <th>Prénom</th>
                    <th>Nom</th>
                    <th>Email</th>
                    <th>&nbsp; &nbsp;</th>
                    {/* <th> &nbsp; &nbsp;  </th>  */}
                  </tr>
                </thead>
                <tbody>{listClient}</tbody>
              </table>

              <div className="form-group col-md-9">
                <br />
                <input
                  type="button"
                  onClick={this.handleCloseListOfClient}
                  className="btn btn-default"
                  value={"Fermer/Close"}
                />{" "}
                &nbsp; &nbsp;
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  };

  CheckStatusOfChargeDeskCharge = (event) => {
    const open = this.state.openPossibleCDStatusInfo; //lg sm md  fullWidth={"sm"} maxWidth={"lg"}
    const { t } = this.props;
    //const { t, i18n } = useTranslation("common");
    const listObj = this.state.possibleListOfCDStatusInfo;

    return (
      <div>
        <Dialog
          maxWidth={"sm"}
          open={open}
          onClose={this.handleCloseListOfCDStatusInfo}
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="form-dialog-confirmclient4"
        >
          <DialogContent id="form-dialog-confirmclient4">
            <div className="text-center">
              <h2>Paiement via Charge Desk</h2>
            </div>
            <p>ID # {this.state.chargeDeskIdStr} </p>
            {/* <p> &nbsp; </p> */}
            <div className="form-row neoxtbl">
              <table style={{ minWidth: "300px" }}>
                <thead>
                  <tr>
                    {/* <th colSpan="4">Prénom</th>  */}
                    <th>Paramètres</th>
                    <th>&nbsp; &nbsp;</th>
                  </tr>
                </thead>
                <tbody>{listObj}</tbody>
              </table>

              <div className="form-group col-md-9">
                <br />
                <input
                  type="button"
                  onClick={this.handleCloseListOfCDStatusInfo}
                  className="btn btn-default"
                  value={"Fermer/Close"}
                />{" "}
                &nbsp; &nbsp;
                {/* {((paymentRound >0 && parseFloat(this.state.nowPayment)<=0 && this.state.pMethod===1) || (this.state.partialCanClose>0 && this.state.status_invoice!=="payed"))? */}
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={this.handleFinalizeCD}
                >
                  FINALISER LA TRANSACTION
                </button>
                {/* } */}
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  };

  handleCloseWinSTransaction = (event) => {
    this.setState({ ["openWinSTransaction"]: false });
  };

  handleOpenWinSTransaction = (event) => {
    this.setState({ ["openWinSTransaction"]: true });
  };

  SaleTransactionClosingPopup = (props) => {
    const open = this.state.openWinSTransaction; //lg sm md  fullWidth={"sm"} maxWidth={"lg"}
    const { t } = this.props;
    const listObj = this.state.possibleListOfCDStatusInfo;

    return (
      <div>
        <Dialog
          maxWidth={"sm"}
          open={open}
          onClose={this.handleCloseListOfCDStatusInfo}
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="form-dialog-confirmclient4"
        >
          <DialogContent id="form-dialog-confirmclient4">
            <div className="text-center">
              <h2>FERMETURE DE TRANSACTION</h2>
            </div>
            <p>ID FACTURE # {this.state.id_invoice} </p>
            <div className="form-group">
              <label>Choisir une option de fermeture</label>
              <br />
              <input
                type="radio"
                className={"form-controlx"}
                id="whichDate1"
                name="whichDate"
                value="1"
                checked={this.state.whichDate === "1" ? true : false}
                onClick={this.handleChange}
              />
              <span for="whichDate1"> Date d’aujourd’hui</span>
              <br />
              <input
                type="radio"
                className={"form-controlx"}
                id="whichDate2"
                name="whichDate"
                value="2"
                checked={this.state.whichDate === "2" ? true : false}
                onClick={this.handleChange}
              />
              <span for="whichDate2"> Date de l’achat</span>
              <br />
              <input
                type="radio"
                className={"form-controlx"}
                id="whichDate3"
                name="whichDate"
                value="3"
                checked={this.state.whichDate === "3" ? true : false}
                onClick={this.handleChange}
              />
              <span for="whichDate3"> Choisir Date</span>
            </div>
            {this.state.whichDate === "3" ? (
              <div className="form-group">
                <label htmlFor="interacCDeskDate">Date</label>
                <input
                  type="date"
                  name="interacCDeskDate"
                  id="interacCDeskDate"
                  value={this.state.interacCDeskDate}
                  onChange={this.handleChange}
                  className={"form-control"}
                />
              </div>
            ) : (
              ""
            )}
            <div className="form-row neoxtbl">
              <div className="form-group col-md-9">
                <br />
                <input
                  type="button"
                  onClick={this.handleCloseWinSTransaction}
                  className="btn btn-default"
                  value={"Fermer/Close"}
                />{" "}
                &nbsp; &nbsp;
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={this.handleFinalizeCD}
                >
                  FINALISER LA TRANSACTION
                </button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  };
  gotoSendPublicForm = (event) => {
    const { t } = this.props;
    let currentTic = this.state.ticket_id;
    const upsaleTicket = this.state.upsaleTicket;
    const searchConflicttxt = this.state.searchConflicttxt;
    if (
      (currentTic === "" &&
        (upsaleTicket === null || upsaleTicket === "") &&
        searchConflicttxt === "") ||
      (currentTic !== "" && upsaleTicket === "" && searchConflicttxt === "")
    ) {
      this.showToast(t("zqwMesas016_x") + "  ", "error");
      return false;
    }
    this.setState({
      ["openPublicLinkForm"]: true,
      publicLinkFormOrFile: 1,
      publicFormOrFile: {},
    });
  };

  gotoSendSAASForm = (event) => {
    const { t } = this.props;
    const { currentTic, upsaleTicket, searchConflicttxt } = this.state;
    if (
      (currentTic === "" &&
        (upsaleTicket === null || upsaleTicket === "") &&
        searchConflicttxt === "") ||
      (currentTic !== "" && upsaleTicket === "" && searchConflicttxt === "")
    ) {
      this.showToast(t("zqwMesas016_x") + "  ", "error");
      return false;
    }
    this.setState({
      isSAASFormOpen: true,
      publicLinkFormOrFile: 1,
      publicFormOrFile: {},
    });
  };

  gotoSendPublicFormFile = (event) => {
    const index = event.currentTarget.dataset.index;
    const fieldid = event.currentTarget.dataset.fieldid;
    const productfieldid = event.currentTarget.dataset.productfieldid;
    const datarecordid = event.currentTarget.dataset.datarecordid;
    const labelnodel = event.currentTarget.dataset.labelnodel;
    const labelname = event.currentTarget.dataset.filelabel;
    const fileaddress = event.currentTarget.dataset.fileaddress;

    const publicFormOrFile = {
      productfieldid: productfieldid,
      fieldid: fieldid,
      index: index,
      datarecordid: datarecordid,
      labelnodel: labelnodel,
      labelname: labelname,
      fileaddress: fileaddress,
    };
    this.setState({
      publicLinkFormOrFile: 2,
      publicFormOrFile: publicFormOrFile,
      ["openPublicLinkForm"]: true,
    });
  };

  SAASFormLinkPopup = () => {
    const {
      isSAASFormOpen,
      SAASForms,
      selectedSAASForm,
      SAASPostLoading,
      SAASFormLink,
      SAASLinkCopied,
      SAASMessage,
      firstname,
      lastname,
      email,
    } = this.state;
    const name = `${firstname ?? ""} ${lastname ?? ""}`;
    const options = SAASForms?.map((i) => ({
      ...i,
      label: i.title["fr"],
      value: i.id,
    }));
    const { t } = this.props;
    return (
      <Dialog
        maxWidth={"lg"}
        open={isSAASFormOpen}
        // onClose={() => {
        //   this.setState({
        //     SAASFormLink: "",
        //     SAASPostLoading: false,
        //     SAASMessage: null,
        //     selectedSAASForm: null,
        //   });
        // }}
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="form-dialog-pop4"
      >
        <DialogContent id="form-dialog-pop4">
          <div style={{ minWidth: "500px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <h5 style={{ marginBottom: "0px" }}>
                {"Envoyer un formulaire SAAS"}
              </h5>
              <Button size="lg" close onClick={this.closePopup4}></Button>
            </div>
            {SAASMessage && (
              <div style={{ marginTop: "15px" }}>
                <MuiAlert severity={SAASMessage?.severity}>
                  {SAASMessage?.message}
                </MuiAlert>
              </div>
            )}
            <div
              style={{
                marginTop: "15px",
                display: "flex",
                gap: "1rem",
                color: "grey",
                fontWeight: "600",
              }}
            >
              {name && (
                <div>
                  <FontAwesomeIcon
                    icon={faUser}
                    style={{ marginRight: "0.5rem" }}
                  />
                  {name}
                </div>
              )}
              {email && (
                <div>
                  <FontAwesomeIcon
                    icon={faAt}
                    style={{ marginRight: "0.5rem" }}
                  />
                  {email}
                </div>
              )}
            </div>
            <div style={{ marginTop: "15px" }}>
              <h6 style={{ marginBottom: "0px" }}>Formulaire</h6>
              <Select
                menuPosition={"fixed"}
                options={options}
                onChange={this.handleSAASSelectChange}
              />
            </div>
            {SAASFormLink && (
              <div style={{ display: "flex", marginTop: "15px", gap: "2rem" }}>
                <Input value={SAASFormLink} disabled />
                <Button
                  style={{ height: "38px" }}
                  color="primary"
                  outline
                  onClick={this.handleCopySAASLink}
                >
                  {
                    <span style={{ display: "flex" }}>
                      <FontAwesomeIcon
                        style={{ marginRight: "0.5rem" }}
                        icon={SAASLinkCopied ? faCheck : faCopy}
                      />
                      {SAASLinkCopied ? "Copier!" : "Copie"}
                    </span>
                  }
                </Button>
              </div>
            )}
            <Button
              disabled={!selectedSAASForm || SAASFormLink}
              style={{ marginTop: "15px", height: "35px" }}
              color="primary"
              block
              onClick={this.handleSendSAASForm}
            >
              {SAASPostLoading ? (
                <Spinner size="sm" />
              ) : (
                <>
                  {" "}
                  <FontAwesomeIcon
                    icon={faPaperPlane}
                    style={{ marginRight: "0.5rem" }}
                  />{" "}
                  Envoyer
                </>
              )}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  handleCopySAASLink = () => {
    const { SAASFormLink } = this.state;
    this.setState({ SAASLinkCopied: true });
    navigator.clipboard.writeText(SAASFormLink);
    setTimeout(() => {
      this.setState({ SAASLinkCopied: false });
    }, 3000);
  };

  handleSAASSelectChange = (e) => {
    !e
      ? this.setState({ selectedSAASForm: null })
      : this.setState({ selectedSAASForm: e });
  };

  handleSendSAASForm = () => {
    const { firstname, lastname, email, language, selectedSAASForm } =
      this.state;
    const { ticket_idPopup } = this.props;
    const obj = {
      form_id: selectedSAASForm?.id,
      ticket_id: ticket_idPopup,
      lang: language ?? "fr",
      form_params: {
        ...selectedSAASForm.form_params,
        form_name: selectedSAASForm?.form_name,
      },
      clients_data: [
        {
          firstname: firstname ?? "",
          lastname: lastname ?? "",
          email: email ?? "",
        },
      ],
    };
    if (obj.form_id && obj.ticket_id && obj.clients_data.length) {
      this.setState({ SAASPostLoading: true });
      axios
        .post(
          BACKEND1 + "/url-public-from-saas",
          {
            ...obj,
            auth_id: parseInt(localStorage.getItem("user_id")),
          },
          {
            params: {},
          }
        )
        .then((res) => {
          if (res.data.statusCode === 200) {
            const { url, message, success } = res.data?.data?.result;
            this.setState({
              SAASPostLoading: false,
              SAASFormLink: url ?? "",
              SAASMessage: { severity: success ? "success" : "error", message },
            });
          }
        })
        .catch((e) => {
          console.error(e);
          this.setState({
            SAASPostLoading: false,
            SAASMessage: { severity: "error", message: "Error" },
          });
        });
    }
    console.log(obj);
  };
  // };
  PublicLinkFormPopup = (props) => {
    const open = this.state.openPublicLinkForm; //lg sm md  fullWidth={"sm"} maxWidth={"lg"}
    const { t } = this.props;
    let currentTic = this.state.ticket_id;
    const dynamicFormAllData = this.state.dynamicFormAllData;
    const formOrFile = this.state.publicLinkFormOrFile;
    const publicFormOrFile = this.state.publicFormOrFile;

    const upsaleTicket = this.state.upsaleTicket;
    if (
      currentTic <= 0 &&
      upsaleTicket !== undefined &&
      upsaleTicket !== null &&
      upsaleTicket !== ""
    ) {
      currentTic = upsaleTicket;
    }

    const refFuncAction = this.setMessageDoneAction;
    return (
      <div>
        <Dialog
          maxWidth={"lg"}
          open={open}
          onClose={this.handleClosePopup4}
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="form-dialog-pop4"
        >
          <DialogContent id="form-dialog-pop4">
            <div className="text-center">
              <h5>{t("zqwNFFrmZn09_x")}</h5>
            </div>
            <PublicLinkForm
              currentTicket={currentTic}
              dynamicFormAllData={dynamicFormAllData}
              formOrFile={formOrFile}
              publicFormOrFile={publicFormOrFile}
              paStateFuncAction={refFuncAction}
            />

            <div className="text-center">
              <p> </p>
            </div>

            <div className="form-row neoxtbl">
              <div className="form-group col-md-9">
                <br />
                <input
                  type="button"
                  onClick={this.closePopup4}
                  className="btn btn-success"
                  value={"Fermer/Close"}
                />{" "}
                &nbsp; &nbsp;
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  };

  setMessageDoneAction = (props) => {
    if (
      props !== undefined &&
      props !== null &&
      props.stateName !== undefined &&
      props.stateName !== null
    ) {
      if (props.stateVal !== undefined && props.stateVal !== null) {
        this.setState({ [props.stateName]: props.stateVal });
      }
    }
  };

  closePopup4 = (event) => {
    //const { name, value } = event.target;
    this.setState({
      ["openFileLabelUpdate"]: false,
      ["openPublicLinkForm"]: false,
      ["openAssignTicketForm"]: false,
      ["openZendFusionNoteForm"]: false,
      ["openAssignTicketForm"]: false,
      ["openZendAddNoteForm"]: false,
      ["openZendAddTitleTAForm"]: false,
      isSAASFormOpen: false,
      SAASFormLink: "",
      SAASPostLoading: false,
      SAASMessage: null,
      selectedSAASForm: null,
    });
  };

  gotoAssignTicketForm = (event) => {
    const { t } = this.props;
    let currentTic = this.state.ticket_id;
    const upsaleTicket = this.state.upsaleTicket;
    const searchConflicttxt = this.state.searchConflicttxt;
    if (
      (currentTic === "" &&
        (upsaleTicket === null || upsaleTicket === "") &&
        searchConflicttxt === "") ||
      (currentTic !== "" && upsaleTicket === "" && searchConflicttxt === "")
    ) {
      this.showToast(t("zqwMesas016_x") + "  ", "error");
      return false;
    }
    this.setState({ ["openAssignTicketForm"]: true });
  };

  AssignTicketFormPopup = (props) => {
    const open = this.state.openAssignTicketForm; //lg sm md  fullWidth={"sm"} maxWidth={"lg"}
    const { t } = this.props;
    const tmpeventDataset = this.state.tmpeventDataset;
    let currentTic = this.state.ticket_id;
    const upsaleTicket = this.state.upsaleTicket;
    if (
      currentTic <= 0 &&
      upsaleTicket !== undefined &&
      upsaleTicket !== null &&
      upsaleTicket !== ""
    ) {
      currentTic = upsaleTicket;
    }

    const refFuncAction = this.setMessageDoneAction;

    return (
      <div>
        <Dialog
          maxWidth={"lg"}
          open={open}
          onClose={this.handleClosePopup4}
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="form-dialog-pop4"
        >
          <DialogContent id="form-dialog-pop4b">
            <div className="text-center">
              <h5>{t("zqwMesas04_x")}</h5>
            </div>

            <AssignTicketToGroupOrUser
              currentTicket={currentTic}
              paStateFuncAction={refFuncAction}
            />

            <div className="text-center">
              <p> </p>
            </div>

            <div className="form-row neoxtbl">
              <div className="form-group col-md-9">
                <input
                  type="button"
                  onClick={this.closePopup4}
                  className="btn btn-success"
                  value={t("Close_x")}
                />{" "}
                &nbsp; &nbsp;
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  };
  //ZendFusionNotePopup
  gotoZendFusionNoteForm = (event) => {
    const { t } = this.props;
    let currentTic = this.state.ticket_id;
    const upsaleTicket = this.state.upsaleTicket;
    const searchConflicttxt = this.state.searchConflicttxt;
    if (
      (currentTic === "" &&
        (upsaleTicket === null || upsaleTicket === "") &&
        searchConflicttxt === "") ||
      (currentTic !== "" && upsaleTicket === "" && searchConflicttxt === "")
    ) {
      this.showToast(t("zqwMesas016_x") + "  ", "error");
      return false;
    }
    this.setState({ ["openZendFusionNoteForm"]: true });
  };

  ZendFusionNotePopup = (props) => {
    const open = this.state.openZendFusionNoteForm; //lg sm md  fullWidth={"sm"} maxWidth={"lg"}
    const { t } = this.props;
    //const { t, i18n } = useTranslation("common");
    const tmpeventDataset = this.state.tmpeventDataset;

    let currentTic = this.state.ticket_id;
    const mergedTic = "0"; //get in url lead id

    const upsaleTicket = this.state.upsaleTicket;
    if (
      currentTic <= 0 &&
      upsaleTicket !== undefined &&
      upsaleTicket !== null &&
      upsaleTicket !== ""
    ) {
      currentTic = upsaleTicket;
    }

    const refFuncAction = this.setMessageDoneAction;

    return (
      <div>
        <Dialog
          maxWidth={"lg"}
          open={open}
          onClose={this.handleClosePopup4}
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="form-dialog-pop4"
        >
          <DialogContent id="form-dialog-pop4c2">
            <div className="text-center">
              {/* <h5>{t("zqwMesas04_x")}</h5>   */}
              <h5>FUSION/MERGE</h5>
            </div>

            <MyZendFusionNoteForm
              currentTicket={currentTic}
              mergedTicket={mergedTic}
              paStateFuncAction={refFuncAction}
            />

            <div className="text-center">
              <p> </p>
            </div>

            <div className="form-row neoxtbl">
              <div className="form-group col-md-9">
                <input
                  type="button"
                  onClick={this.closePopup4}
                  className="btn btn-success"
                  value={t("Close_x")}
                />{" "}
                &nbsp; &nbsp;
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  };

  ///ZendAddNoteForm
  gotoZendAddNoteForm = (event) => {
    const { t } = this.props;
    let currentTic = this.state.ticket_id;
    const upsaleTicket = this.state.upsaleTicket;
    const searchConflicttxt = this.state.searchConflicttxt;
    if (
      (currentTic === "" &&
        (upsaleTicket === null || upsaleTicket === "") &&
        searchConflicttxt === "") ||
      (currentTic !== "" && upsaleTicket === "" && searchConflicttxt === "")
    ) {
      this.showToast(t("zqwMesas016_x") + "  ", "error");
      return false;
    }

    this.setState({ ["openZendAddNoteForm"]: true });
  };

  ZendAddNotePopup = (props) => {
    const open = this.state.openZendAddNoteForm; //lg sm md  fullWidth={"sm"} maxWidth={"lg"}
    const { t } = this.props;
    let currentTic = this.state.ticket_id;

    const upsaleTicket = this.state.upsaleTicket;
    if (
      currentTic <= 0 &&
      upsaleTicket !== undefined &&
      upsaleTicket !== null &&
      upsaleTicket !== ""
    ) {
      currentTic = upsaleTicket;
    }
    const refFuncAction = this.setMessageDoneAction;

    return (
      <div>
        <Dialog
          maxWidth={"lg"}
          open={open}
          onClose={this.handleClosePopup4}
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="form-dialog-pop4"
        >
          <DialogContent id="form-dialog-pop4c2">
            <div className="text-center">
              {/* <h5>{t("zqwMesas04_x")}</h5>   */}
              <h5>NOTES</h5>
            </div>
            <ZendAddNoteForm
              currentTicket={currentTic}
              paStateFuncAction={refFuncAction}
            />

            <div className="text-center">
              <p> </p>
            </div>

            <div className="form-row neoxtbl">
              <div className="form-group col-md-9">
                <input
                  type="button"
                  onClick={this.closePopup4}
                  className="btn btn-success"
                  value={t("Close_x")}
                />{" "}
                &nbsp; &nbsp;
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  };

  gotoZendAddTitleTAForm = (event) => {
    const { t } = this.props;
    let currentTic = this.state.ticket_id;
    const upsaleTicket = this.state.upsaleTicket;
    const searchConflicttxt = this.state.searchConflicttxt;
    if (
      (currentTic === "" &&
        (upsaleTicket === null || upsaleTicket === "") &&
        searchConflicttxt === "") ||
      (currentTic !== "" && upsaleTicket === "" && searchConflicttxt === "")
    ) {
      this.showToast(t("zqwMesas016_x") + "  ", "error");
      return false;
    }

    this.setState({ ["openZendAddTitleTAForm"]: true });
  };

  ZendAddTitleTAPopup = (props) => {
    const open = this.state.openZendAddTitleTAForm; //lg sm md  fullWidth={"sm"} maxWidth={"lg"}
    const { t } = this.props;
    let currentTic = this.state.ticket_id;
    const upsaleTicket = this.state.upsaleTicket;
    if (
      currentTic <= 0 &&
      upsaleTicket !== undefined &&
      upsaleTicket !== null &&
      upsaleTicket !== ""
    ) {
      currentTic = upsaleTicket;
    }

    const refFuncAction = this.setMessageDoneAction;
    return (
      <div>
        <Dialog
          maxWidth={"lg"}
          open={open}
          onClose={this.handleClosePopup4}
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="form-dialog-pop4"
        >
          <DialogContent id="form-dialog-pop4c2">
            <div className="text-center">
              <h5>Titre du TA</h5>
            </div>

            <ZendAddTitleTAForm
              currentTicket={currentTic}
              paStateFuncAction={refFuncAction}
            />

            <div className="text-center">
              <p> </p>
            </div>

            <div className="form-row neoxtbl">
              <div className="form-group col-md-9">
                <input
                  type="button"
                  onClick={this.closePopup4}
                  className="btn btn-success"
                  value={t("Close_x")}
                />{" "}
                &nbsp; &nbsp;
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  };

  getAllProductsList = (event) => {
    //getAllProducts
    let myLang =
    localStorage.getItem("i18nextLng")?.split('-')[0] ?? "fr";
    const objProd = { lang: myLang, upsell: "yes", reasons: "s" };
    Promise.resolve(this.props.getAllProducts(objProd))
      .then((response) => {
        let productsList = [];
        const productListx = response.NeoVentes.products;
        const reasons = response.NeoVentes.reasons;
        let productList =
          productListx.length > 0 &&
          productListx.map((item, i) => {
            if (item !== null) {
              productsList.push(item);
            }
            return null;
          }, this);

        this.setState({
          ["productsList"]: { products: productsList },
          ["reasons"]: reasons,
        });
        this.productsList.products = productsList;
        return response;
      })
      .catch((e) => {
        console.error(e);
      });
  };

  getAllDoneActionsAftersalesList = (event) => {
    const { t } = this.props;
    let idx = 0;
    const idx1 = this.backLeadReId;
    const idx0 = this.state.id_invoice;
    if (idx0 > 0) {
      idx = idx0;
    } else if (idx1 > 0) {
      idx = idx1;
    }
    const aftobj = { ticket_id: "0", invoice_id: "" + idx };
    const upsaleTicket = this.state.upsaleTicket;

    const this00 = this;
    let rawError = {};
    this.setState({ ["isLoaderOpen"]: true, ["errorTransacMessage"]: "" });
    if (upsaleTicket !== null && upsaleTicket !== "") {
      this.setState({ ["isLoaderOpen"]: false });
      aftobj.ticket_id = "" + upsaleTicket;
    }
    Promise.resolve(this.props.getNeoInvAftersalesActionsx(aftobj))
      .then((response) => {
        const respo = response.NeoInvAftersalesActionsOne;
        this.setState({
          ["isLoaderOpen"]: false,
          ["actionsaftersales"]: respo,
        });
        this.handleSalespopupToClose(respo);

        return response;
      })
      .catch(function (error) {
        const errObj = error?.response?.data ?? {};
        let errMessage =
          errObj.data !== ""
            ? "Erreur " + " \n" + errObj.data
            : "Erreur " + " \n" + errObj.statusText;
        if (errObj.status === undefined) {
          errMessage += "\n " + t("zqwNVFrmEl14_x");
        }
        this00.setState({
          ["isLoaderOpen"]: false,
          ["errorTransacMessage"]: errMessage,
        });
        console.log(error);
      });
  };
  handleUpdateClient = (event) => {
    //event.preventDefault();
    const { t } = this.props;
    const idClient = this.state.idClient;
    const firstname = this.state.firstname;
    const lastname = this.state.lastname;
    const cell = this.state.cell;
    const terms = 0; //this.state.terms;
    const active = 1; //this.state.active;
    //const created = this.state.created;  existedTF=false;
    const email = this.state.email;
    const id_users = this.state.id_users;
    const language = this.state.language;
    const push = 0; //this.state.push;
    const id_zendesk = this.state.id_zendesk;
    const sms = this.state.sms;
    const stripe_id = this.state.stripe_id;
    const occupation = this.state.occupation;
    const neoClients = {
      idClient: idClient,
      firstname: firstname,
      lastname: lastname,
      cell: cell,
      terms: terms,
      active: active,
      email: email,
      id_users: id_users,
      language: language,
      push: push,
      id_zendesk: id_zendesk,
      sms: sms,
      stripe_id: stripe_id,
      occupation: occupation,
    };

    const idAddress = this.state.idAddress;
    const id_clients = this.state.id_clients;
    const address0 = this.state.address;
    const unitsuite = this.state.unitsuite;
    const city = this.state.city;
    const province0 = this.state.province;
    const province2 = this.state.province2;
    const cp = this.state.cp;
    const country = this.state.country;

    //const created = this.state.created;
    const active2 = 1; //this.state.active;
    const tel = ""; //this.state.tel; //this.state.cell;
    const ext = ""; //this.state.ext;
    const company = this.state.company;
    const nameNx2 = ""; //this.state.name;
    //const type = this.state.type;
    let address = address0;
    if (unitsuite !== "") {
      address = unitsuite + "-" + address;
    }
    let province = "";
    if (province0.length > 2) {
      province = province0.substring(0, 2);
      address += " (" + province0 + ")";
    } else {
      province = province0;
    }
    const neoAddress = {
      idAddress: idAddress,
      id_clients: id_clients,
      address: address,
      city: city,
      province: province,
      cp: cp,
      country: country,
      active: active2,
      tel: tel,
      ext: ext,
      company: company,
      name: nameNx2,
    };

    const productId = this.state.productCart.length; //const productId=this.state.productId;

    const productPriceTotal = this.state.grandTotal;
    const id = this.state.id;
    const name = this.state.name;
    const description = this.state.description;
    const amount = this.state.amount;
    //const NeoVentes = {id: id, name: name, description: description, amount: amount, };
    const neoVentes = [];
    const taxInfo = this.state.taxInfo;
    const TAX1 = parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    const TAX2 = parseFloat(taxInfo["tax2x"]); //0.05; //tax2
    const TAX_TOTAL = TAX1 + TAX2; //0.14975;
    const TAX1_NAME = taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME = taxInfo["tax2_name"]; //"TPS";
    const TAX1l = taxInfo["tax1"]; //tax1 0.9975
    const TAX2l = taxInfo["tax2"];

    const productCart = this.state.productCart;
    let subTotal = 0;
    let totTagTotOptions = 0;
    let totProd = 0;

    const tarifAmount = subTotal + totTagTotOptions;
    const tax1 = (tarifAmount * 1 * TAX1).toFixed(2);
    const tax2 = (tarifAmount * 1 * TAX2).toFixed(2);
    const tax_total = (tarifAmount * TAX_TOTAL).toFixed(2);
    const totalForItem = (tarifAmount * 1 + tax_total * 1).toFixed(2); //const totalForItem= ((tarifAmount+tax_total)).toFixed(2);

    let neoPromocode = {
      id: 0,
      percentage: 0,
      name: name,
      amount: 0,
      tax1: 0,
      tax2: 0,
    };

    const stripeToken = this.state.stripeToken;
    let pMethod = 1;

    const dossier = this.state.dossier;
    pMethod = this.state.pMethod;

    const downPayment = this.state.nowPayment;
    const paymentRound = this.state.paymentRound;
    const isRecurrent = this.state.isRecurrentPayment;
    const qtyPayment = this.state.qtyPayment;
    const laterpayment = this.state.laterpayment;
    const fromDatePayment = this.state.fromDatePayment;
    const paymentWay = this.state.paymentWay; //full, partial, recurrent
    const neoPayments = {
      id: id,
      downPayment: downPayment,
      paymentRound: paymentRound,
      paymentWay: paymentWay,
      isRecurrent: isRecurrent,
      qtyPayment: qtyPayment,
      recPaymentDay: laterpayment,
      recPaymentDate: fromDatePayment,
    };

    const id_invoice = this.state.id_invoice;
    let errorFound = 0;

    const isupsale = this.state.isupsale;
    const upsaleTicket = this.state.upsaleTicket;
    const isAmerindian = this.state.isAmerindian;
    const no_amerindien = this.state.no_amerindien;
    const neoVentesBlocks = {
      client: neoClients,
      address: neoAddress,
      sales: [],
      clientInfoUPDATE: "yes",
      dossier: dossier,
      pMethod: pMethod,
      payment: {},
    };
    const this00 = this;
    let rawError = {};

    this.setState({ ["isLoaderOpen"]: true, ["errorTransacMessage"]: "" });
    Promise.resolve(this.props.addOrUpdateNeoVentes(neoVentesBlocks, 1))
      .then((response) => {
        const resp = response.NeoVentes.transaction;
        rawError = response;
        if (resp.paymentAmount > 0) {
          this.showToast(
            "L'info du client a été actualisée avec succès!/ The client info was updated successfully!",
            "success"
          );
        }
        this.setState({ ["isLoaderOpen"]: false });
        return response;
      })
      .catch(function (error) {
        const errObj = error?.response?.data ?? {};
        let errMessage =
          errObj?.data !== ""
            ? "Erreur " + " \n" + errObj?.data
            : "Erreur " + " \n" + errObj?.statusText;
        if (errObj?.status === undefined) {
          errMessage += "\n " + t("zqwNVFrmEl14_x");
        }
        this00.setState({
          ["isLoaderOpen"]: false,
          ["errorTransacMessage"]: errMessage,
        });
        this00.showToast(errMessage, "error");
        console.log(error);
      });
  };
  handleSalespopupToClose = (actionsaftersales) => {
    let sendPublicFormtxt = this.state.sendPublicFormtxt;
    let assignTicketFormtxt = this.state.assignTicketFormtxt;
    const zendFusionNotetxt = this.state.zendFusionNotetxt;
    let zendAddNotetxt = this.state.zendAddNotetxt;
    let zendAddTitleTAtxt = this.state.zendAddTitleTAtxt;

    let lead2actionFormtxt = this.state.lead2actionFormtxt;
    let searchConflicttxt = this.state.searchConflicttxt;
    //const bookingFormtxt= this.state.bookingFormtxt;
    let bookingFormtxt = this.state.calendarBookingInfo;
    let lead2actionFormtxt2 = this.state.lead2actionFormtxt2;
    let lead2actionFormtxt3 = this.state.lead2actionFormtxt3;
    let ticketSelected = 0;

    let showActionMessage = 0;

    if (
      bookingFormtxt !== "" ||
      sendPublicFormtxt !== "" ||
      assignTicketFormtxt !== ""
    ) {
      showActionMessage = 7; //2
    }

    if (this.props.informSaleToAllowClose === undefined) {
      return false;
    }

    let ticket_id = this.state.ticket_id;
    //const ticket_id= "232358";
    const client_id = this.state.idClient; // client_id;
    let backTicketId = this.state.ticket_id; //433332;
    if (
      this.backLeadTiId > 0 &&
      (ticket_id < 1 || ticket_id === "" || ticket_id === "0")
    ) {
      backTicketId = this.backLeadTiId;
      ticket_id = this.backLeadTiId;
    }
    ticketSelected = ticket_id;

    const upsaleTicket = this.state.upsaleTicket;
    //const actionsaftersales=this.state.actionsaftersales;
    const actionsafterx =
      actionsaftersales.length > 0 &&
      actionsaftersales.map((item, i) => {
        if (
          item.ticket_id === "" + ticketSelected ||
          (upsaleTicket !== "" && item.ticket_id === "" + upsaleTicket)
        ) {
          if (item.action_no === "1" && searchConflicttxt === "") {
            searchConflicttxt = item.action_text;
          } else if (item.action_no === "2" && bookingFormtxt === "") {
            bookingFormtxt = item.action_text;
            showActionMessage = 2;
          } else if (item.action_no === "3" && sendPublicFormtxt === "") {
            sendPublicFormtxt = item.action_text;
            showActionMessage = 3;
          } else if (item.action_no === "4" && zendAddNotetxt === "") {
            zendAddNotetxt = item.action_text;
          } else if (item.action_no === "5" && assignTicketFormtxt === "") {
            assignTicketFormtxt = item.action_text;
            showActionMessage = 5;
          } else if (item.action_no === "6" && zendAddTitleTAtxt === "") {
            zendAddTitleTAtxt = item.action_text;
          }
        }

        return null;
      }, this);

    let allowCloseVal = "0";
    if (showActionMessage > 0) {
      allowCloseVal = "1";
    }

    if (this.props.informSaleToAllowClose !== undefined) {
      const obj = { allowClose: allowCloseVal };
      this.props.informSaleToAllowClose(obj);
    }
  };
}

const mapStateToProps = (state) => ({ NeoVentes: state.NeoVentes });
const mapDispatchToProps = {
  addOrUpdateNeoVentes,
  getNeoVentes,
  getClientSearch,
  getB2BClientSearch,
  getClientAddress,
  getCodePromo,
  getNeoVentesPromos,
  getProductInfo,
  getPartialInvoicePayments,
  getAllProducts,
  getChargeDeskStatus,
  getClientTransactionsFullInfo,
  getClientAndAddress,
  getClientAndAddressByLead,
  getNeoInvAftersalesActionsx,
  addOrUpdateNeoInvAftersalesActions,
  sendAssignedGroupUser,
  getGroupsAndUsers
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("common")(NeoVentesForm));
